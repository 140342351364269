import { isNil } from 'ramda';

export const MINIMUM_DEPOSIT_AMOUNT_IN_UNITS = 5;

export const MINIMUM_WITHDRAWAL_AMOUNT_IN_UNITS = 1;

export const WITHDRAWAL_FEE = 0;

export const validateUnitDepositAmount = (amount: number) => {
  if (amount >= MINIMUM_DEPOSIT_AMOUNT_IN_UNITS) return true;

  return false;
};

export const validateUnitWithdrawalAmount = (amount: number) => {
  if (isNil(amount) || amount < MINIMUM_WITHDRAWAL_AMOUNT_IN_UNITS) {
    return false;
  }

  return true;
};
