// Style guide:
// https://www.figma.com/file/SxoJzGJuOC9sL82AH4oMKO/Cobra-UI-Style-Guide

export const Colors = {
  Black: '#000000',
  CodGray: '#121212',
  DodgerBlue: '#1DA1F2',
  Green300: '#3FD79D',
  Green900: '#006D36',
  Mirage: '#15202B',
  Punch300: '#EAE381',
  Punch900: '#CD7A31',
  Sun: '#FBAD18',
  Terracotta: '#E26457',
  White: '#FFFFFF',
} as const;

export type Colors = typeof Colors[keyof typeof Colors];

// Should be overlayed on top of primary color to convey emphasis.
export const Emphasis = {
  High: 'rgba(0, 0, 0, 1)',
  Medium: 'rgba(0, 0, 0, 0.74)',
  Disabled: 'rgba(0, 0, 0, 0.38)',
} as const;

export type Emphasis = typeof Emphasis[keyof typeof Emphasis];

// Should be overlayed on top of surface color to convey depth
// dp = depth
export const Elevation = {
  dp0: 'rgba(255, 255, 255, 0)',
  dp1: 'rgba(255, 255, 255, 0.05)',
  dp2: 'rgba(255, 255, 255, 0.07)',
  dp3: 'rgba(255, 255, 255, 0.08)',
  dp4: 'rgba(255, 255, 255, 0.09)',
  dp6: 'rgba(255, 255, 255, 0.11)',
  dp8: 'rgba(255, 255, 255, 0.12)',
  dp12: 'rgba(255, 255, 255, 0.14)',
  dp16: 'rgba(255, 255, 255, 0.15)',
  dp24: 'rgba(255, 255, 255, 0.16)',
} as const;

export type Elevation = typeof Elevation[keyof typeof Elevation];

// Legacy colors
export const COD_GRAY = '#111111';

const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

export const hexToRgba = (hex: string, alpha: number) => {
  const defaultValue = 'rgba(255, 255, 255, 1)';

  if (alpha < 0 || alpha > 100) return defaultValue;

  const rgb = hexToRgb(hex);

  if (!rgb) return defaultValue;

  return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${alpha / 100})`;
};
