import gql from 'graphql-tag';
import { SportAllInfo } from '../fragments/sport';

export const WRITE = gql`
  mutation writeSport($data: SportInput!) {
    writeSport(data: $data) {
      ...SportAllInfo
    }
  }
  ${SportAllInfo}
`;

export const REMOVE = gql`
  mutation removeSport($id: String!) {
    removeSport(id: $id) {
      ...SportAllInfo
    }
  }
  ${SportAllInfo}
`;
