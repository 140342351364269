import { useQuery } from '@apollo/client';
import {
  SponsorsQuery,
  SponsorsQueryVariables,
} from '@cobra/common/dist/graphql/generated/graphql';
import { SponsorQueries } from '@cobra/common/dist/graphql/queries';
import { Select } from 'antd';

export const SponsorSelector = ({
  sponsor,
  onChange,
}: {
  sponsor: string | undefined;
  onChange: (id: string | undefined) => void;
}) => {
  const { data: { sponsors: options } = { sponsors: [] }, loading } = useQuery<
    SponsorsQuery,
    SponsorsQueryVariables
  >(SponsorQueries.SPONSORS, {
    variables: { limit: 100, offset: 0, filters: {} },
    fetchPolicy: 'no-cache',
  });

  return (
    <Select
      disabled={loading}
      value={sponsor}
      labelRender={(x) => options.find((y) => y.id === x.value)?.name}
      placeholder="Seleccione un patrocinante"
      onChange={onChange}
      showSearch
      allowClear
      optionFilterProp="label"
      options={options
        .filter((option) => sponsor !== option.id)
        .map((option) => ({
          ...option,
          value: option.id,
          label: option.name,
        }))}
    />
  );
};
