import gql from 'graphql-tag';

import * as fixtureFragments from './fixture';

export const BettorMainInfo = gql`
  fragment BettorMainInfo on Bet {
    bettor {
      id
      username
      profileImageUrl
    }
  }
`;

export const BettorFullInfo = gql`
  fragment BettorFullInfo on Bet {
    bettor {
      id
      username
      firstName
      lastName
      profileImageUrl
    }
  }
`;

export const TakerMainInfo = gql`
  fragment TakerMainInfo on Bet {
    taker {
      id
      username
      profileImageUrl
    }
  }
`;

export const TakerFullInfo = gql`
  fragment TakerFullInfo on Bet {
    taker {
      id
      username
      firstName
      lastName
      profileImageUrl
    }
  }
`;

export const BetMainInfo = gql`
  fragment BetMainInfo on Bet {
    amount
    balanceBettor
    balanceTaker
    createdAt
    fixture {
      ...allInfo
    }
    id
    odds {
      homeTeamOdds
      awayTeamOdds
    }
    pick
    status
    type
    ...BettorMainInfo
    ...TakerMainInfo
  }
  ${fixtureFragments.allInfo}
  ${BettorMainInfo}
  ${TakerMainInfo}
`;
