import { ClearOutlined } from '@ant-design/icons';
import { Row, Col, Input, Button, Space } from 'antd';
import useArray from '../../../../hooks/useArray';
import { CompetitionConfigPhaseInput } from '@cobra/common/dist/graphql/generated/graphql';
import { useEffect } from 'react';

export const CompetitionConfigPhases = ({
  config,
  onChange,
}: {
  config: CompetitionConfigPhaseInput[] | null | undefined;
  onChange: (phases: CompetitionConfigPhaseInput[]) => void;
}) => {
  const phases = useArray(config ?? []);

  useEffect(() => {
    onChange(phases.array);
  }, [phases.array]);

  return (
    <>
      <Space direction="vertical" style={{ width: '100%' }}>
        {phases.array.map((phase, i) => (
          <Row gutter={16} style={{ maxWidth: '100%' }}>
            <Col span={5}>
              <Input
                type="number"
                prefix="ID"
                placeholder="id"
                value={phase.id}
                onChange={(x) =>
                  phases.update(i, { ...phase, id: x.target.value })
                }
              />
            </Col>
            <Col span={18}>
              <Input
                prefix="Nombre"
                placeholder="Nombre"
                value={phase.name}
                onChange={(x) =>
                  phases.update(i, {
                    ...phase,
                    name: x.target.value,
                  })
                }
              />
            </Col>
            <Col span={1}>
              <Button
                onClick={() => phases.remove(i)}
                icon={<ClearOutlined />}
              />
            </Col>
          </Row>
        ))}
        <Button onClick={() => phases.push({ id: '', name: '' })}>
          Añadir nueva fase
        </Button>
      </Space>
    </>
  );
};
