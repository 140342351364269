import React, { SyntheticEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, MutationUpdaterFn } from '@apollo/client';
import { Typography, Form, Input, Button, Space } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { css, cx } from '@emotion/css';
import gql from 'graphql-tag';
import {
  SignInMutation as TSignIn,
  SignInMutationVariables as signInVariables,
} from '@cobra/common/dist/graphql/generated/graphql';
import { SIGN_IN } from '@cobra/common/dist/graphql/mutations';
import { centeredColumn } from '../../styles';
import GenericError from '../GenericError/GenericError';

const { Title } = Typography;
const { useForm, Item } = Form;

const container = cx(
  centeredColumn,
  css({
    height: 'calc(100vh - 80px)',
  })
);
const prefixStyle = css({ color: 'rgba(0,0,0,.25)' });

// interface LoginProps extends FormProps {
//   form: FormInstance;
// }

// props: LoginProps
const Login = () => {
  const [form] = useForm();
  const navigate = useNavigate();

  const handleUpdate: MutationUpdaterFn<TSignIn> = (cache, result) => {
    const data = result?.data;

    if (data) {
      const {
        signIn: { accessToken, refreshToken },
      } = data;

      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
      cache.writeQuery({
        query: gql`
          query IsLoggedIn {
            isLoggedIn
          }
        `,
        data: { isLoggedIn: true },
      });
      navigate('/dashboard');
    }
  };

  const [signIn, { loading: signInLoading, error: signInError }] = useMutation<
    TSignIn,
    signInVariables
  >(SIGN_IN, { update: handleUpdate });

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    try {
      await form.validateFields();
      await signIn({
        variables: {
          login: form.getFieldValue('username'),
          password: form.getFieldValue('password'),
          admin: true,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const renderForm = () => {
    return (
      <Form form={form}>
        <Space size={16} direction="vertical">
          <Item
            name="username"
            noStyle
            rules={[{ required: true, message: 'email/usuario inválido' }]}
          >
            <Input
              prefix={<UserOutlined className={prefixStyle} />}
              placeholder="email/usuario"
              autoComplete="username"
            />
          </Item>

          <Item
            name="password"
            noStyle
            rules={[
              {
                required: true,
                message: 'contraseña inválida',
              },
            ]}
          >
            <Input
              prefix={<LockOutlined className={prefixStyle} />}
              type="password"
              placeholder="contraseña"
              autoComplete="current-password"
            />
          </Item>

          <Item>
            <div className={centeredColumn}>
              <Button
                loading={signInLoading}
                onClick={handleSubmit}
                type="primary"
                htmlType="submit"
              >
                Iniciar Sesión
              </Button>
              <GenericError error={signInError} />
            </div>
          </Item>
        </Space>
      </Form>
    );
  };

  return (
    <div className={container}>
      <Title level={2}>Cobra Admin</Title>
      {renderForm()}
    </div>
  );
};

export default Login;
