import gql from 'graphql-tag';

export const Config = gql`
  fragment Config on CompetitionConfig {
    phases {
      id
      name
    }
    groups {
      id
      name
      members
    }
    schema {
      from {
        id
        type
        place
      }
      to {
        id
        type
        position
      }
    }
    points {
      phase
      breakdown {
        inPhase
        participants
        positions
        podium
        placing
        score
        scoreRange {
          threshold
          points
        }
        scoreTotal
        scoreUnder
        scoreOver
      }
    }
  }
`;
