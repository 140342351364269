import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { isEmpty, isNil } from 'ramda';
import { Button, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  Sport,
  SportType,
  SportQuery,
  SportQueryVariables,
  SportsQuery,
  SportsQueryVariables,
  WriteSportMutation,
  WriteSportMutationVariables,
  RemoveSportMutation,
  RemoveSportMutationVariables,
  SportInput,
} from '@cobra/common/dist/graphql/generated/graphql';
import { SportMutations } from '@cobra/common/dist/graphql/mutations';
import { SportQueries } from '@cobra/common/dist/graphql/queries';
import { SportFilters } from './filters';
import { SportForm } from './form';
import { Layout } from '../../Layout';
import { useFormState } from '../../../hooks/useFormState';

const initialSportData: SportInput = {
  id: '',
  name: '',
  config: {},
  type: SportType.TEAMS,
};

export const SportsView = () => {
  const [params, setParams] = useSearchParams();
  const id = params.get('id');
  const [sportData, sportForm] = useFormState(initialSportData);

  // Declare queries and mutations
  const {
    data: { sports } = { sports: [] },
    loading,
    refetch,
  } = useQuery<SportsQuery, SportsQueryVariables>(SportQueries.SPORTS, {
    fetchPolicy: 'no-cache',
  });

  const [fetchSport] = useLazyQuery<SportQuery, SportQueryVariables>(
    SportQueries.SPORT,
    {
      onCompleted: ({ sport }) => sportForm.reset(sport ?? undefined),
      fetchPolicy: 'no-cache',
    }
  );

  const [saveSport] = useMutation<
    WriteSportMutation,
    WriteSportMutationVariables
  >(SportMutations.WRITE, {
    onCompleted: () => refetch(),
    fetchPolicy: 'no-cache',
  });

  const [removeSport] = useMutation<
    RemoveSportMutation,
    RemoveSportMutationVariables
  >(SportMutations.REMOVE, {
    onCompleted: () => refetch(),
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (isNil(id) || isEmpty(id)) {
      sportForm.reset(initialSportData);
    } else {
      fetchSport({ variables: { id } });
    }
  }, [id]);

  return (
    <Layout<Sport>
      table={{
        data: sports,
        loading,
        columns: [
          { title: 'Nombre', dataIndex: 'name' },
          {
            title: 'Acciones',
            render: (t, x) => (
              <Popconfirm
                title="¿Desea borrar este deporte?"
                onConfirm={() => removeSport({ variables: { id: x.id } })}
              >
                <Button>
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            ),
          },
        ],
        onRowClick: (x) => {
          if (id === x.id)
            setParams((p) => {
              p.delete('id');
              return p;
            });
          else
            setParams((p) => {
              p.set('id', x.id);
              return p;
            });
        },
      }}
      filters={
        <SportFilters
          onChange={(filters) => refetch({ filters })}
          openModal={() =>
            setParams((p) => {
              p.set('id', 'new');
              return p;
            })
          }
        />
      }
      modal={{
        autoWidth: true,
        state: !isEmpty(id) && !isNil(id),
        title: id === 'new' ? 'Nuevo participante' : sportData.name,
        onCancel: () =>
          setParams((p) => {
            p.delete('id');
            return p;
          }),
        onOk: async () => {
          saveSport({ variables: { data: sportData } });
        },
        element: <SportForm data={sportData} onChange={sportForm.update} />,
      }}
    />
  );
};
