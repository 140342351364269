import {
  ContestType,
  ContestWinType,
  WriteContestInput,
} from '@cobra/common/dist/graphql/generated/graphql';
import {
  Card,
  Col,
  Flex,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Slider,
  Space,
  Statistic,
} from 'antd';
import { Dayjs } from 'dayjs';
import { isNil } from 'ramda';
import { FC, useMemo } from 'react';
import useDebounce from '../../../hooks/useDebounce';
import { percentageFormatter } from '../../../utils/formatters';
import { CouponTags } from '../../CouponTags';
import { FixturesTable } from '../../FixturesTable';
import { FeaturedSelector } from '../../Selector/FeaturedSelector';
import { SponsorSelector } from '../../Selector/SponsorSelector';
import { SportSelector } from '../../Selector/SportSelector';

const { Item } = Form;

export const ContestForm: FC<{
  data: WriteContestInput;
  onChange: (data: WriteContestInput) => void;
  fixtures: string[];
  dateRange: [Dayjs, Dayjs];
  sponsor: string | undefined;
  onSponsorChange: (data: string | undefined) => void;
  onFixtureChange: (data: string[]) => void;
}> = ({
  data,
  onChange,
  fixtures,
  dateRange,
  onFixtureChange,
  sponsor,
  onSponsorChange,
}) => {
  // const [data, setState] = useState(original);
  const debounced = useDebounce(data, 350);

  // const onChange = (upd: Partial<WriteContestInput>) => {
  //   setState((p) => ({ ...p, ...upd }));
  // };

  const {
    minContestants,
    maxContestants,
    minWinnerCount,
    recommendedMinWinnerPercentage: rMWP,
  } = useMemo(
    () =>
      getContestantStats({
        pot: debounced.initialPot!,
        entry: debounced.entryAmount!,
        prizeAmount: debounced.prizeAmount!,
        winType: debounced.winType!,
        maxProfit: debounced.maxProfitPercentage!,
        minProfit: debounced.minProfitPercentage!,
        winnerPercentage: debounced.winnerPercentage!,
        fee: debounced.fee!,
      }),
    [debounced]
  );

  return (
    <Form layout="vertical" labelWrap>
      <Space
        size={16}
        direction="vertical"
        style={{
          width: '100%',
        }}
      >
        <Row gutter={16}>
          <Col>
            <Flex justify="end" align="center" style={{ height: '100%' }}>
              <FeaturedSelector
                featured={!!data.featured}
                onChange={(x) => onChange({ featured: x })}
              />
            </Flex>
          </Col>

          <Col span={3}>
            <Item label="Prioridad">
              <InputNumber
                min={0}
                style={{ width: '100%' }}
                value={data.priority  }
                onChange={(x) => onChange({ priority  : x })}
              />
            </Item>
          </Col>

          <Col span={5}>
            <Item label="Nombre">
              <Input
                placeholder="Nombre"
                value={data?.name ?? undefined}
                onChange={(x) => onChange({ name: x.target.value })}
              />
            </Item>
          </Col>
          <Col span={3}>
            <Item label="Tipo">
              <Select
                placeholder="Tipo"
                value={data.type}
                onChange={(x) => onChange({ type: x })}
                options={Object.entries(ContestType).map(([k, v]) => ({
                  value: v,
                  label: k,
                }))}
              />
            </Item>
          </Col>
          <Col span={3}>
            <Item label="Patrocinante">
              <SponsorSelector sponsor={sponsor} onChange={onSponsorChange} />
            </Item>
          </Col>
          <Col span={3}>
            <Item label="Tipo de victoria">
              <Select
                placeholder="Pote o Regular"
                value={data.winType}
                onChange={(x) => {
                  const upd: WriteContestInput = { winType: x };
                  if (x === ContestWinType.POT) {
                    upd.minProfitPercentage = 0;
                    upd.maxProfitPercentage = 100;
                    upd.winnerPercentage = 0;
                    upd.fee = 30;
                    upd.entryAmount = 3;
                  } else {
                    upd.minProfitPercentage = 30;
                    upd.maxProfitPercentage = 100;
                    upd.winnerPercentage = 0;
                    upd.fee = 100;
                    upd.entryAmount = 0;
                  }
                  onChange(upd);
                }}
                options={Object.entries(ContestWinType).map(([k, v]) => ({
                  value: v,
                  label: k,
                }))}
              />
            </Item>
          </Col>
          <Col span={3}>
            <Item label="Deporte">
              <SportSelector
                sport={data.sport}
                onChange={(x) => onChange({ sport: x })}
              />
            </Item>
          </Col>
          <Col span={3}>
            <Item label="Cupones">
              <CouponTags
                coupons={data.coupons ?? []}
                onChange={(c) => onChange({ coupons: c })}
              />
            </Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={6}>
            <Item label="Pot Inicial">
              <InputNumber
                min={0}
                prefix="USD"
                style={{ width: '100%' }}
                value={data.initialPot}
                onChange={(x) => onChange({ initialPot: x })}
              />
            </Item>
          </Col>
          <Col span={6}>
            <Item label="Monto de inscripción" required>
              <InputNumber
                min={0}
                prefix="USD"
                style={{ width: '100%' }}
                placeholder={'0'}
                value={isNil(data.entryAmount) ? 0 : data.entryAmount}
                onChange={(x) => onChange({ entryAmount: x })}
              />
            </Item>
          </Col>
          <Col span={6}>
            <Item label="Comisión" required>
              <InputNumber
                min={0}
                max={100}
                step={1}
                prefix="%"
                style={{ width: '100%' }}
                value={data.fee}
                onChange={(x) => onChange({ fee: x })}
              />
            </Item>
          </Col>
          <Col span={6}>
            <Item label="Entradas maximas" required>
              <InputNumber
                style={{ width: '100%' }}
                min={1}
                placeholder={'0'}
                value={data.maxEntriesPerContestant ?? 0}
                onChange={(x) => onChange({ maxEntriesPerContestant: x })}
              />
            </Item>
          </Col>
        </Row>

        <Item label="Premio">
          <Input.TextArea
            placeholder="Premio..."
            value={data.prizeAmount ?? undefined}
            onChange={(x) => onChange({ prizeAmount: x.target.value })}
            autoSize={{ minRows: 4, maxRows: undefined }}
          />
        </Item>

        <Item label="Info">
          <Input.TextArea
            placeholder="Información..."
            value={data.info ?? undefined}
            onChange={(x) => onChange({ info: x.target.value })}
            autoSize={{ minRows: 4, maxRows: undefined }}
          />
        </Item>

        <Row gutter={16}>
          <Col span={12}>
            <Item label="Rango de porcentaje de ganancia">
              <Flex align="center" justify="center" style={{ height: '100%' }}>
                <Slider
                  range
                  included
                  style={{ width: '95%' }}
                  marks={{
                    0: '0%',
                    10: '10%',
                    20: '20%',
                    30: '30%',
                    40: '40%',
                    50: '50%',
                    60: '60%',
                    70: '70%',
                    80: '80%',
                    90: '90%',
                    100: '100%',
                  }}
                  value={[data.minProfitPercentage!, data.maxProfitPercentage!]}
                  tooltip={{ formatter: percentageFormatter }}
                  onChange={([min, max]) =>
                    onChange({
                      minProfitPercentage: min,
                      maxProfitPercentage: max,
                    })
                  }
                />
              </Flex>
            </Item>
          </Col>
          <Col span={12}>
            <Item label="Porcentaje de ganadores">
              <Flex align="center" justify="center" style={{ height: '100%' }}>
                <Slider
                  included
                  style={{ width: '95%' }}
                  marks={{ [rMWP]: rMWP + '%' }}
                  tooltip={{ formatter: percentageFormatter }}
                  value={data.winnerPercentage!}
                  onChange={(x) => onChange({ winnerPercentage: x })}
                />
              </Flex>
            </Item>
          </Col>
        </Row>
        <Flex gap={16} justify="space-evenly" align="center">
          <Card>
            <Statistic
              title="Jugadores mínimos"
              value={minContestants}
              valueStyle={{ color: '#4287f5' }}
            />
          </Card>
          <Card>
            <Statistic
              title="Jugadores máximos"
              value={maxContestants}
              valueStyle={{ color: '#cf1322' }}
            />
          </Card>
          <Card>
            <Statistic
              title="Minímo de Ganadores"
              value={minWinnerCount}
              valueStyle={{ color: '#3f8600' }}
            />
          </Card>
        </Flex>
        <FixturesTable
          sport={data.sport ?? ''}
          dates={dateRange}
          onChange={onFixtureChange}
          selectedFixtures={fixtures}
        />
      </Space>
    </Form>
  );
};

const getContestantsLimit = (
  min: number,
  max: number,
  pot: number,
  prize: number,
  entry: number,
  fee: number
) => {
  const noPrize = prize <= 0;
  const noEntry = !entry || entry <= 0;
  const notValid = noPrize || noEntry;

  const investment = prize + pot;
  const sale = (entry * fee) / 100;
  const profit = investment / sale;

  return {
    minContestants: notValid ? 1 : profit * (1 + min / 100),
    maxContestants: notValid ? 5000 : profit * (1 + max / 100),
  };
};

const getContestantStats = ({
  winType,
  entry,
  pot,
  prizeAmount,
  minProfit,
  maxProfit,
  winnerPercentage,
  fee,
}: {
  winType: ContestWinType;
  pot: number;
  entry: number;
  prizeAmount: string;
  minProfit: number;
  maxProfit: number;
  winnerPercentage: number;
  fee: number;
}) => {
  const prize = isNaN(Number(prizeAmount)) ? 0 : Number(prizeAmount);

  let minContestants: number,
    maxContestants: number,
    minWinnerCount: number,
    minWinnerPercentage: number,
    recommendedMinWinnerPercentage: number;

  // If contest win type is pot, manually set the values
  if (winType === ContestWinType.POT) {
    minContestants = 2;
    maxContestants = 5000;
    minWinnerCount = 1;
    minWinnerPercentage = 1;
    recommendedMinWinnerPercentage = 1;
  } else {
    const contestantsLimits = getContestantsLimit(
      minProfit,
      maxProfit,
      pot,
      prize,
      entry,
      fee
    );
    minContestants = contestantsLimits.minContestants;
    maxContestants = contestantsLimits.maxContestants;

    const tier = [
      { count: 5, minWinnerCount: 1, topWinners: 1, blockWinners: 0 },
      { count: 15, minWinnerCount: 3, topWinners: 3, blockWinners: 0 },
      { count: 30, minWinnerCount: 5, topWinners: 5, blockWinners: 10 },
      { count: Infinity, minWinnerCount: 10, topWinners: 10, blockWinners: 20 },
    ].find((x) => minContestants <= x.count)!;

    recommendedMinWinnerPercentage =
      (tier.topWinners + tier.blockWinners) / minContestants;

    minWinnerPercentage = Math.max(
      winnerPercentage,
      recommendedMinWinnerPercentage
    );

    minWinnerCount = Math.max(
      (minContestants * minWinnerPercentage) / 100,
      tier.minWinnerCount
    );
  }

  return {
    maxContestants: Math.ceil(maxContestants),
    minContestants: Math.ceil(minContestants),
    minWinnerCount: Math.ceil(minWinnerCount),
    minWinnerPercentage: Math.ceil(minWinnerPercentage),
    recommendedMinWinnerPercentage: Math.ceil(
      recommendedMinWinnerPercentage * 100
    ),
  };
};
