import { ClearOutlined } from '@ant-design/icons';
import { PointSchemaRange as PSR } from '@cobra/common/dist/graphql/generated/graphql';
import { Button, Col, Input, Modal, Row } from 'antd';
import { useState } from 'react';
import useArray from '../../../../hooks/useArray';

type PointSchemaRange = Omit<PSR, '__typename'>;

export const ScoreRange = ({
  range,
  onChange,
}: {
  range: PointSchemaRange[];
  onChange: (range: PointSchemaRange[]) => void;
}) => {
  const [open, toggle] = useState(false);
  const {
    array: rng,
    set,
    push,
    remove,
    update,
  } = useArray<PointSchemaRange>(range);

  return (
    <>
      <Button onClick={() => toggle(true)} style={{ width: '100%' }}>
        {(range ?? []).length < 1 ? 'Definir rangos' : 'Editar rangos'}
      </Button>
      <Modal
        width={'auto'}
        styles={{
          body: {
            overflowY: 'auto',
            overflowX: 'hidden',
            maxHeight: 'calc(100vh - 10rem)',
            minWidth: 300,
          },
        }}
        centered
        title="Rangos de puntos"
        open={open}
        onCancel={() => {
          set(range);
          toggle(false);
        }}
        onOk={() => {
          onChange(rng);
          toggle(false);
        }}
      >
        {rng.map(({ threshold, points }, i) => (
          <Row key={i} gutter={16} style={{ marginBottom: 16 }}>
            <Col span={11}>
              <Input
                type="number"
                prefix="Desde puntaje:"
                value={threshold}
                onChange={(e) =>
                  update(i, {
                    threshold: Number(e.target.value),
                    points,
                  })
                }
              />
            </Col>
            <Col span={11}>
              <Input
                type="number"
                prefix="Recibe:"
                suffix="ptos"
                value={points}
                onChange={(e) =>
                  update(i, {
                    threshold,
                    points: Number(e.target.value),
                  })
                }
              />
            </Col>
            <Col span={2}>
              <Button onClick={() => remove(i)} icon={<ClearOutlined />} />
            </Col>
          </Row>
        ))}
        <Button
          style={{ width: '100%' }}
          onClick={() => {
            const prev = rng[rng.length - 1] ?? { points: 1, threshold: 0 };
            push({
              points: prev.points * 2,
              threshold: prev.threshold * 2,
            });
          }}
        >
          Añadir rango
        </Button>
      </Modal>
    </>
  );
};
