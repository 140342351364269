import { ClearOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Row, Col, Input, Button, Space, Select, Form, Flex } from 'antd';
import useArray from '../../../../hooks/useArray';
import {
  CompetitionConfigSchemaInput,
  TransitionType,
} from '@cobra/common/dist/graphql/generated/graphql';
import { useEffect } from 'react';

const { Item } = Form;

export const CompetitionConfigSchema = ({
  config,
  onChange,
}: {
  config: CompetitionConfigSchemaInput[] | null | undefined;
  onChange: (phases: CompetitionConfigSchemaInput[]) => void;
}) => {
  const schema = useArray(config ?? []);

  useEffect(() => {
    onChange(schema.array);
  }, [schema.array]);

  return (
    <>
      <Space direction="vertical" style={{ width: '100%' }}>
        {schema.array.map((transition, i) => (
          <Row gutter={16} style={{ maxWidth: '100%' }}>
            <Col span={4}>
              <Item label="Desde">
                <Select
                  value={transition.from.type}
                  options={Object.values(TransitionType).map((x) => ({
                    label: x,
                    value: x,
                  }))}
                  onChange={(x) => {
                    const upd = { ...transition };
                    upd.from.type = x;
                    schema.update(i, { ...upd });
                  }}
                />
              </Item>
            </Col>
            <Col span={3}>
              <Item
                label={
                  transition.from.type === TransitionType.FIXTURE
                    ? 'Nro.'
                    : 'ID'
                }
              >
                <Input
                  value={transition.from.id}
                  type="number"
                  onChange={(x) => {
                    const upd = { ...transition };
                    upd.from.id = Number(x.target.value);
                    schema.update(i, { ...upd });
                  }}
                />
              </Item>
            </Col>
            <Col span={3}>
              <Item label="Puesto">
                <Input
                  value={transition.from.place}
                  type="number"
                  onChange={(x) => {
                    const upd = { ...transition };
                    upd.from.place = Number(x.target.value);
                    schema.update(i, { ...upd });
                  }}
                />
              </Item>
            </Col>

            <Col span={3}>
              <Flex justify="center" align="center" style={{ height: '100%' }}>
                <ArrowRightOutlined />
              </Flex>
            </Col>

            <Col span={4}>
              <Item label="Hacia">
                <Select
                  value={transition.to.type}
                  options={Object.values(TransitionType).map((x) => ({
                    label: x,
                    value: x,
                  }))}
                  onChange={(x) => {
                    const upd = { ...transition };
                    upd.to.type = x;
                    schema.update(i, { ...upd });
                  }}
                />
              </Item>
            </Col>
            <Col span={3}>
              <Item
                label={
                  transition.to.type === TransitionType.FIXTURE ? 'Nro.' : 'ID'
                }
              >
                <Input
                  value={transition.to.id}
                  type="number"
                  onChange={(x) => {
                    const upd = { ...transition };
                    upd.to.id = Number(x.target.value);
                    schema.update(i, { ...upd });
                  }}
                />
              </Item>
            </Col>
            <Col span={3}>
              <Item label="Posición">
                <Input
                  value={transition.to.position}
                  type="number"
                  onChange={(x) => {
                    const upd = { ...transition };
                    upd.to.position = Number(x.target.value);
                    schema.update(i, { ...upd });
                  }}
                />
              </Item>
            </Col>

            <Col span={1}>
              <Button
                onClick={() => schema.remove(i)}
                icon={<ClearOutlined />}
              />
            </Col>
          </Row>
        ))}
        <Button
          onClick={() =>
            schema.push({
              from: { id: 1, place: 1, type: TransitionType.FIXTURE },
              to: { id: 1, position: 1, type: TransitionType.FIXTURE },
            })
          }
        >
          Añadir nueva transición
        </Button>
      </Space>
    </>
  );
};
