import React, { FC, useEffect, useState } from 'react';
import {
  SportType,
  SportsFilterInput,
} from '@cobra/common/dist/graphql/generated/graphql';
import { Input, Select, Button } from 'antd';
import useDebounce from '../../../hooks/useDebounce';

const initialFilterData: SportsFilterInput = {
  name: '',
  type: null,
};

export const SportFilters: FC<{
  onChange: (filterData: SportsFilterInput) => void;
  openModal: () => void;
}> = ({ onChange, openModal }) => {
  const [filterData, setFilterData] = useState(initialFilterData);
  const filters = useDebounce(filterData);

  const updateFilterData = (data: Partial<SportsFilterInput>) => {
    setFilterData((p) => ({ ...p, ...data }));
  };

  useEffect(() => {
    onChange(filters);
  }, [filters, onChange]);

  return (
    <>
      <Input
        allowClear
        placeholder="Nombre..."
        value={filterData.name ?? undefined}
        onChange={(x) => updateFilterData({ name: x.target.value })}
      />
      <Select
        placeholder="Tipo"
        value={filterData.type}
        onChange={(x) => updateFilterData({ type: x })}
      >
        <Select.Option value={null}>Cualquiera</Select.Option>
        {Object.entries(SportType).map(([k, v]) => (
          <Select.Option value={k} key={k}>
            {v}
          </Select.Option>
        ))}
      </Select>
      <Button type="primary" onClick={openModal}>
        Nuevo deporte
      </Button>
    </>
  );
};
