import gql from 'graphql-tag';
import { ContestEntry } from '../fragments/contest-entry';

export const CONTEST_ENTRY = gql`
  query getContestEntry($id: ID!) {
    getContestEntry(id: $id) {
      ...ContestEntry
    }
  }
  ${ContestEntry}
`;

export const CONTEST_ENTRIES = gql`
  query getUserContestEntries($userId: ID!, $contestId: ID!) {
    getUserContestEntries(userId: $userId, contestId: $contestId) {
      ...ContestEntry
    }
  }
  ${ContestEntry}
`;

export const LEADERBOARD = gql`
  query leaderboard($id: ID!, $offset: Int!, $limit: Int!) {
    leaderboard(id: $id, offset: $offset, limit: $limit) {
      ...ContestEntry
    }
  }
  ${ContestEntry}
`;
