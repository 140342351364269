import gql from 'graphql-tag';
import { SportAllInfo } from '../fragments/sport';

export const SPORT = gql`
  query sport($id: String!) {
    sport(id: $id) {
      ...SportAllInfo
    }
  }
  ${SportAllInfo}
`;
export const SPORTS = gql`
  query sports($filters: SportsFilterInput) {
    sports(filters: $filters) {
      ...SportAllInfo
    }
  }
  ${SportAllInfo}
`;
