import { DeleteOutlined, StarFilled, StarOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  ContestFilterInput,
  ContestQuery,
  ContestQueryVariables,
  ContestType,
  ContestWinType,
  ContestsCountQuery,
  ContestsCountQueryVariables,
  ContestsQuery,
  ContestsQueryVariables,
  RemoveContestMutation,
  RemoveContestMutationVariables,
  WriteContestInput,
  WriteContestMutation,
  WriteContestMutationVariables,
} from '@cobra/common/dist/graphql/generated/graphql';
import { ContestMutations } from '@cobra/common/dist/graphql/mutations';
import { ContestQueries } from '@cobra/common/dist/graphql/queries';
import { Button, Popconfirm } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import Money from 'dinero.js';
import { isEmpty, isNil } from 'ramda';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import useArray from '../../../hooks/useArray';
import { useFormState } from '../../../hooks/useFormState';
import { usePagination } from '../../../hooks/usePagination';
import { Layout } from '../../Layout';
import { ContestFilters } from './filters';
import { ContestForm } from './form';

const initialMainIput: WriteContestInput = {
  coupons: undefined,
  currency: undefined,
  entryAmount: undefined,
  featured: undefined,
  id: undefined,
  info: undefined,
  initialPot: undefined,
  maxEntriesPerContestant: undefined,
  maxProfitPercentage: undefined,
  minProfitPercentage: undefined,
  name: undefined,
  prizeAmount: undefined,
  sport: undefined,
  type: undefined,
  winnerPercentage: undefined,
  winType: undefined,
  priority: 0,
};

export const ContestView = () => {
  const [params, setParams] = useSearchParams();
  const id = params.get('id');
  const [contestData, contestForm] = useFormState(initialMainIput);
  const [filter, setFilter] = useState<ContestFilterInput>();
  const { limit, offset, goTo, changeSize } = usePagination();
  const [dates, setDates] = useState<[Dayjs, Dayjs]>([dayjs(), dayjs()]);
  const fixtures = useArray<string>([]);
  const [sponsor, setSponsor] = useState<string>();
  // const { array: invites } = useArray([]);

  // Declare queries and mutations
  const {
    data: { contests } = { contests: [] },
    loading,
    refetch,
  } = useQuery<ContestsQuery, ContestsQueryVariables>(ContestQueries.CONTESTS, {
    fetchPolicy: 'no-cache',
    variables: { offset, limit, filter },
  });

  const { data: { contestsCount } = { contestsCount: 0 }, refetch: count } =
    useQuery<ContestsCountQuery, ContestsCountQueryVariables>(
      ContestQueries.COUNT,
      {
        fetchPolicy: 'no-cache',
        variables: { filter },
      }
    );

  const [fetch] = useLazyQuery<ContestQuery, ContestQueryVariables>(
    ContestQueries.CONTEST,
    {
      onCompleted: ({ contest: { sponsor, fixtures: fixs, ...contest } }) => {
        const upd = { ...initialMainIput };

        for (const key in upd) {
          if (!isNil((contest as any)[key])) {
            upd[key as keyof typeof upd] = (contest as any)[key];
          }
        }

        contestForm.reset(upd);
        setSponsor(sponsor?.id);
        fixtures.set(fixs.map((x) => x.id));
        if (fixs.length > 0) {
          setDates([
            dayjs(fixs[0].startDate),
            dayjs(fixs[fixs.length - 1].startDate),
          ]);
        }
        // const invitesUpd = contest.conte.map((x) => x.id);
      },
      fetchPolicy: 'no-cache',
    }
  );

  const queryTable = useCallback(() => {
    refetch({ offset, limit, filter });
    count({ filter });
  }, [offset, limit, filter]);

  const [save] = useMutation<
    WriteContestMutation,
    WriteContestMutationVariables
  >(ContestMutations.WRITE, {
    onCompleted: queryTable,
    fetchPolicy: 'no-cache',
  });

  const [remove] = useMutation<
    RemoveContestMutation,
    RemoveContestMutationVariables
  >(ContestMutations.DELETE, {
    onCompleted: queryTable,
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    queryTable();
  }, [queryTable]);

  useEffect(() => {
    if (isNil(id) || isEmpty(id)) {
      contestForm.reset(initialMainIput);
    } else if (id === 'new') {
      contestForm.reset({
        ...initialMainIput,
        initialPot: 0,
        entryAmount: 0,
        maxProfitPercentage: 100,
        minProfitPercentage: 30,
        winnerPercentage: 0,
        fee: 100,
        type: ContestType.PUBLIC,
        winType: ContestWinType.REGULAR,
      });
      setSponsor(undefined);
      fixtures.clear();
    } else {
      fetch({ variables: { id } });
    }
  }, [id]);

  return (
    <Layout<(typeof contests)[0]>
      table={{
        data: contests,
        loading,
        columns: [
          { title: 'Nombre', dataIndex: 'name' },
          { title: 'Status', dataIndex: 'status' },
          { title: 'Contestants', dataIndex: 'contestantCount' },
          { title: 'Type', dataIndex: 'type' },
          { title: 'Sport', dataIndex: 'sport' },
          {
            title: 'Entrada',
            dataIndex: 'entryAmount',
            render: (amount: string) => (
              <span>{Money({ amount: Number(amount) }).toFormat()}</span>
            ),
          },
          {
            title: 'Premios',
            dataIndex: 'prizeAmount',
            render: (amount: string) => (
              <span>
                {isNaN(amount as any)
                  ? amount.length > 60
                    ? amount.substring(0, 57) + '...'
                    : amount
                  : Money({ amount: Number(amount) }).toFormat()}
              </span>
            ),
          },

          { title: 'Created', dataIndex: 'createdAt' },
          {
            title: 'Participantes',
            dataIndex: 'contestantCount',
            render: (x, item) => (
              <span>
                {x}/{item.maxContestants}
              </span>
            ),
          },
          {
            title: 'Featured',
            dataIndex: 'featured',
            render: (x) =>
              x ? (
                <StarFilled color="dodgerblue" />
              ) : (
                <StarOutlined color="gray" />
              ),
          },
          {
            title: 'Acciones',
            render: (t, x) => (
              <Popconfirm
                title="¿Desea borrar este equipo?"
                onConfirm={() => remove({ variables: { id: x.id } })}
              >
                <Button>
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            ),
          },
        ],
        pagination: {
          pageSize: limit,
          total: contestsCount,
          onChange: (p) => goTo(p),
          onSizeChange: (p, s) => changeSize(s),
        },
        onRowClick: (x) => {
          if (id === x.id)
            setParams((p) => {
              p.delete('id');
              return p;
            });
          else
            setParams((p) => {
              p.set('id', x.id);
              return p;
            });
        },
      }}
      filters={
        <ContestFilters
          onChange={setFilter}
          openModal={() =>
            setParams((p) => {
              p.set('id', 'new');
              return p;
            })
          }
        />
      }
      modal={{
        state: !isEmpty(id) && !isNil(id),
        title: id === 'new' ? 'Nueva quiniela' : contestData.name ?? '',
        onCancel: () =>
          setParams((p) => {
            p.delete('id');
            return p;
          }),
        onOk: async () => {
          const { data } = await save({
            variables: {
              data: contestForm.difference({ keep: ['name', 'id', 'sport'] }),
              extra: { fixtures: fixtures.array, sponsor },
            },
          });
          if (!data) return;

          setParams((p) => {
            p.set('id', data.writeContest.id);
            return p;
          });
        },
        element: (
          <ContestForm
            data={contestData}
            onChange={contestForm.update}
            fixtures={fixtures.array}
            dateRange={dates}
            onFixtureChange={fixtures.set}
            sponsor={sponsor}
            onSponsorChange={(x) => setSponsor(x)}
          />
        ),
      }}
    />
  );
};
