import React, { FC, useEffect, useState } from 'react';
import {
  FixtureFilter,
  FixtureStatus,
  FixtureTypes,
} from '@cobra/common/dist/graphql/generated/graphql';
import { Button, Select, DatePicker, TimeRangePickerProps } from 'antd';
import useDebounce from '../../../hooks/useDebounce';
import { SportSelector } from '../../Selector/SportSelector';
import dayjs from 'dayjs';
import { CompetitionSelector } from '../../Selector/CompetitionSelector';

const { utc } = dayjs;

const initialFilterData: FixtureFilter = {
  competitionId: undefined,
  sport: undefined,
  start: utc().startOf('day').toDate(),
  end: utc().endOf('day').add(1, 'month').toDate(),
  status: FixtureStatus.NOT_STARTED,
  type: undefined,
};

export const FixtureFilters: FC<{
  onChange: (filterData: FixtureFilter) => void;
  openModal: () => void;
}> = ({ onChange, openModal }) => {
  const [filterData, setFilterData] = useState(initialFilterData);
  const filters = useDebounce(filterData);

  const updateFilterData = (data: FixtureFilter) => {
    setFilterData((p) => ({ ...p, ...data }));
  };

  useEffect(() => {
    onChange(filters);
  }, [filters]);

  return (
    <>
      <CompetitionSelector
        competition={filterData.competitionId}
        onChange={(x) => updateFilterData({ competitionId: x })}
      />

      <Select
        mode="multiple"
        placeholder="Tipo"
        allowClear
        value={filterData.type}
        options={Object.entries(FixtureTypes).map(([k, v]) => ({
          value: v,
          label: k,
        }))}
        onChange={(x) => updateFilterData({ type: x.length ? x : undefined })}
      />

      <Select
        placeholder="Status"
        allowClear
        value={filterData.status}
        options={Object.entries(FixtureStatus).map(([k, v]) => ({
          value: v,
          label: k,
        }))}
        onChange={(x) => updateFilterData({ status: x })}
      />

      <SportSelector
        isFilter
        sport={filterData.sport?.[0]}
        onChange={(x) => updateFilterData({ sport: x })}
      />

      <DatePicker.RangePicker
        value={[
          filterData.start ? dayjs(filterData.start) : undefined,
          filterData.end ? dayjs(filterData.end) : undefined,
        ]}
        placeholder={['Desde el inicio', 'Hasta hoy']}
        allowEmpty={[false, true]}
        allowClear
        presets={rangePresets}
        onChange={(x) => {
          console.log(x);
          if (x != null) {
            updateFilterData({
              start: x[0] ? x[0].toDate() : undefined,
              end: x[1] ? x[1].toDate() : undefined,
            });
          } else {
            updateFilterData({ start: undefined, end: undefined });
          }
        }}
      />

      <Button type="primary" onClick={openModal}>
        Nuevo partido
      </Button>
    </>
  );
};

const rangePresets: TimeRangePickerProps['presets'] = [
  {
    label: 'Próximo mes',
    value: [utc().startOf('day'), utc().endOf('day').add(1, 'month')],
  },
  {
    label: 'Próxima semana',
    value: [utc().startOf('day'), utc().endOf('day').add(1, 'week')],
  },
  {
    label: 'Últimos 7 días',
    value: [utc().startOf('day').subtract(7, 'days'), undefined],
  },
  {
    label: 'Últimos 14 días',
    value: [utc().startOf('day').subtract(14, 'days'), undefined],
  },
  {
    label: 'Último mes',
    value: [utc().startOf('day').subtract(1, 'month'), undefined],
  },
  {
    label: 'Último cuartil',
    value: [utc().startOf('day').subtract(3, 'months'), undefined],
  },
  {
    label: 'Último año',
    value: [utc().startOf('day').subtract(1, 'year'), undefined],
  },
];
