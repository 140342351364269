import { css, cx } from '@emotion/css';

export const darkGrey = '#e8e8e8';

export const errorStyle = css({
  color: 'red',
  height: '40px',
  textAlign: 'center',
});

export const centeredColumn = css({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

export const leftAlignedButtonStyle = css({
  marginBottom: 16,
  alignSelf: 'flex-start',
});

export const createContainerStyle = cx(
  centeredColumn,
  css({
    width: '60%',
    alignSelf: 'center',
    alignItems: 'stretch',
  }),
);
