import gql from 'graphql-tag';
import { CompetitionConfig } from './competition';

export const fixtureParticipant = gql`
  fragment FixtureParticipant on Fixture {
    participants {
      id
      score
      odds
      proposedId
      type
      name
      image
      position
    }
  }
`;

export const allInfo = gql`
  fragment allInfo on Fixture {
    competition {
      id
      logoUrl
      name
      region
      sport
      type
      groups
      phases
      ...CompetitionConfig
    }
    createdAt
    id
    startDate
    status
    type
    phase
    numberOfMatch
    ...FixtureParticipant
  }
  ${fixtureParticipant}
  ${CompetitionConfig}
`;
