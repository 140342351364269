import { EnumLiteralsOf } from '../typings/index';

export type ErrorCode = EnumLiteralsOf<typeof ERROR_CODES>;
export const ERROR_CODES = Object.freeze({
  BAD_IMAGE_TYPE: 'BAD_IMAGE_TYPE' as 'BAD_IMAGE_TYPE',
  BAD_PRIVATE_CONTEST_DISTRIBUTION: 'BAD_PRIVATE_CONTEST_DISTRIBUTION' as 'BAD_PRIVATE_CONTEST_DISTRIBUTION',
  BALANCE_TOO_LOW: 'BALANCE_TOO_LOW' as 'BALANCE_TOO_LOW',
  INVALID_CASHOUT_REQUEST: 'INVALID_CASHOUT_REQUEST' as 'INVALID_CASHOUT_REQUEST',
  INVALID_DEPOSIT_AMOUNT: 'INVALID_DEPOSIT_AMOUNT' as 'INVALID_DEPOSIT_AMOUNT',
  // INVALID_EXTERNAL_REQUEST is used for errors in webhook endpoints that are
  // only supposed to be hit by third parties, like directa24.
  INVALID_EXTERNAL_REQUEST: 'INVALID_EXTERNAL_REQUEST' as 'INVALID_EXTERNAL_REQUEST',
  INVALID_FIXTURE_DATE: 'INVALID_FIXTURE_DATE' as 'INVALID_FIXTURE_DATE',
  INVALID_FIXTURE_IDS: 'INVALID_FIXTURE_IDS' as 'INVALID_FIXTURE_IDS',
  INVALID_FIXTURE_SPORT: 'INVALID_FIXTURE_SPORT' as 'INVALID_FIXTURE_SPORT',
  INVALID_NAME: 'INVALID_NAME' as 'INVALID_NAME',
  INVALID_PICKS: 'INVALID_PICKS' as 'INVALID_PICKS',
  NO_FRIENDS_FOUND: 'NO_FRIENDS_FOUND' as 'NO_FRIENDS_FOUND',
  PAYMENT_METHOD_NOT_FOUND: 'PAYMENT_METHOD_NOT_FOUND' as 'PAYMENT_METHOD_NOT_FOUND',
  TRANSACTION_NOT_FOUND: 'TRANSACTION_NOT_FOUND' as 'TRANSACTION_NOT_FOUND',
  USER_NOT_FOUND: 'USER_NOT_FOUND' as 'USER_NOT_FOUND',
  CONTEST_INVITE_NOT_FOUND: 'CONTEST_INVITE_NOT_FOUND' as 'CONTEST_INVITE_NOT_FOUND',
  // COMMONS
  INVALID_LOGIN_COMBINATION: 'INVALID_LOGIN_COMBINATION' as 'INVALID_LOGIN_COMBINATION',
  MINIMUM_2_FIXTURES: 'minimum2Fixtures' as 'minimum2Fixtures',
  INVALID_URL: 'INVALID_URL' as 'INVALID_URL',
  INVALID_RFC: 'INVALID_RFC' as 'INVALID_RFC',
});
