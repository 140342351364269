import React from 'react';
import { Navigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Card, Empty } from 'antd';
import { css } from '@emotion/css';

import { Role } from '@cobra/common/dist/utils/constants';
import { AuthenticatedUserQuery as authenticatedUser } from '@cobra/common/dist/graphql/generated/graphql';
import { AUTHENTICATED_USER } from '@cobra/common/dist/graphql/queries';

const container = css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

interface ProtectedRouteProps {
  Component: React.ComponentType<any>;
  isLoggedIn: boolean;
  requiresSuperAdmin?: boolean;
}

const ProtectedRoute = (props: ProtectedRouteProps) => {
  const { Component, isLoggedIn, requiresSuperAdmin } = props;

  const { data, loading, error } =
    useQuery<authenticatedUser>(AUTHENTICATED_USER);

  if (!isLoggedIn) {
    return <Navigate to="/" />;
  }

  if (loading) {
    return (
      <div className={container}>
        <Card loading />
      </div>
    );
  }

  if (error) {
    return (
      <div className={container}>
        <Empty />
      </div>
    );
  }

  if (!data) return <Empty />;

  const {
    authenticatedUser: { role },
  } = data;

  if (requiresSuperAdmin && role !== Role.SuperAdmin) {
    return <Navigate to="/dashboard" />;
  }

  const ComponentWithRouter = Component;

  return (
    <div className={container}>
      <ComponentWithRouter />
    </div>
  );
};

export default ProtectedRoute;
