import { Phases } from './phases';

export const PHASENAMES = {
  [Phases.DEFAULT]: 'N/A',
  [Phases.GROUPS]: 'Grupos',
  [Phases.ROUNDOF32]: '16vos',
  [Phases.ROUNDOF16]: '8vos',
  [Phases.QUARTER]: '4tos',
  [Phases.SEMI]: 'Semifinal',
  [Phases.FINAL3rd]: 'Final de terceros',
  [Phases.FINAL]: 'Final',
} as const;
