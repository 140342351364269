import { ApolloClient } from '@apollo/client';
import { FormInstance } from 'antd/lib/form/Form';

export const logOut = (client: ApolloClient<object>) => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  client.resetStore().catch((err) => {
    console.error(err);
  });
};

export const getBaseballFormStats = (form: FormInstance) => {
  const { getFieldValue } = form;
  const stats = {
    homePitcher: {
      name: getFieldValue('homePitcherName'),
      wins: getFieldValue('homePitcherWins'),
      losses: getFieldValue('homePitcherLosses'),
    },
    awayPitcher: {
      name: getFieldValue('awayPitcherName'),
      wins: getFieldValue('awayPitcherWins'),
      losses: getFieldValue('awayPitcherLosses'),
    },
  };

  return JSON.stringify(stats);
};
