import {
  TeamType,
  UpdateTeamInput,
} from '@cobra/common/dist/graphql/generated/graphql';
import { Input, Select, Space, Switch, Form } from 'antd';
import React, { FC } from 'react';
import { SportSelector } from '../../Selector/SportSelector';
import { RegionSelector } from '../../Selector/RegionSelector';

const { Item } = Form;

export const TeamForm: FC<{
  data: UpdateTeamInput;
  onChange: (data: Partial<UpdateTeamInput>) => void;
}> = ({ data, onChange }) => {
  return (
    <Space size={16} direction="vertical">
      <Item label="Nombre">
        <Input
          placeholder="name"
          value={data?.name ?? undefined}
          onChange={(x) => onChange({ name: x.target.value })}
        />
      </Item>
      <Item label="Logo">
        <Input
          placeholder="logo url"
          value={data?.image ?? undefined}
          onChange={(x) => onChange({ image: x.target.value })}
        />
      </Item>
      <Item label="Es placeholder">
        <Switch
          checked={data.placeholder ?? false}
          onChange={(x) => onChange({ placeholder: x })}
        />
      </Item>

      <Item label="Tipo">
        <Select
          style={{ width: '100%' }}
          value={data.type}
          onChange={(x) => onChange({ type: x })}
          options={Object.entries(TeamType).map(([k, v]) => ({
            value: v,
            label: k,
          }))}
        />
      </Item>
      <Item label="Region">
        <RegionSelector
          region={data.countryCode}
          onChange={(x) => onChange({ countryCode: x })}
        />
      </Item>
      <Item label="Deporte">
        <SportSelector
          sport={data.sport}
          onChange={(x) => onChange({ sport: x })}
        />
      </Item>
    </Space>
  );
};
