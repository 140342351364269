import gql from 'graphql-tag';

// import * as betFragments from './bet';
// import * as fixtureFragments from './fixture';
// import { Prediction } from './prediction';

export const allNames = gql`
  fragment userAllNames on User {
    firstName
    lastName
    username
  }
`;

export const fullName = gql`
  fragment userFullName on User {
    firstName
    lastName
  }
`;

export const documentId = gql`
  fragment userDocumentId on User {
    documentId {
      lastFour
    }
  }
`;

// const allJoinedGames = gql`
//   fragment AllJoined on JoinedGames {
//     bets {
//       amount
//       ...BettorMainInfo
//       ...TakerMainInfo
//       createdAt
//       fixture {
//         id
//         startDate
//         competition {
//           id
//           logoUrl
//         }
//         ...FixtureParticipant
//       }
//       id
//       odds {
//         homeTeamOdds
//         awayTeamOdds
//       }
//       pick
//       status
//       type
//     }
//     contests {
//       id
//       info {
//         createdAt
//         id
//         predictions {
//           ...Prediction
//         }
//         points
//         prize
//         rank
//       }
//       entries {
//         createdAt
//         id
//         predictions {
//           ...Prediction
//         }
//         points
//         coupon
//         prize
//         rank
//       }
//       contest {
//         contestantCount
//         createdAt
//         entryAmount
//         fixtures {
//           id
//           startDate
//           competition {
//             id
//             logoUrl
//           }
//         }
//         id
//         maxContestants
//         minContestants
//         minWinnerCount
//         name
//         prizeAmount
//         sport
//         status
//         type
//         featured
//       }
//     }
//   }
//   ${fixtureFragments.fixtureParticipant}
//   ${betFragments.BettorMainInfo}
//   ${betFragments.TakerMainInfo}
//   ${Prediction}
// `;

export const contestsJoined = gql`
  fragment contestsJoined on User {
    contestsJoined {
      contestantCount
      coupons
      createdAt
      entryAmount
      id
      initialPot
      maxContestants
      maxEntriesPerContestant
      minContestants
      minWinnerCount
      name
      prizeAmount
      sport
      status
      type
      winType
      featured
    }
  }
`;

export const stats = gql`
  fragment userStats on User {
    lossCount
    winCount
  }
`;

export const friends = gql`
  fragment userFriends on User {
    friends(offset: $friendsOffset, limit: $friendsLimit) {
      firstName
      lastName
      id
      profileImageUrl
      username
    }
  }
`;
