import { min, identity } from 'ramda';
import Money from 'dinero.js';

import { WithPoints, WithPrize } from '../../typings';
import { getPlacement } from '../contest';
import {
  TOP_3_DISTRIBUTION,
  TOP_5_DISTRIBUTION,
  TOP_10_DISTRIBUTION,
  Percentages,
} from '../contest-distribution';

interface WithId {
  id: string;
}

interface DistributePrizeOptions {
  isPrivateContest?: boolean;
  minWinnerCount: number;
}

type PrizeDistribution = [number, string][];

export const distributeTopOnePrize = <T extends WithId & WithPoints>(
  prize: number,
  leaderboard: T[]
): PrizeDistribution => {
  const placement = getPlacement(leaderboard);
  const placedFirst = placement[1];
  const individualPrize = placedFirst.length
    ? Money({ amount: prize }).divide(placedFirst.length)
    : Money({ amount: 0 });

  return placedFirst.map(winner => [individualPrize.getAmount(), winner.id]);
};

const doDistribution = <T extends WithId & WithPoints & Partial<WithPrize>>(
  prize: number,
  leaderboard: T[],
  percentageDistribution: Percentages
): PrizeDistribution => {
  const percentages = percentageDistribution.slice();
  const placement = getPlacement(leaderboard);
  // places will look like [1, 2, 3] or [1, 2, 3, 4, 5]
  const places = percentageDistribution.reduce(acc => {
    return [...acc, acc.length + 1];
  }, [] as number[]);
  const topWinners = places.map(place => placement[place]).filter(identity);
  const distribution: PrizeDistribution = [];

  topWinners.some(winners => {
    // if we have no more prize money to distribute, then we are done
    if (!percentageDistribution.length) return true;

    // If 2 people placed first, the total prize would be 60 + 25.
    const percentagesToTake = min(winners.length, places.length);
    const totalPrizePercentage = percentages
      .splice(0, percentagesToTake)
      .reduce((total, percentage) => total + percentage);
    // There can also be block prizes involved if people from second and
    // third block tied with top players.
    const blockPrize = winners.reduce((acc, winner) => {
      if (winner.prize) {
        const result = acc + winner.prize;
        // We don't want the indiviudal block prize for each user in the
        // final distribution.
        // eslint-disable-next-line no-param-reassign
        delete winner.prize;
        return result;
      }

      return acc;
    }, 0);
    const totalPrize = Money({ amount: prize })
      .percentage(totalPrizePercentage)
      .add(Money({ amount: blockPrize }));
    const individualPrize = winners.length
      ? totalPrize.divide(winners.length)
      : Money({ amount: 0 });

    winners.forEach(winner =>
      distribution.push([individualPrize.getAmount(), winner.id])
    );

    // Keep going.
    return false;
  });

  return distribution;
};

const doBlockDistribution = <
  T extends WithId & WithPoints & Partial<WithPrize>
>(
  prize: number,
  leaderboard: T[],
  minWinnerCount: number,
  percentageDistribution: Percentages
): PrizeDistribution => {
  const topWinnersCount = percentageDistribution.length;
  const topWinners = leaderboard.slice(0, topWinnersCount);
  const blockContestantCount = Math.ceil(
    (minWinnerCount - topWinnersCount) / 2
  );
  const secondBlockEnd = topWinnersCount + blockContestantCount;

  // Determine what's the prize for each contestant in the third block
  const thirdBlock = leaderboard.slice(secondBlockEnd, minWinnerCount);
  const lastFromThirdBlock = thirdBlock[thirdBlock.length - 1];
  // Check if there are more people (outside of the original third block) that
  // tied with the last person of the third block.
  leaderboard.slice(minWinnerCount).some(contestant => {
    if (contestant.points !== lastFromThirdBlock.points) return true;

    thirdBlock.push(contestant);

    return false;
  });
  const thirdBlockIndividualPrize = thirdBlock.length
    ? Money({ amount: prize })
        .percentage(5)
        .divide(thirdBlock.length)
    : Money({ amount: 0 });
  thirdBlock.forEach(user => {
    // eslint-disable-next-line no-param-reassign
    user.prize = thirdBlockIndividualPrize.getAmount();
  });

  // Determine what's the prize for each contestant in the second block.
  const secondBlock = leaderboard.slice(topWinnersCount, secondBlockEnd);
  const lastFromSecondBlock = secondBlock[secondBlock.length - 1];
  let secondBlockTotalPrize = Money({ amount: prize })
    .percentage(10)
    .getAmount();
  thirdBlock.slice().some(thirdBlockWinner => {
    if (thirdBlockWinner.points !== lastFromSecondBlock.points) return true;

    secondBlockTotalPrize += thirdBlockIndividualPrize.getAmount();
    secondBlock.push(thirdBlockWinner);
    thirdBlock.splice(0, 1);

    return false;
  });
  const secondBlockIndividualPrize = secondBlock.length
    ? Money({
        amount: secondBlockTotalPrize,
      }).divide(secondBlock.length)
    : Money({ amount: 0 });
  secondBlock.forEach(user => {
    // eslint-disable-next-line no-param-reassign
    user.prize = secondBlockIndividualPrize.getAmount();
  });
  const winners = [...topWinners, ...secondBlock, ...thirdBlock];
  const distribution = doDistribution(prize, winners, percentageDistribution);

  // Add remaining block winners after percentage distribution
  if (distribution.length < minWinnerCount) {
    const blockDistribution = winners
      .slice(distribution.length)
      .map(blockWinner => {
        const result = [blockWinner.prize, blockWinner.id] as [number, string];
        // We don't want the indiviudal block prize for each user in the
        // final distribution.
        // eslint-disable-next-line no-param-reassign
        delete blockWinner.prize;
        return result;
      });

    return [...distribution, ...blockDistribution];
  }

  return distribution;
};

export const distributeTopThreePrize = <T extends WithId & WithPoints>(
  prize: number,
  leaderboard: T[]
): PrizeDistribution => doDistribution(prize, leaderboard, TOP_3_DISTRIBUTION);

export const distributeTopFivePrize = <T extends WithId & WithPoints>(
  prize: number,
  leaderboard: T[],
  minWinnerCount: number
): PrizeDistribution =>
  doBlockDistribution(prize, leaderboard, minWinnerCount, TOP_5_DISTRIBUTION);

export const distributeTopTenPrize = <T extends WithId & WithPoints>(
  prize: number,
  leaderboard: T[],
  minWinnerCount: number
): PrizeDistribution =>
  doBlockDistribution(prize, leaderboard, minWinnerCount, TOP_10_DISTRIBUTION);

const distributePrivateContestPrize = <T extends WithId & WithPoints>(
  prize: number,
  leaderboard: T[],
  minWinnerCount: number
) => {
  // Private contest only has top 1 and top 3 distribution
  if (minWinnerCount === 1) return distributeTopOnePrize(prize, leaderboard);

  return distributeTopThreePrize(prize, leaderboard);
};

const distributePrize = <T extends WithId & WithPoints>(
  prize: number,
  leaderboard: T[],
  { minWinnerCount, isPrivateContest }: DistributePrizeOptions
): PrizeDistribution => {
  if (!leaderboard.length) return [];

  if (isPrivateContest) {
    return distributePrivateContestPrize(prize, leaderboard, minWinnerCount);
  }

  if (minWinnerCount < 3) return distributeTopOnePrize(prize, leaderboard);

  if (minWinnerCount <= 15) return distributeTopThreePrize(prize, leaderboard);

  if (minWinnerCount <= 30) {
    return distributeTopFivePrize(prize, leaderboard, minWinnerCount);
  }

  return distributeTopTenPrize(prize, leaderboard, minWinnerCount);
};

export default distributePrize;
