import {
  DeleteOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import type { GetProp, UploadProps } from 'antd';
import { Avatar, Button, Image, Upload, message } from 'antd';
import { useState } from 'react';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

// const getBase64 = (img: FileType, callback: (url: string) => void) => {
//   const reader = new FileReader();
//   reader.addEventListener('load', () => callback(reader.result as string));
//   reader.readAsDataURL(img);
// };

const beforeUpload = (file: FileType) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
};

export const UploadImageInput = ({
  name,
  preview,
  avatar = false,
  onChange,
}: {
  name: string;
  preview: string | undefined | null;
  avatar?: boolean;
  onChange: (image: string | undefined) => void;
}) => {
  const [loading, setLoading] = useState(false);
  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');

  const handleChange: UploadProps['onChange'] = (info) => {
    console.log({ info });

    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    setLoading(false);
    // if (info.file.url) onChange(info.file.url);
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      onChange(info.file.response);
      // getBase64(info.file.originFileObj as FileType, (url) => {
      //   console.log({ url });
      // });
    }
  };

  return (
    <>
      <Upload
        name="file"
        listType="picture"
        maxCount={1}
        multiple={false}
        showUploadList={false}
        headers={{
          Authorization: `Bearer ${accessToken}, Bearer ${refreshToken}`,
        }}
        action={`${process.env.REACT_APP_API_URL}/upload/image`}
        data={{ name }}
        beforeUpload={beforeUpload}
        onChange={handleChange}
      >
        {preview ? (
          avatar ? (
            <Avatar src={preview} />
          ) : (
            <Image src={preview} preview={false} height={180} width="auto" />
          )
        ) : (
          <button style={{ border: 0, background: 'none' }} type="button">
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
          </button>
        )}
      </Upload>
      {preview && (
        <Button onClick={() => onChange(undefined)}>
          <DeleteOutlined />
        </Button>
      )}
    </>
  );
};
