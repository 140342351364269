import gql from 'graphql-tag';

import * as betFragments from './bet';
import * as contestFragments from './contest';

export const PayoutMainInfo = gql`
  fragment PayoutMainInfo on Payout {
    amount
    balance
    bet {
      ...BetMainInfo
    }
    completed
    contest {
      ...contestAll
    }
    createdAt
    id
    payoutType: type
  }
  ${betFragments.BetMainInfo}
  ${contestFragments.all}
`;
