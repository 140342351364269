import React from 'react';
import { useApolloClient } from '@apollo/client';
import { Card, Empty } from 'antd';
import { css } from '@emotion/css';
import * as R from 'ramda';

import { Helpers } from '@cobra/common';
import { AUTHENTICATED_USER } from '@cobra/common/dist/graphql/queries';

const container = css({ width: '100%' });
const card = css`
  width: 300px;
  > div > p {
    text-transform: lowercase;
  }
`;

const Dashboard = () => {
  const client = useApolloClient();
  const data = client.readQuery({ query: AUTHENTICATED_USER });

  if (R.isEmpty(data)) return <Empty />;

  const { authenticatedUser: user } = data;
  const { username, email, role } = user;

  return (
    <div className={container}>
      <Card title={<span>{`Dashboard - ${username}`}</span>} className={card}>
        <p>{email}</p>
        <p>{Helpers.snakeToLower(role || '')}</p>
      </Card>
    </div>
  );
};

export default Dashboard;
