import React, { FC, useEffect, useState } from 'react';
import {
  ContestFilterInput,
  ContestStatus,
  ContestType,
} from '@cobra/common/dist/graphql/generated/graphql';
import { Input, Button, Select, DatePicker, TimeRangePickerProps } from 'antd';
import useDebounce from '../../../hooks/useDebounce';
import { SportSelector } from '../../Selector/SportSelector';
import dayjs from 'dayjs';
import { isNil } from 'ramda';

const initialFilterData: ContestFilterInput = {
  coupons: undefined,
  created: undefined,
  creator: undefined,
  currency: undefined,
  entryAmount: undefined,
  featured: undefined,
  id: undefined,
  name: undefined,
  prizeAmount: undefined,
  sport: undefined,
  startDate: undefined,
  status: undefined,
  type: undefined,
  updated: undefined,
  winType: undefined,
};

export const ContestFilters: FC<{
  onChange: (filterData: ContestFilterInput) => void;
  openModal: () => void;
}> = ({ onChange, openModal }) => {
  const [filterData, setFilterData] = useState(initialFilterData);
  const filters = useDebounce(filterData);

  const updateFilterData = (data: ContestFilterInput) => {
    setFilterData((p) => ({ ...p, ...data }));
  };

  useEffect(() => {
    console.log({ filterData });
  }, [filterData]);

  useEffect(() => {
    console.log({ filters });
    onChange(filters);
  }, [filters, onChange]);

  return (
    <>
      <Input
        width={'100px'}
        allowClear
        placeholder="Nombre..."
        value={filterData.name ?? undefined}
        onChange={(x) => updateFilterData({ name: x.target.value })}
      />

      <Select
        mode="multiple"
        placeholder="Tipo"
        allowClear
        value={filterData.type}
        options={Object.entries(ContestType).map(([k, v]) => ({
          value: v,
          label: k,
        }))}
        onChange={(x) => updateFilterData({ type: x.length ? x : undefined })}
      />

      <Select
        mode="multiple"
        placeholder="Status"
        allowClear
        value={filterData.status}
        options={Object.entries(ContestStatus).map(([k, v]) => ({
          value: v,
          label: k,
        }))}
        onChange={(x) => updateFilterData({ status: x.length ? x : undefined })}
      />

      <Select
        allowClear
        placeholder="Destacado"
        value={filterData.featured}
        options={[
          { value: true, label: 'Destacado' },
          { value: false, label: 'Normal' },
        ]}
        onChange={(x) =>
          updateFilterData({ featured: isNil(x) ? undefined : x })
        }
      />

      <SportSelector
        isFilter
        sport={filterData.sport?.[0]}
        onChange={(x) => updateFilterData({ sport: !x ? null : [x] })}
      />

      <DatePicker.RangePicker
        value={[
          filterData.created?.from
            ? dayjs(filterData.created?.from)
            : undefined,
          filterData.created?.to ? dayjs(filterData.created?.to) : undefined,
        ]}
        maxDate={dayjs()}
        placeholder={['Desde el inicio', 'Hasta hoy']}
        allowEmpty={[false, true]}
        allowClear
        presets={rangePresets}
        onChange={(x) => {
          console.log(x);
          if (x != null) {
            updateFilterData({
              created: {
                from: x[0] ? x[0].toDate() : undefined,
                to: x[1] ? x[1].toDate() : undefined,
              },
            });
          } else {
            updateFilterData({ created: undefined });
          }
        }}
      />

      <Button type="primary" onClick={openModal}>
        Nueva quiniela
      </Button>
    </>
  );
};

const rangePresets: TimeRangePickerProps['presets'] = [
  { label: 'Últimos 7 días', value: [dayjs().subtract(7, 'days'), undefined] },
  {
    label: 'Últimos 14 días',
    value: [dayjs().subtract(14, 'days'), undefined],
  },
  { label: 'Último mes', value: [dayjs().subtract(1, 'month'), undefined] },
  {
    label: 'Último cuartil',
    value: [dayjs().subtract(3, 'months'), undefined],
  },
  { label: 'Último año', value: [dayjs().subtract(1, 'year'), undefined] },
];
