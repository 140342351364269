import React, { FC, useEffect, useState } from 'react';
import {
  TeamType,
  FilterTeamInput,
} from '@cobra/common/dist/graphql/generated/graphql';
import { Input, Select, Button } from 'antd';
import useDebounce from '../../../hooks/useDebounce';

const initialFilterData: FilterTeamInput = {
  competitions: undefined,
  countryCode: undefined,
  id: undefined,
  name: undefined,
  placeholder: undefined,
  sports: undefined,
  types: undefined,
};

export const TeamFilters: FC<{
  onChange: (filterData: FilterTeamInput) => void;
  openModal: () => void;
}> = ({ onChange, openModal }) => {
  const [filterData, setFilterData] = useState(initialFilterData);
  const filters = useDebounce(filterData);

  const updateFilterData = (data: Partial<FilterTeamInput>) => {
    setFilterData((p) => ({ ...p, ...data }));
  };

  useEffect(() => {
    onChange(filters);
  }, [filters, onChange]);

  return (
    <>
      <Input
        allowClear
        placeholder="Nombre..."
        value={filterData.name ?? undefined}
        onChange={(x) => updateFilterData({ name: x.target.value })}
      />
      <Select
        placeholder="Tipo"
        mode="multiple"
        value={filterData.types}
        onChange={(x) => updateFilterData({ types: x.length ? x : undefined })}
      >
        <Select.Option value={null}>Cualquiera</Select.Option>
        {Object.entries(TeamType).map(([k, v]) => (
          <Select.Option value={k} key={k}>
            {v}
          </Select.Option>
        ))}
      </Select>
      <Button type="primary" onClick={openModal}>
        Nuevo participante
      </Button>
    </>
  );
};
