import { REGIONS_AND_COUNTRIES } from '@cobra/common/dist/utils/constants';
import { Select } from 'antd';

export const RegionSelector = ({
  region,
  onChange,
  isFilter = false,
}: {
  region: string | undefined | null;
  onChange: (region: string) => void;
  isFilter?: boolean;
}) => (
  <Select
    showSearch
    placeholder="Seleccione una región"
    optionFilterProp="children"
    value={
      !region
        ? isFilter
          ? 'Cualquiera'
          : undefined
        : REGIONS_AND_COUNTRIES[region]
    }
    onChange={onChange}
    filterOption={(input, option) => {
      if (!option) return false;
      const { children } = option;
      if (!children) return false;
      if (typeof children !== 'string') return false;

      return (children as string).toLowerCase().includes(input.toLowerCase());
    }}
  >
    {isFilter && <Select.Option value={undefined}>Cualquiera</Select.Option>}
    {Object.keys(REGIONS_AND_COUNTRIES).map((regionCode) => (
      <Select.Option key={regionCode} value={regionCode}>
        {REGIONS_AND_COUNTRIES[regionCode]}
      </Select.Option>
    ))}
  </Select>
);
