import { useQuery } from '@apollo/client';
import {
  CompetitionsQuery,
  CompetitionsQueryVariables,
  FixtureFilter,
} from '@cobra/common/dist/graphql/generated/graphql';
import { CompetitionQueries } from '@cobra/common/dist/graphql/queries';
import { Select } from 'antd';
import { useEffect } from 'react';

export const CompetitionSelector = ({
  competition,
  sport,
  onChange,
}: {
  competition: FixtureFilter['competitionId'];
  sport?: string;
  onChange: (ids: string) => void;
}) => {
  const getVariables = () => {
    const variables = {
      limit: 100,
      offset: 0,
      options: {
        active: true,
      } as FixtureFilter,
    };
    if (sport) variables.options.sport = sport;
    return variables;
  };

  const {
    data: { competitions: options } = { competitions: [] },
    loading,
    refetch,
  } = useQuery<CompetitionsQuery, CompetitionsQueryVariables>(
    CompetitionQueries.COMPETITIONS,
    {
      variables: getVariables(),
      fetchPolicy: 'no-cache',
    }
  );

  useEffect(() => {
    refetch(getVariables());
  }, [sport]);

  return (
    <Select
      disabled={loading}
      value={competition ?? undefined}
      allowClear
      labelRender={(x) => options.find((y) => y.id === x.value)?.name}
      placeholder="Seleccione una competencia"
      onChange={(x) => onChange(x)}
      showSearch
      optionFilterProp="label"
      options={options
        .filter((option) => competition !== option.id)
        .map((option) => ({
          ...option,
          value: option.id,
          label: option.name,
        }))}
    />
  );
};
