import gql from 'graphql-tag';

export const TransactionMainInfo = gql`
  fragment TransactionMainInfo on Transaction {
    amount
    balance
    bankName
    createdAt
    id
    transactionStatus: status
    transactionType: type
  }
`;
