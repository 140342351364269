import React, { useEffect } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  TeamQuery,
  TeamQueryVariables,
  TeamsQuery,
  TeamsQueryVariables,
  UpdateTeamMutation,
  UpdateTeamMutationVariables,
  CreateTeamMutation,
  CreateTeamMutationVariables,
  UpdateTeamInput,
  CreateTeamInput,
  TeamType,
  DeleteTeamMutation,
  DeleteTeamMutationVariables,
  JoinCompetitionsMutation,
  JoinCompetitionsMutationVariables,
  LeaveCompetitionMutation,
  LeaveCompetitionMutationVariables,
} from '@cobra/common/dist/graphql/generated/graphql';
import { TeamMutations } from '@cobra/common/dist/graphql/mutations';
import { TeamQueries } from '@cobra/common/dist/graphql/queries';
import { TeamFilters } from './filters';
import { TeamForm } from './form';
import { Layout } from '../../Layout';
import { useFormState } from '../../../hooks/useFormState';
import { DeleteOutlined } from '@ant-design/icons';
import { Popconfirm, Button, Flex } from 'antd';
import useArray from '../../../hooks/useArray';
import { CompetitionsSelector } from '../../Selector/CompetitionsSelector';
import { useIdParam } from '../../../hooks/useIdParam';

const initialSportData: UpdateTeamInput & { id: string | null | undefined } = {
  id: undefined,
  name: undefined,
  countryCode: undefined,
  image: undefined,
  placeholder: false,
  sport: undefined,
  type: TeamType.CLUB,
};

export const TeamsView = () => {
  const id = useIdParam();
  const [teamData, teamForm] = useFormState(initialSportData);
  const competitions = useArray<string>([]);

  // Declare queries and mutations
  const {
    data: { teams } = { teams: [] },
    loading,
    refetch,
  } = useQuery<TeamsQuery, TeamsQueryVariables>(TeamQueries.TEAMS, {
    fetchPolicy: 'no-cache',
  });

  const [fetch] = useLazyQuery<TeamQuery, TeamQueryVariables>(
    TeamQueries.TEAM,
    {
      onCompleted: ({ team: { competitions: cs, ...team } }) => {
        teamForm.reset(team ?? undefined);
        competitions.reset(cs.map((c) => c.id));
        if (!id.is(team.id)) id.set(team.id);
      },
      fetchPolicy: 'no-cache',
    }
  );

  const [createTeam] = useMutation<
    CreateTeamMutation,
    CreateTeamMutationVariables
  >(TeamMutations.CREATE, {
    onCompleted: () => refetch(),
    fetchPolicy: 'no-cache',
  });

  const [updateTeam] = useMutation<
    UpdateTeamMutation,
    UpdateTeamMutationVariables
  >(TeamMutations.UPDATE, {
    onCompleted: () => refetch(),
    fetchPolicy: 'no-cache',
  });

  const [join] = useMutation<
    JoinCompetitionsMutation,
    JoinCompetitionsMutationVariables
  >(TeamMutations.JOIN_COMPETITIONS);

  const [leave] = useMutation<
    LeaveCompetitionMutation,
    LeaveCompetitionMutationVariables
  >(TeamMutations.LEAVE_COMPETITION);

  const [deleteTeam] = useMutation<
    DeleteTeamMutation,
    DeleteTeamMutationVariables
  >(TeamMutations.DELETE, {
    onCompleted: () => refetch(),
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (id.isEmpty) {
      teamForm.reset(initialSportData);
      competitions.reset([]);
    } else {
      fetch({ variables: { id: id.value } });
    }
  }, [id.value]);

  return (
    <Layout
      table={{
        data: teams,
        loading,
        columns: [
          { title: 'Nombre', dataIndex: 'name' },
          {
            title: 'Acciones',
            render: (t, x) => (
              <Popconfirm
                title="¿Desea borrar este equipo?"
                onConfirm={() => deleteTeam({ variables: { id: x.id } })}
              >
                <Button>
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            ),
          },
        ],
        onRowClick: (x) => {
          if (id.is(x.id)) id.clear();
          else id.set(x.id);
        },
      }}
      filters={
        <TeamFilters
          onChange={(filters) =>
            refetch({ offset: 0, limit: 100, data: filters })
          }
          openModal={() => id.set('new')}
        />
      }
      modal={{
        autoWidth: false,
        state: !id.isEmpty,
        title: id.isNew ? 'Nuevo participante' : teamData.name ?? '',
        onCancel: id.clear,
        onOk: async () => {
          const { id: tid, ...tdata } = teamData;
          if (id.isNew) {
            const { data } = await createTeam({
              variables: { data: tdata as CreateTeamInput },
            });
            if (data?.createTeam.id && competitions.added.length) {
              join({
                variables: {
                  teamId: data.createTeam.id,
                  competitionIds: competitions.array,
                },
              });
            }
          } else {
            const { data } = await updateTeam({
              variables: { id: tid!, data: tdata },
            });
            if (data?.updateTeam.id) {
              if (competitions.added.length) {
                await join({
                  variables: {
                    teamId: data.updateTeam.id,
                    competitionIds: competitions.added,
                  },
                });
              }
              if (competitions.removed.length) {
                const promises = competitions.removed.map((x) =>
                  leave({
                    variables: {
                      teamId: data.updateTeam.id,
                      competitionId: x,
                    },
                  })
                );

                await Promise.all(promises);
              }
            }
          }
        },
        element: (
          <Flex vertical gap={16}>
            <TeamForm data={teamData} onChange={teamForm.update} />
            <CompetitionsSelector
              competitions={competitions.array}
              sport={teamData.sport ?? undefined}
              onChange={competitions.set}
            />
          </Flex>
        ),
      }}
    />
  );
};
