import gql from 'graphql-tag';

import * as betFragments from '../fragments/bet';
import * as fixtureFragments from '../fragments/fixture';

export const CREATE_BETS = gql`
  mutation createBets($data: CreateBetsInput!) {
    createBets(data: $data) {
      ...BettorFullInfo
      ...TakerFullInfo
      amount
      currency
      fixture {
        id
        ...FixtureParticipant
        competition {
          id
          name
          region
        }
      }
      id
      pick
      status
      type
    }
  }
  ${fixtureFragments.allInfo}
  ${betFragments.BettorFullInfo}
  ${betFragments.TakerFullInfo}
`;

export const ACCEPT = gql`
  mutation acceptBet($betId: ID!) {
    acceptBet(id: $betId) {
      id
      balance
    }
  }
`;

export const CREATE = CREATE_BETS;

export const DECLINE = gql`
  mutation declineBet($betId: ID!) {
    declineBet(id: $betId) {
      id
      balance
    }
  }
`;
