import {
  ApolloLink,
  ApolloClient,
  createHttpLink,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import { logOut } from './utils/helpers';

const authLink = setContext((_, { headers = {} }) => {
  // get the authentication token from local storage if it exists
  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization:
        accessToken && refreshToken
          ? `Bearer ${accessToken}, Bearer ${refreshToken}`
          : '',
    },
  };
});

export const ErrorLink: {
  client: ApolloClient<NormalizedCacheObject> | null;
  injectClient: (
    client: ApolloClient<NormalizedCacheObject>
  ) => ApolloClient<NormalizedCacheObject>;
  create: () => ApolloLink;
} = {
  client: null, // injected after the apollo client gets created
  injectClient: (client: ApolloClient<NormalizedCacheObject>) => {
    ErrorLink.client = client;
    return client;
  },
  create: () =>
    onError(({ graphQLErrors, networkError }) => {
      if (networkError && 'bodyText' in networkError) {
        // https://github.com/apollographql/apollo-feature-requests/issues/153
        // Check if error response is JSON.
        try {
          JSON.parse(networkError.bodyText);
        } catch (e) {
          // If not replace parsing error message with real one
          // eslint-disable-next-line no-param-reassign
          networkError.message = networkError.bodyText;
        }
      }

      if (graphQLErrors) {
        const sessionExpired = graphQLErrors.some(({ extensions }) => {
          if (extensions && extensions.code === 'UNAUTHENTICATED') return true;

          return false;
        });

        if (ErrorLink.client !== null && sessionExpired) {
          logOut(ErrorLink.client);
        }
      }
    }),
};

const afterwareLink = new ApolloLink((operation, forward) => {
  if (!forward) throw new Error('no forward in afterwareLink');

  return forward(operation).map((response) => {
    const context = operation.getContext();
    const { response: { headers } = { headers: null } } = context;

    if (headers) {
      const accessToken = headers.get('Access-Token');

      if (accessToken) localStorage.setItem('accessToken', accessToken);
    }

    return response;
  });
});

const httpLink = createHttpLink({
  // REACT_APP_API_URL is injected by vercel at build time. When developing
  // locally set it via .env file.
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
  credentials: 'include',
});

const createApolloLink = () => {
  const errorLink = ErrorLink.create();

  return ApolloLink.from([authLink, errorLink, afterwareLink, httpLink]);
};

export default createApolloLink;
