import { Phases } from './phases';

type PointsSpecs = {
  winner: number;
  looser: number;
  teamInPhase: number;
  teamInCorrectSpot: number;
  correctScore: number;
  max?: number;
};

export const PointsSchema: Record<Phases, PointsSpecs> = {
  DEFAULT: {
    // default calculation values
    // +1 point per winner guessed
    winner: 1,
    looser: 0,
    teamInCorrectSpot: 0,
    teamInPhase: 0,
    correctScore: 0,
  },
  GROUPS: {
    winner: 3,
    looser: 0,
    teamInPhase: 0,
    teamInCorrectSpot: 0,
    correctScore: 0,
    max: 144,
  },
  ROUNDOF32: {
    winner: 3,
    looser: 0,
    teamInPhase: 5,
    teamInCorrectSpot: 5,
    correctScore: 0,
    max: 160,
  },
  ROUNDOF16: {
    winner: 3,
    looser: 0,
    teamInPhase: 5,
    teamInCorrectSpot: 5,
    correctScore: 0,
    max: 160,
  },
  QUARTER: {
    winner: 3,
    looser: 0,
    teamInPhase: 15,
    teamInCorrectSpot: 0,
    correctScore: 0,
    max: 120,
  },
  SEMI: {
    winner: 3,
    looser: 0,
    teamInPhase: 20,
    teamInCorrectSpot: 0,
    correctScore: 0,
    max: 80,
  },
  FINAL3rd: {
    winner: 30,
    looser: 20,
    teamInPhase: 0,
    teamInCorrectSpot: 0,
    correctScore: 0,
  },
  FINAL: {
    winner: 50,
    looser: 40,
    teamInPhase: 0,
    teamInCorrectSpot: 0,
    correctScore: 0,
    max: 140,
  },
} as const;
