import gql from 'graphql-tag';
import { Config } from './competition-config';

export const CompetitionConfig = gql`
  fragment CompetitionConfig on Competition {
    config {
      ...Config
    }
  }
  ${Config}
`;

export const CompetitionShallow = gql`
  fragment CompetitionShallow on Competition {
    id
    active
    groups
    logoUrl
    name
    phases
    region
    sport
    type
    ...CompetitionConfig
  }
  ${CompetitionConfig}
`;

export const CompetitionNoConfig = gql`
  fragment CompetitionNoConfig on Competition {
    createdAt
    ...CompetitionShallow
    fixtures {
      id
    }
    teams {
      id
    }
  }

  ${CompetitionShallow}
`;

export const CompetitionAllInfo = gql`
  fragment CompetitionAllInfo on Competition {
    ...CompetitionNoConfig
    ...CompetitionConfig
  }
  ${CompetitionNoConfig}
  ${CompetitionConfig}
`;
