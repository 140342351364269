import { negate } from 'ramda';

const oddsToNegativeImpliedProbability = (odds: number) =>
  (negate(odds) / (negate(odds) + 100)) * 100;

const oddsToPositiveImpliedProbability = (odds: number) =>
  (100 / (odds + 100)) * 100;

const getRealPercentage = (
  impliedProbability: number,
  totalProbability: number
) => (100 * impliedProbability) / totalProbability;

const roundPercentage = (prob: number) =>
  prob >= 50 ? Math.floor(prob) : Math.ceil(prob);

export const resolveOdds = ({
  awayTeamOdds,
  homeTeamOdds,
  tieOdds,
}: {
  awayTeamOdds: number;
  homeTeamOdds: number;
  tieOdds?: number;
}) => {
  let tiePercentage = 0;

  if (tieOdds) {
    tiePercentage =
      tieOdds >= 0
        ? oddsToPositiveImpliedProbability(tieOdds)
        : oddsToNegativeImpliedProbability(tieOdds);
  }
  const homePercentage =
    homeTeamOdds >= 0
      ? oddsToPositiveImpliedProbability(homeTeamOdds)
      : oddsToNegativeImpliedProbability(homeTeamOdds);
  const awayPercentage =
    awayTeamOdds >= 0
      ? oddsToPositiveImpliedProbability(awayTeamOdds)
      : oddsToNegativeImpliedProbability(awayTeamOdds);
  const totalPercentage = homePercentage + awayPercentage + tiePercentage;
  const realHomePercentage = roundPercentage(
    getRealPercentage(homePercentage, totalPercentage)
  );
  const realAwayPercentage = roundPercentage(
    getRealPercentage(awayPercentage, totalPercentage)
  );

  return {
    awayTeamOdds: realAwayPercentage,
    awayTeamVegasOdds: awayTeamOdds,
    homeTeamOdds: realHomePercentage,
    homeTeamVegasOdds: homeTeamOdds,
    tieOdds: tiePercentage,
    tieVegasOdds: tieOdds,
  };
};
