import gql from 'graphql-tag';

export const Prediction = gql`
  fragment Prediction on Prediction {
    fixtureId
    items {
      id
      score
      position
      winner
      proposed
    }
  }
`;
