import { DeleteOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  RemoveSponsorMutation,
  RemoveSponsorMutationVariables,
  SponsorInput,
  SponsorQuery,
  SponsorQueryVariables,
  SponsorsQuery,
  SponsorsQueryVariables,
  WriteSponsorMutation,
  WriteSponsorMutationVariables,
} from '@cobra/common/dist/graphql/generated/graphql';
import { SponsorMutations } from '@cobra/common/dist/graphql/mutations';
import { SponsorQueries } from '@cobra/common/dist/graphql/queries';
import { Avatar, Button, Flex, Popconfirm } from 'antd';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import useArray from '../../../hooks/useArray';
import { useFormState } from '../../../hooks/useFormState';
import { useIdParam } from '../../../hooks/useIdParam';
import { Layout } from '../../Layout';
import { SponsorsFilters } from './filters';
import { SponsorForm } from './form';

const initialSportData: () => SponsorInput = () => ({
  id: uuidv4(),
  name: '',
  active: false,
  banner: '',
  bannerInHome: false,
  logo: '',
  popup: '',
  popupInHome: false,
});

export const SponsorsView = () => {
  const id = useIdParam();
  const [data, form] = useFormState(initialSportData());
  const competitions = useArray<string>([]);

  // Declare queries and mutations
  const {
    data: { sponsors = [] } = { sponsors: [] },
    loading,
    refetch,
  } = useQuery<SponsorsQuery, SponsorsQueryVariables>(SponsorQueries.SPONSORS, {
    fetchPolicy: 'no-cache',
  });

  const [fetch] = useLazyQuery<SponsorQuery, SponsorQueryVariables>(
    SponsorQueries.SPONSOR,
    {
      onCompleted: ({ sponsor }) => {
        if (!sponsor) return;
        form.reset(sponsor);
        if (!id.is(sponsor.id)) id.set(sponsor.id);
      },
      fetchPolicy: 'no-cache',
    }
  );
  const [writeSponsor] = useMutation<
    WriteSponsorMutation,
    WriteSponsorMutationVariables
  >(SponsorMutations.WRITE, {
    onCompleted: () => {
      refetch();
      form.reset(data);
    },
    fetchPolicy: 'no-cache',
  });

  const [deleteSponsor] = useMutation<
    RemoveSponsorMutation,
    RemoveSponsorMutationVariables
  >(SponsorMutations.REMOVE, {
    onCompleted: () => refetch(),
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (id.isEmpty) {
      form.reset(initialSportData());
      competitions.reset([]);
    } else {
      fetch({ variables: { id: id.value } });
    }
  }, [id.value]);

  return (
    <Layout
      table={{
        data: sponsors,
        loading,
        columns: [
          {
            title: '',
            dataIndex: 'logo',
            render: (x) => <Avatar size="large" shape="circle" src={x} />,
            width: 42,
          },
          { title: 'Nombre', dataIndex: 'name' },
          {
            title: 'Active',
            dataIndex: 'active',
            render: (x) => (x ? <CheckCircleFilled /> : <CloseCircleFilled />),
          },
          {
            title: 'Acciones',
            render: (t, x) => (
              <Popconfirm
                title="¿Desea borrar este equipo?"
                onConfirm={() => deleteSponsor({ variables: { id: x.id } })}
              >
                <Button>
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            ),
          },
        ],
        onRowClick: (x) => {
          if (id.is(x.id)) id.clear();
          else id.set(x.id);
        },
      }}
      filters={
        <SponsorsFilters
          onChange={(filters) => refetch({ offset: 0, limit: 100, filters })}
          openModal={id.set}
        />
      }
      modal={{
        autoWidth: false,
        state: !id.isEmpty,
        title: id.isNew ? 'Nuevo participante' : data.name ?? '',
        onCancel: id.clear,
        onOk: async () => {
          await writeSponsor({
            variables: { data },
          });
        },
        disabled: !form.changed,
        element: (
          <Flex vertical gap={16}>
            <SponsorForm data={data} onChange={form.update} />
          </Flex>
        ),
      }}
    />
  );
};
