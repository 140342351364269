import { useQuery } from '@apollo/client';
import {
  CompetitionsQuery,
  CompetitionsQueryVariables,
} from '@cobra/common/dist/graphql/generated/graphql';
import { CompetitionQueries } from '@cobra/common/dist/graphql/queries';
import { Select, Tag } from 'antd';
import { useEffect } from 'react';

export const CompetitionsSelector = ({
  competitions,
  onChange,
  sport,
}: {
  competitions: string[];
  onChange: (ids: string[]) => void;
  sport?: string;
}) => {
  const {
    data: { competitions: options } = { competitions: [] },
    loading,
    refetch,
  } = useQuery<CompetitionsQuery, CompetitionsQueryVariables>(
    CompetitionQueries.COMPETITIONS,
    {
      variables: { limit: 100, offset: 0, options: { sport } },
      fetchPolicy: 'no-cache',
    }
  );

  useEffect(() => {
    if (sport) refetch();
  }, [sport]);

  return (
    <Select
      mode="multiple"
      disabled={loading}
      value={competitions}
      tagRender={(x) => (
        <Tag {...x}>{options.find((y) => y.id === x.value)?.name}</Tag>
      )}
      placeholder="Seleccione una competencia"
      onChange={(x) => onChange(x)}
      showSearch
      optionFilterProp="label"
      options={options
        .filter((option) => !competitions.includes(option.id))
        .map((option) => ({
          ...option,
          value: option.id,
          label: option.name,
        }))}
    />
  );
};
