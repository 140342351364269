import deepFreeze from 'deep-freeze';
import { getCodeList } from 'country-list';
import { clone } from 'ramda';
import { Currency } from 'dinero.js';

import { EnumLiteralsOf } from '../typings';

import { PHASES, Phases } from '../constanst/phases';
import { PHASENAMES } from '../constanst/phasesNames';
import { PointsSchema } from '../constanst/points';
import { VenezuelaBanks } from '../constanst/venezuelaBanks';
import {
  DepositMethodProperties,
  DepositMethodPropertyOrder,
  WithdrawMethodProperties,
  WithdrawMethodPropertyOrder,
} from '../constanst/options';

export {
  Phases,
  PHASES,
  PointsSchema,
  PHASENAMES,
  DepositMethodProperties,
  DepositMethodPropertyOrder,
  WithdrawMethodProperties,
  WithdrawMethodPropertyOrder,
  VenezuelaBanks,
};

export const Environments = {
  local: 'local',
  staging: 'staging',
  production: 'production',
} as const;

export const CompetitionTypes = {
  Normal: 'NORMAL',
  Tournament: 'TOURNAMENT',
} as const;

export type CompetitionTypes =
  (typeof CompetitionTypes)[keyof typeof CompetitionTypes];

export const ContestTypes = {
  Public: 'PUBLIC',
  VIP: 'VIP',
} as const;
export type ContestTypes = (typeof ContestTypes)[keyof typeof ContestTypes];

export const TeamTypes = {
  Club: 'CLUB',
  Nation: 'NATION',
  Fictional: 'FICTIONAL',
} as const;

export type TeamTypes = (typeof TeamTypes)[keyof typeof TeamTypes];

export type Environments = (typeof Environments)[keyof typeof Environments];

export const Role = {
  SuperAdmin: 'SUPER_ADMIN',
  Admin: 'ADMIN',
  Regular: 'REGULAR',
} as const;

export type Role = (typeof Role)[keyof typeof Role];

export const Language = {
  en: 'en' as 'en',
  es: 'es' as 'es',
} as const;

export type Language = keyof typeof Language;
export interface Localization {
  currency: Currency;
  language: Language;
}

export const Sport = {
  Baseball: 'BASEBALL',
  Basketball: 'BASKETBALL',
  Football: 'FOOTBALL',
  Soccer: 'SOCCER',
  // NHL: 'NHL',
} as const;

export type Sport = (typeof Sport)[keyof typeof Sport];

export const TIEABLE_SPORTS: readonly Sport[] = Object.freeze([Sport.Soccer]);

export const REGIONS = deepFreeze({
  AFRICA: {
    value: 'AFRICA',
    display: 'África',
  },
  ASIA: {
    value: 'ASIA',
    display: 'Asia',
  },
  EUROPE: {
    value: 'EUROPE',
    display: 'Europa',
  },
  NORTH_AMERICA: {
    value: 'NORTH_AMERICA',
    display: 'Norteámerica',
  },
  SOUTH_AMERICA: {
    value: 'SOUTH_AMERICA',
    display: 'Surámerica',
  },
});

export const REGIONS_AND_COUNTRIES = deepFreeze(
  Object.keys(REGIONS).reduce((regionAndCountries, key) => {
    const regionValue = REGIONS[key as keyof typeof REGIONS].value;
    // eslint-disable-next-line no-param-reassign
    regionAndCountries[key] = regionValue;

    return regionAndCountries;
  }, clone(getCodeList()))
);

export const TIE = 'TIE';

export const ChallengeType = {
  Contest: 'CONTEST' as 'CONTEST',
  Bet: 'BET' as 'BET',
} as const;
export type ChallengeType = (typeof ChallengeType)[keyof typeof ChallengeType];

export const PaymentProvider = Object.freeze({
  Cobra: 'COBRA' as 'COBRA',
  Directa24: 'DIRECTA24' as 'DIRECTA24',
});
export type PaymentProvider = EnumLiteralsOf<typeof PaymentProvider>;

export type FriendshipStatus = EnumLiteralsOf<typeof FRIENDSHIP_STATUSES>;
export const FRIENDSHIP_STATUSES = Object.freeze({
  PENDING: 'PENDING' as 'PENDING',
  ACCEPTED: 'ACCEPTED' as 'ACCEPTED',
});

export const NotificationType = {
  BetAccepted: 'BET_ACCEPTED',
  BetDeclined: 'BET_DECLINED',
  BetReceived: 'BET_RECEIVED',
  BetVoided: 'BET_VOIDED',
  ContestRefundReceived: 'CONTEST_REFUND_RECEIVED',
  FriendRequestAccepted: 'FRIEND_REQUEST_ACCEPTED',
  FriendRequestReceived: 'FRIEND_REQUEST_RECEIVED',
  PayoutReceived: 'PAYOUT_RECEIVED',
  PrivateContestInviteReceived: 'PRIVATE_CONTEST_INVITE_RECEIVED',
  USER_IN_ENDED_CONTEST: 'USER_IN_ENDED_CONTEST',
  USER_IN_ENDED_CONTEST_PODIUM: 'USER_IN_ENDED_CONTEST_PODIUM',
  CONTEST_ABOUT_TO_CLOSE: 'CONTEST_ABOUT_TO_CLOSE',
} as const;

export type NotificationType =
  (typeof NotificationType)[keyof typeof NotificationType];

export const NotificationTopic = {
  GENERAL: 'GENERAL',
} as const;

export type NotificationTopic =
  (typeof NotificationTopic)[keyof typeof NotificationTopic];

export const TransactionClientSource = {
  Native: 'Native',
  Web: 'Web',
} as const;

export type TransactionClientSource =
  (typeof TransactionClientSource)[keyof typeof TransactionClientSource];

export interface Directa24DepositBody {
  amount: number;
  bankCode: string;
  clientSource: TransactionClientSource;
}
