import gql from 'graphql-tag';

import * as userFragments from '../fragments/user';
import { contestsJoined } from '../fragments/user';

export const ACTIVE_GAMES = gql`
  query userContestsJoined($id: ID!) {
    user(id: $id) {
      id
      ...contestsJoined
    }
  }
  ${contestsJoined}
`;

export const FRIENDS = gql`
  query userFriends($id: ID!, $friendsOffset: Int!, $friendsLimit: Int!) {
    user(id: $id) {
      ...userFriends
      id
    }
  }
  ${userFragments.friends}
`;

export const SEARCH_USERS_AS_ADMIN = gql`
  query searchUsersAsAdmin(
    $text: String!
    $searchOffset: Int!
    $searchLimit: Int!
  ) {
    search(text: $text, offset: $searchOffset, limit: $searchLimit) {
      ...userAllNames
      id
      balance
    }
  }
  ${userFragments.allNames}
`;

export const DEPOSITS = gql`
  query deposits($username: String!) {
    deposits(username: $username) {
      username
      type
      amount
      reference
      completed
      id
    }
  }
`;

export const WITHDRAWS = gql`
  query withdraws {
    withdraws {
      aba
      account
      amount
      bank
      cedula
      completed
      contactNumber
      email
      id
      personType
      type
      username
    }
  }
`;
