import gql from 'graphql-tag';

import * as authedUserFragments from '../fragments/authed-user';
import * as userFragments from '../fragments/user';

export * as AuthedUser from './authed-user';
export * as BetQueries from './bet';
export * as CompetitionQueries from './competition';
export * as ContestQueries from './contest';
export * as ContestEntriesQueries from './contest-entry';
export * as FixtureQueries from './fixture';
export * as OptionQueries from './option';
export * as SponsorQueries from './sponsor';
export * as SportQueries from './sport';
export * as TeamQueries from './team';
export * as UserQueries from './user';

// export const IS_LOGGED_IN = gql`
//   query isLoggedIn {
//     isLoggedIn @client
//   }
// `;

export const AUTHENTICATED_USER = gql`
  query authenticatedUser {
    authenticatedUser {
      ...userAllNames
      ...userDocumentId
      ...userFriendRequests
      balance
      bio
      email
      id
      profileImageUrl
      role
    }
  }
  ${userFragments.allNames}
  ${userFragments.documentId}
  ${authedUserFragments.friendRequests}
`;

export const AUTHENTICATED_USER_PROFILE = gql`
  query authenticatedUserProfile($friendsLimit: Int!, $friendsOffset: Int!) {
    authenticatedUser {
      ...userAllNames
      ...userDocumentId
      ...userFriends
      ...userFriendRequests
      profileImageUrl
      friendCount
      balance
      bio
      friendCount
      id
      email
    }
  }
  ${userFragments.allNames}
  ${userFragments.documentId}
  ${userFragments.friends}
  ${authedUserFragments.friendRequests}
`;

export const AUTHENTICATED_USER_TRANSACTIONS = gql`
  query authenticatedUserTransactions(
    $transactionsOffset: Int!
    $transactionsLimit: Int!
  ) {
    authenticatedUser {
      ...userTransactions
    }
  }
  ${authedUserFragments.transactions}
`;

export const AUTHENTICATED_USER_DEPOSIT = gql`
  query authenticatedUserDeposit {
    authenticatedUser {
      ...userDepositFields
    }
  }
  ${authedUserFragments.depositFields}
`;

export const ADMINS = gql`
  query admins($offset: Int!, $limit: Int!) {
    admins(offset: $offset, limit: $limit) {
      id
      username
      email
      role
    }
  }
`;

export const AUTHENTICATED_USER_WITHDRAWAL = gql`
  query authenticatedUserWithdrawal {
    authenticatedUser {
      ...userWithdrawalFields
    }
  }
  ${authedUserFragments.withdrawalFields}
`;

export const SEARCH_USERS = gql`
  query searchUsers($text: String!, $searchOffset: Int!, $searchLimit: Int!) {
    search(text: $text, offset: $searchOffset, limit: $searchLimit) {
      ...userAllNames
      id
      profileImageUrl
    }
  }
  ${userFragments.allNames}
`;

export const USER = gql`
  query user($id: ID!) {
    user(id: $id) {
      ...userAllNames
      ...userStats
      bio
      friendCount
      id
      profileImageUrl
    }
  }
  ${userFragments.allNames}
  ${userFragments.stats}
`;
