/* eslint-disable no-prototype-builtins */
import { useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import {
  OptionsQuery,
  UpdateOptionMutation as updateOption,
  UpdateOptionMutationVariables as updateOptionVariables,
} from '@cobra/common/dist/graphql/generated/graphql';
import { OPTIONS } from '@cobra/common/dist/graphql/queries/option';
import { Options as Os } from '@cobra/common/dist/';
import UPDATEOPTION from '@cobra/common/dist/graphql/mutations/option';
import { equals } from 'ramda';
import { Button, Input, Switch, Tabs, Tooltip } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import {
  DepositMethodPropertyOrder as DMPO,
  DepositMethodProperties as DMP,
  WithdrawMethodPropertyOrder as WMPO,
  WithdrawMethodProperties as WMP,
} from '@cobra/common/dist/utils/constants';
import {
  DepositMethod,
  WithdrawMethod,
} from '@cobra/common/dist/typings/option';
import Title from 'antd/lib/typography/Title';

const initialState = {
  WITHDRAW_METHODS: [],
  DEPOSIT_METHODS: [],
  EXCHANGE_RATE: 0,
};

const initialDepositMethod: Os.DepositMethod = {
  code: '',
  name: '',
  email: null,
  aba: null,
  swift: null,
  reference: null,
  accountNumber: null,
  accountName: null,
  warn: null,
  href: null,
  idn: null,
  phone: null,
  paymentConcept: null,
  bank: null,
};

const initialWithdrawMethod: Os.WithdrawMethod = {
  code: '',
  name: '',
  aba: null,
  accountName: null,
  accountNumber: null,
  bank: null,
  idn: null,
  email: null,
  personType: null,
  phone: null,
};

export const Options = () => {
  const [original, setOriginal] = useState<Os.Option>({ ...initialState });
  const [state, setState] = useState<Os.Option>({ ...initialState });

  const [saveOption] = useMutation<updateOption, updateOptionVariables>(
    UPDATEOPTION,
    {
      onCompleted: () => getOptions(),
    }
  );

  const { refetch: getOptions } = useQuery<OptionsQuery>(OPTIONS, {
    onCompleted: ({ options: os }) => {
      setOriginal(
        os.reduce((finalOptions, dbOption) => {
          if (!dbOption) return finalOptions;
          const key = dbOption.id;
          const { value } = dbOption;
          return { ...finalOptions, [key]: value };
        }, {} as Os.Option)
      );
      setState(
        os.reduce((finalOptions, dbOption) => {
          if (!dbOption) return finalOptions;
          const key = dbOption.id;
          let { value } = dbOption;
          if (key === 'DEPOSIT_METHODS') {
            value = dbOption.value.map((x: Os.DepositMethod) => {
              const val = { ...initialDepositMethod };
              Object.entries(x).forEach(([k, v]) => {
                if (v !== null && initialDepositMethod.hasOwnProperty(k)) {
                  val[k as keyof DepositMethod] = v;
                }
              });
              return val;
            });
          }
          if (key === 'WITHDRAW_METHODS') {
            value = dbOption.value.map((x: Os.WithdrawMethod) => {
              const val = { ...initialWithdrawMethod };
              Object.entries(x).forEach(([k, v]) => {
                if (v !== null && initialWithdrawMethod.hasOwnProperty(k)) {
                  val[k as 'personType'] = v as Os.WithdrawMethod['personType'];
                }
              });
              return val;
            });
          }
          return { ...finalOptions, [key]: value };
        }, {} as Os.Option)
      );
    },
  });

  const updateState = (data: Partial<typeof state>) => {
    setState((p) => ({ ...p, ...data }));
  };

  const updateRate = async () => {
    const data = await fetch('https://s3.amazonaws.com/dolartoday/data.json');
    const json = await data.json();
    updateState({ EXCHANGE_RATE: json.USD.dolartoday * 100 });
  };

  const updateArrayProp = <T extends 'DEPOSIT_METHODS' | 'WITHDRAW_METHODS'>(
    propName: T,
    index: number,
    data: Partial<Os.Option[T][0] | null>
  ) => {
    setState((p) => {
      const upd = [...p[propName]];

      if (!data) upd.splice(index, 1);
      else upd[index] = { ...upd[index], ...data };

      return { ...p, [propName]: [...upd] };
    });
  };

  const onEditTab = <T extends 'DEPOSIT_METHODS' | 'WITHDRAW_METHODS'>(
    propName: T,
    initialValue: Os.Option[T][0],
    targetKey:
      | string
      | React.MouseEvent<Element, MouseEvent>
      | React.KeyboardEvent<Element>,
    action: 'add' | 'remove'
  ) => {
    if (action === 'add') {
      updateArrayProp(propName, state[propName].length, initialValue);
    } else {
      updateArrayProp(propName, Number(targetKey), null);
    }
  };

  const handleSubmission = async () => {
    const changes = Object.entries(state).reduce(
      (t, [key, value]) => {
        const upd = [...t];
        if (!equals(value, original[key as keyof typeof initialState]))
          upd.push([
            key as keyof typeof initialState,
            saveOption({
              variables: { id: key, value },
            }),
          ]);
        return upd;
      },
      [] as [keyof typeof initialState, Promise<any>][]
    );

    console.log({ changes, state, original });

    await Promise.all(changes);
    console.log('done');
  };

  return (
    <div className={styles}>
      <div className="title-bar">
        <Title level={2}>Opciones de configuración de la app</Title>
        <Button
          onClick={handleSubmission}
          type="primary"
          disabled={equals(original, state)}
        >
          Guardar
        </Button>
      </div>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Options" key="0">
          <Input
            addonBefore="Tasa de cambio"
            value={(state.EXCHANGE_RATE / 100).toString()}
            onChange={(e) => {
              const float = /^\d+\.\d{0,2}$/;
              const int = /^\d+$/;
              const string = e.target.value;
              if (float.test(string)) {
                updateState({ EXCHANGE_RATE: parseFloat(string) * 100 });
              } else if (int.test(string)) {
                updateState({ EXCHANGE_RATE: parseInt(string, 10) * 100 });
              } else if (string === '') updateState({ EXCHANGE_RATE: 0.0 });
            }}
            addonAfter={
              <Tooltip title="Buscar en DolarToday">
                <ReloadOutlined onClick={updateRate} />
              </Tooltip>
            }
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Depositos" key="1">
          <Tabs
            className="deposit-methods"
            type="editable-card"
            onEdit={(...args) =>
              onEditTab('DEPOSIT_METHODS', initialDepositMethod, ...args)
            }
          >
            {state.DEPOSIT_METHODS.map((x, i) => (
              <Tabs.TabPane key={i.toString()} tab={x.code}>
                {Object.entries(x)
                  .sort(
                    (a, b) =>
                      DMPO.indexOf(a[0] as keyof DepositMethod) -
                      DMPO.indexOf(b[0] as keyof DepositMethod)
                  )
                  .filter((k) => k[0] !== 'reference')
                  .map(([key, value]) => (
                    <Input
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${key}-${i}`}
                      disabled={value === null}
                      value={value ?? ''}
                      onChange={(y) => {
                        console.log({ key, value: y.target.value });

                        updateArrayProp('DEPOSIT_METHODS', i, {
                          [key]: y.target.value,
                        });
                      }}
                      addonBefore={DMP[key as keyof DepositMethod]}
                      addonAfter={
                        <Switch
                          unCheckedChildren="off"
                          checkedChildren="on"
                          checked={value !== null}
                          onChange={(y) =>
                            updateArrayProp('DEPOSIT_METHODS', i, {
                              [key]: y ? '' : null,
                            })
                          }
                        />
                      }
                    />
                  ))}
              </Tabs.TabPane>
            ))}
          </Tabs>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Retiros" key="2">
          <Tabs
            className="withdraw-methods"
            type="editable-card"
            onEdit={(...args) =>
              onEditTab('WITHDRAW_METHODS', initialWithdrawMethod, ...args)
            }
          >
            {state.WITHDRAW_METHODS.map((x, i) => (
              <Tabs.TabPane key={i.toString()} tab={x.code}>
                {Object.entries(x)
                  .sort(
                    (a, b) =>
                      WMPO.indexOf(a[0] as keyof WithdrawMethod) -
                      WMPO.indexOf(b[0] as keyof WithdrawMethod)
                  )
                  .map(([key, value]) => (
                    <Input
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${key}-${i}`}
                      disabled={value === null}
                      value={value ?? ''}
                      onChange={(y) =>
                        updateArrayProp('WITHDRAW_METHODS', i, {
                          [key]: y.target.value,
                        })
                      }
                      addonBefore={WMP[key as keyof WithdrawMethod]}
                      addonAfter={
                        <Switch
                          unCheckedChildren="off"
                          checkedChildren="on"
                          checked={value !== null}
                          onChange={(y) =>
                            updateArrayProp('WITHDRAW_METHODS', i, {
                              [key]: y ? '' : null,
                            })
                          }
                        />
                      }
                    />
                  ))}
              </Tabs.TabPane>
            ))}
          </Tabs>
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

const styles = css`
  .withdraw-methods,
  .deposit-methods {
    .ant-tabs-tabpane {
      display: flex;
      flex-flow: column wrap;
      gap: 1rem;
    }
  }

  .ant-input-group-wrapper .ant-input-group .ant-input-group-addon {
    &:first-child {
      width: 160px;
    }
    &:last-child {
      width: 80px;
    }
  }

  .title-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
