import {
  CompetitionConfigInput,
  WriteCompetitionInput,
} from '@cobra/common/dist/graphql/generated/graphql';
import { Collapse, Form } from 'antd';
import { CompetitionConfigGroups } from './groups';
import { CompetitionConfigPhases } from './phases';
import { CompetitionConfigPoints } from './points';
import { isEmpty } from 'ramda';
import { CompetitionConfigSchema } from './schema';

const { Item } = Form;

export const CompetitionConfigForm = ({
  data,
  onChange,
}: {
  data: WriteCompetitionInput;
  onChange: (config: CompetitionConfigInput) => void;
}) => {
  const updateConfig = (update: CompetitionConfigInput) => {
    const keys = Object.keys(update) as (keyof CompetitionConfigInput)[];

    const newConfig = { ...data.config };

    for (const key of keys) {
      if (isEmpty(update[key])) delete newConfig[key];
      else newConfig[key] = update[key] as any;
    }
    console.log('fired from Config');
    onChange(newConfig);
  };

  return (
    <>
      <Form>
        <Item>
          <Collapse
            defaultActiveKey={[]}
            items={[
              {
                key: 'groups',
                label: 'Groups',
                children: data.id ? (
                  <CompetitionConfigGroups
                    context={data}
                    config={data.config?.groups}
                    onChange={(x) => updateConfig({ groups: x })}
                  />
                ) : (
                  <span>
                    Para configurar los grupos, primero es necesrio crear los
                    equipos que van a participar en la competencia
                  </span>
                ),
              },
              {
                key: 'phases',
                label: 'Fases',
                children: (
                  <CompetitionConfigPhases
                    config={data.config?.phases}
                    onChange={(x) => updateConfig({ phases: x })}
                  />
                ),
              },
              {
                key: 'points',
                label: 'Esquema de puntos',
                children: (
                  <CompetitionConfigPoints
                    config={data.config?.points}
                    phases={(data.config?.phases ?? []).filter(
                      (p) => !isEmpty(p.id) && !isEmpty(p.name)
                    )}
                    onChange={(x) => updateConfig({ points: x })}
                  />
                ),
              },
              {
                key: 'schema',
                label: 'Esquema de la competencia',
                children: (
                  <CompetitionConfigSchema
                    config={data.config?.schema}
                    onChange={(x) => updateConfig({ schema: x })}
                  />
                ),
              },
            ]}
          />
        </Item>
      </Form>
    </>
  );
};
