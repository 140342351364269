import gql from 'graphql-tag';

import * as contestFragments from '../fragments/contest';
import * as fixtureFragments from '../fragments/fixture';
import { contestsJoined } from '../fragments/user';

export const JOIN = gql`
  mutation joinContest($contestId: ID!, $predictions: JSON!, $coupon: String!) {
    joinContest(
      contestId: $contestId
      predictions: $predictions
      coupon: $coupon
    ) {
      id
      balance
      ...contestsJoined
    }
  }
  ${contestsJoined}
`;

export const UPDATE_PICKS = gql`
  mutation updateContestPicks($contestId: ID!, $predictions: JSON!) {
    updateContestPicks(contestId: $contestId, predictions: $predictions) {
      id
      ...contestsJoined
    }
  }
  ${contestsJoined}
`;

export const CREATE_PRIVATE = gql`
  mutation createPrivateContest($data: CreatePrivateContestInput!) {
    createPrivateContest(data: $data) {
      id
      balance
      ...contestsJoined
    }
  }
  ${contestsJoined}
`;

export const WRITE = gql`
  mutation writeContest(
    $data: WriteContestInput!
    $extra: WriteContestAdditionalInput!
  ) {
    writeContest(data: $data, extra: $extra) {
      ...contestAll
    }
  }
  ${contestFragments.all}
`;

export const DELETE = gql`
  mutation removeContest($id: ID!) {
    removeContest(id: $id) {
      ...contestAll
    }
  }
  ${contestFragments.all}
`;

export const INVITE = gql`
  mutation inviteToPrivateContest($contestId: ID!, $invitees: [ID!]!) {
    inviteToPrivateContest(contestId: $contestId, invitees: $invitees) {
      id
      pendingContestants {
        id
        username
        firstName
        lastName
        profileImageUrl
      }
    }
  }
`;

export const REMOVE_INVITE = gql`
  mutation removePrivateContestInvitation($contestId: ID!, $inviteeId: ID) {
    removePrivateContestInvitation(
      contestId: $contestId
      inviteeId: $inviteeId
    ) {
      id
      pendingContestants {
        id
        username
        firstName
        lastName
        profileImageUrl
      }
    }
  }
`;

export const CREATE_PUBLIC_CONTEST = gql`
  mutation createPublicContest($data: CreatePublicContestInput!) {
    createPublicContest(data: $data) {
      contestantCount
      currency
      entryAmount
      fixtures {
        id
        ...FixtureParticipant
        competition {
          id
          name
          region
        }
      }
      id
      maxContestants
      name
      prizeAmount
      sport
      status
      type
      winType
      type
      initialPot
    }
  }
  ${fixtureFragments.fixtureParticipant}
`;

export const EDIT_PUBLIC_CONTEST = gql`
  mutation editPublicContest($data: EditPublicContestInput!) {
    editPublicContest(data: $data) {
      contestantCount
      currency
      entryAmount
      fixtures {
        id
        ...FixtureParticipant
        competition {
          id
          name
          region
        }
      }
      id
      maxContestants
      name
      prizeAmount
      sport
      status
      type
      winType
      type
      initialPot
    }
  }
  ${fixtureFragments.allInfo}
`;
