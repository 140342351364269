import { equals, isEmpty, isNil } from 'ramda';
import { useState } from 'react';

type Rule<T> = {
  message: string;
  validate: (upd: T[keyof T]) => boolean;
};

export const useFormState = <T extends object>(
  initial: T,
  options: {
    onReset?: (state: T) => void;
    rules?: Partial<Record<keyof T, Rule<T>[]>>;
  } = {}
) => {
  const [original, setOriginal] = useState(initial);
  const [state, setState] = useState(initial);
  const [error, setError] = useState<{ [k in keyof T]?: string[] }>({});
  const changed = !equals(original, state);

  const update = (data: Partial<T>) => setState((p) => ({ ...p, ...data }));

  const validate = (data: Partial<T>) => {
    if (isNil(options.rules) || isEmpty(options.rules)) return;

    const unchecked = Object.entries(data) as unknown as [
      keyof T,
      T[keyof T],
    ][];
    const errs: typeof error = {};
    unchecked.forEach(([k, v]) => {
      const ruleset = options.rules![k];
      if (!ruleset) return;
      ruleset.forEach((rule) => {
        const passed = rule.validate(v);
        if (passed) return;
        if (!errs[k]) errs[k] = [];
        errs[k]!.push(rule.message);
      });
    });
    setError(errs);
  };

  const reset = (data?: T) => {
    if (data) {
      setOriginal({ ...data });
      setState({ ...data });
    } else setState({ ...original });

    if (options.onReset) options.onReset(data ?? original);
  };

  const difference = ({
    filterNull = false,
    filterUndefined = true,
    keep = [],
  }: {
    filterNull?: boolean;
    filterUndefined?: boolean;
    keep?: (keyof T)[];
  } = {}) => {
    const diff = {} as T;

    for (const key in state) {
      if (
        Object.prototype.hasOwnProperty.call(state, key) &&
        Object.prototype.hasOwnProperty.call(original, key) &&
        (keep.includes(key) ||
          (!equals(original[key], state[key]) &&
            (filterNull ? state[key] !== null : true) &&
            (filterUndefined ? state[key] !== undefined : true)))
      ) {
        const value = state[key];
        diff[key] = value;
      }
    }
    return diff;
  };

  const methods = {
    changed,
    original,
    error,
    update,
    validate,
    reset,
    difference,
  };

  return [state, methods] as [T, typeof methods];
};
