import { ClearOutlined } from '@ant-design/icons';
import { Row, Col, Input, Button, Space } from 'antd';
import useArray from '../../../../hooks/useArray';
import {
  CompetitionConfigGroupInput,
  WriteCompetitionInput,
} from '@cobra/common/dist/graphql/generated/graphql';
import { useEffect } from 'react';
import { equals } from 'ramda';
import { TeamSelector } from '../../../Selector/TeamSelector';

export const CompetitionConfigGroups = ({
  context,
  config,
  onChange,
}: {
  context: WriteCompetitionInput;
  config: CompetitionConfigGroupInput[] | null | undefined;
  onChange: (groups: CompetitionConfigGroupInput[]) => void;
}) => {
  const groups = useArray(config ?? []);

  useEffect(() => {
    if (equals(groups.array, config)) return;
    onChange(groups.array);
  }, [JSON.stringify(groups.array)]);

  console.log({ config, groups: groups.array });

  return (
    <>
      <Space direction="vertical" style={{ width: '100%' }}>
        {config?.map((group, i) => (
          <Row gutter={16} style={{ maxWidth: '100%' }}>
            <Col span={2}>
              <Input
                type="number"
                prefix="ID"
                placeholder="id"
                value={group.id}
                onChange={(x) =>
                  groups.update(i, { ...group, id: x.target.value })
                }
              />
            </Col>
            <Col span={3}>
              <Input
                prefix="Nombre"
                placeholder="Nombre"
                value={group.name}
                onChange={(x) =>
                  groups.update(i, {
                    ...group,
                    name: x.target.value,
                  })
                }
              />
            </Col>
            <Col span={18}>
              <TeamSelector
                value={group.members}
                onChange={(x) => groups.update(i, { ...group, members: x })}
                extra={{ id: context.id! }}
              />
            </Col>
            <Col span={1}>
              <Button
                onClick={() => groups.remove(i)}
                icon={<ClearOutlined />}
              />
            </Col>
          </Row>
        ))}
        <Button onClick={() => groups.push({ id: '', name: '', members: [] })}>
          Añadir nuevo grupo
        </Button>
      </Space>
    </>
  );
};
