import gql from 'graphql-tag';

export const TeamAllInfo = gql`
  fragment TeamAllInfo on Team {
    id
    countryCode
    image
    name
    placeholder
    sport
    type
  }
`;
