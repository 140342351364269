import React, { FC, useEffect, useState } from 'react';
import { Input, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import useDebounce from '../../../hooks/useDebounce';
import { FilterCompetitionInput } from '@cobra/common/dist/graphql/generated/graphql';
import { SportSelector } from '../../Selector/SportSelector';
import { RegionSelector } from '../../Selector/RegionSelector';
import { ActiveSelector } from '../../Selector/ActiveSelector';

const initialFilterData: FilterCompetitionInput = {
  name: undefined,
  active: undefined,
  region: undefined,
  sport: undefined,
  type: undefined,
};

export const CompetitionsFilters: FC<{
  onChange: (filterData: FilterCompetitionInput) => void;
  openModal: () => void;
}> = ({ onChange, openModal }) => {
  const [filterData, setFilterData] = useState(initialFilterData);
  const filters = useDebounce(filterData);

  const updateFilterData = (data: FilterCompetitionInput) => {
    setFilterData((p) => ({ ...p, ...data }));
  };

  useEffect(() => {
    console.log({ filters, onChange });

    onChange(filters);
  }, [filters]);

  return (
    <>
      <Input
        allowClear
        placeholder="Nombre..."
        value={filterData.name ?? ''}
        onChange={(x) => updateFilterData({ name: x.target.value })}
      />
      <RegionSelector
        isFilter
        region={filterData.region}
        onChange={(x: string) => updateFilterData({ region: x })}
      />
      <SportSelector
        sport={filterData.sport}
        onChange={(x) => updateFilterData({ sport: x })}
        isFilter
      />
      <ActiveSelector
        active={filterData.active}
        onChange={(x) => updateFilterData({ active: x })}
      />

      <Button type="primary" onClick={openModal} icon={<PlusOutlined />}>
        Create competition
      </Button>
    </>
  );
};
