import { BetType } from '../../graphql/generated/graphql';

export const getTakerAmount = ({
  amount,
  pick,
  type,
  odds,
}: {
  amount: number;
  pick: 'HOME' | 'AWAY';
  type: BetType;
  odds: { homeTeamOdds: number; awayTeamOdds: number };
}): number => {
  const { homeTeamOdds, awayTeamOdds } = odds;
  let bettorOdds = pick === 'HOME' ? homeTeamOdds : awayTeamOdds;
  const tieOdds = 100 - homeTeamOdds - awayTeamOdds;

  if (type === BetType.TIE_FOR_BETTOR) bettorOdds += tieOdds;

  const totalOdds =
    type === BetType.TIE_BREAKS_EVEN ? homeTeamOdds + awayTeamOdds : 100;

  return Math.ceil(amount / (bettorOdds / totalOdds) - amount);
};
