import React, { useMemo, useState } from 'react';
import { To, useLocation, useNavigate } from 'react-router-dom';
import { Menu } from 'antd';
import {
  LogoutOutlined,
  SettingOutlined,
  TrophyOutlined,
  TeamOutlined,
  LeftCircleFilled,
  MenuOutlined,
} from '@ant-design/icons';
import { useApolloClient, useQuery } from '@apollo/client';
import { AuthenticatedUserQuery } from '@cobra/common/dist/graphql/generated/graphql';
import { AUTHENTICATED_USER } from '@cobra/common/dist/graphql/queries';
import { Role } from '@cobra/common/dist/utils/constants';
import { MENU_KEYS } from '../../contants/menukeys';
import { useAppContext } from '../../context/app';
import { logOut } from '../../utils/helpers';
import { MenuItemType, ItemType } from 'antd/es/menu/hooks/useItems';

const SideMenu = () => {
  const client = useApolloClient();
  const { menuState, set } = useAppContext();
  const nav = useNavigate();
  const { pathname } = useLocation();
  const [openKey, setOpenKey] = useState<string[]>([]);
  const { data } = useQuery<AuthenticatedUserQuery>(AUTHENTICATED_USER);
  const menuItems = useMemo(
    () => buildMenuItems(data?.authenticatedUser?.role),
    [data?.authenticatedUser?.role]
  );

  const handleClick = (e: { key: To }) => {
    if (e.key === pathname || !e.key) return;
    switch (e.key) {
      case MENU_KEYS.LOG_OUT:
        logOut(client);
        break;

      default:
        nav(e.key);
        break;
    }
  };

  // Keys only go two levels down. /dashboard/admins/create would be
  // /dashboard/admins. This is so the corresponding menu tab is still active
  // even when going to nested routes.
  const currentMenuKey = pathname.split('/').slice(0, 3).join('/');

  return (
    <>
      <Menu
        className="menu-container"
        theme="light"
        onClick={handleClick}
        selectedKeys={[currentMenuKey]}
        mode="inline"
        inlineCollapsed={menuState}
        style={{
          position: 'fixed',
          height: '100%',
          top: 0,
          bottom: 0,
          maxWidth: 300,
          minWidth: 80,
        }}
        onOpenChange={(keys) => setOpenKey([keys.reverse()[0]])}
        openKeys={openKey}
        items={menuItems}
      ></Menu>
      <LeftCircleFilled
        className="toggle"
        onClick={() => {
          if (menuState) setOpenKey([]);
          set({ menuState: !menuState });
        }}
        style={{
          fontSize: '1.25rem',
          transition: 'all 150ms',
          margin: '0',
          position: 'fixed',
          top: '1rem',
          // left: menuState ? 80 : 300,
          zIndex: '10',
          transform: menuState
            ? 'translate(calc(80px - 50%), 0) rotate(180deg)'
            : 'translate(calc(300px - 50%), 0) rotate(0)',
          transformOrigin: 'center',
        }}
      />
    </>
  );
};

export default SideMenu;

const buildMenuItems = (role: string | null | undefined) => {
  const isSuperAdmin = role === Role.SuperAdmin;

  const superAdminCollapse: ItemType<MenuItemType>[] = [
    { key: MENU_KEYS.MANAGE_ADMINS, label: 'Administradores' },
  ];

  const primaryCollapse: ItemType<MenuItemType>[] = [
    { key: MENU_KEYS.FIXTURES, label: 'Partidos' },
    { key: MENU_KEYS.CONTESTS, label: 'Quinielas' },
    { key: MENU_KEYS.COMPETITIONS, label: 'Competencias' },
    { key: MENU_KEYS.TEAMS, label: 'Equipos' },
    { key: MENU_KEYS.SPONSORS, label: 'Patrocinantes' },
  ];

  const settingsCollapse: ItemType<MenuItemType>[] = [
    { key: MENU_KEYS.OPTIONS, label: 'Opciones' },
    { key: MENU_KEYS.SPORTS, label: 'Deportes' },
  ];

  const menuItems: ItemType<MenuItemType>[] = [
    {
      key: MENU_KEYS.DASHBOARD,
      icon: <MenuOutlined />,
      label: 'Cobra Admin',
      title: 'Cobra Admin',
      theme: 'dark',
    },
    !isSuperAdmin
      ? null
      : {
          key: 'admin',
          icon: <TeamOutlined />,
          label: 'Personas',
          children: superAdminCollapse,
        },
    {
      key: 'primary',
      icon: <TrophyOutlined />,
      label: 'Quinielas',
      children: primaryCollapse,
    },
    {
      key: 'config',
      icon: <SettingOutlined />,
      label: 'Configuraciones',
      children: settingsCollapse,
    },
    {
      style: { position: 'absolute', bottom: 0 },
      danger: true,
      key: MENU_KEYS.LOG_OUT,
      icon: <LogoutOutlined />,
      label: 'Cerrar sesión',
    },
  ];

  return menuItems;
};
