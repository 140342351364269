import { SponsorsFilterInput } from '@cobra/common/dist/graphql/generated/graphql';
import { Button, Input } from 'antd';
import { FC, useEffect, useState } from 'react';
import useDebounce from '../../../hooks/useDebounce';
import { ActiveSelector } from '../../Selector/ActiveSelector';

const initialFilterData: SponsorsFilterInput = {
  name: undefined,
  active: undefined,
};

export const SponsorsFilters: FC<{
  onChange: (filterData: SponsorsFilterInput) => void;
  openModal: () => void;
}> = ({ onChange, openModal }) => {
  const [filterData, setFilterData] = useState(initialFilterData);
  const filters = useDebounce(filterData);

  const updateFilterData = (data: Partial<SponsorsFilterInput>) => {
    setFilterData((p) => ({ ...p, ...data }));
  };

  useEffect(() => {
    onChange(filters);
  }, [filters, onChange]);

  return (
    <>
      <Input
        allowClear
        placeholder="Nombre..."
        value={filterData.name ?? undefined}
        onChange={(x) => updateFilterData({ name: x.target.value })}
      />
      <ActiveSelector
        active={filterData.active}
        onChange={(active) => updateFilterData({ active })}
      />
      <Button type="primary" onClick={openModal}>
        Nuevo patrocinante
      </Button>
    </>
  );
};
