import gql from 'graphql-tag';
import * as userFragments from './user';
import * as betFragments from './bet';
import * as fixtureFragments from './fixture';
import * as contestFragments from './contest';
import * as transactionFragments from './transaction';

export const paymentMethods = gql`
  fragment userPaymentMethods on User {
    paymentMethods {
      accountNumberLastFour
      bankCode
      isDefault
    }
  }
`;

export const bettorBets = gql`
  fragment userBettorBets on User {
    bettorBets(offset: $betsOffset, limit: $betsLimit, active: $active) {
      ...BetMainInfo
    }
  }
  ${betFragments.BetMainInfo}
`;

export const takerBets = gql`
  fragment userTakerBets on User {
    takerBets(offset: $betsOffset, limit: $betsLimit, active: $active) {
      ...BetMainInfo
    }
  }
  ${betFragments.BetMainInfo}
`;

export const friendRequests = gql`
  fragment userFriendRequests on User {
    id
    friendRequestsSent {
      ...userAllNames
      id
      profileImageUrl
    }
    friendRequestsReceived {
      ...userAllNames
      id
      profileImageUrl
    }
  }
  ${userFragments.allNames}
`;

export const transactions = gql`
  fragment userTransactions on User {
    transactions(offset: $transactionsOffset, limit: $transactionsLimit) {
      ...TransactionMainInfo
    }
  }
  ${transactionFragments.TransactionMainInfo}
`;

export const payouts = gql`
  fragment userPayouts on User {
    payouts(
      after: $payoutsAfter
      first: $payoutsFirst
      createdAt: $payoutsCreatedAt
    ) {
      edges {
        cursor
        node {
          amount
          bet {
            ...BetMainInfo
          }
          completed
          contest {
            ...contestAll
          }
          createdAt
          id
          type
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        startCursor
      }
      totalCount
    }
  }
  ${betFragments.BetMainInfo}
  ${contestFragments.all}
`;

export const depositFields = gql`
  fragment userDepositFields on User {
    ...userFullName
    ...userDocumentId
  }
  ${userFragments.fullName}
  ${userFragments.documentId}
`;

export const withdrawalFields = gql`
  fragment userWithdrawalFields on User {
    ...userFullName
    ...userDocumentId
    ...userPaymentMethods
    balance
    username
  }
  ${userFragments.fullName}
  ${userFragments.documentId}
  ${paymentMethods}
`;

export const balanceFeed = gql`
  fragment balanceFeed on User {
    balanceFeed {
      __typename
      ... on Bet {
        amount
        balanceBettor
        balanceTaker
        createdAt
        id
        odds {
          awayTeamOdds
          homeTeamOdds
        }
        pick
        type
        ...BettorMainInfo
        ...TakerMainInfo
      }
      ... on ContestFeedItem {
        balance
        createdAt
        feedContest: contest {
          createdAt
          entryAmount
          id
          name
        }
      }
      ... on Payout {
        amount
        balance
        bet {
          id
          amount
          ...BettorMainInfo
          ...TakerMainInfo
        }
        contest {
          id
          name
        }
        createdAt
        id
        payoutType: type
      }
      ... on Transaction {
        ...TransactionMainInfo
      }
    }
  }
  ${betFragments.BettorMainInfo}
  ${betFragments.TakerMainInfo}
  ${transactionFragments.TransactionMainInfo}
`;

export const socialFeed = gql`
  fragment socialFeed on User {
    socialFeed {
      __typename
      ... on AcceptedPrivateInvite {
        createdAt
        user {
          id
          username
          profileImageUrl
        }
        createdPrivateContest: contest {
          createdAt
          id
          name
        }
      }
      ... on Bet {
        amount
        createdAt
        fixture {
          id
          ...FixtureParticipant
        }
        id
        odds {
          awayTeamOdds
          homeTeamOdds
        }
        pick
        status
        type
        ...BettorMainInfo
        ...TakerMainInfo
      }
      ... on ContestFeedItem {
        createdAt
        joinedPrivateContest: contest {
          id
          creator {
            id
            username
            profileImageUrl
          }
          name
        }
      }
      ... on Payout {
        amount
        bet {
          amount
          fixture {
            id
            ...FixtureParticipant
          }
          id
          type
          ...BettorMainInfo
          ...TakerMainInfo
        }
        contest {
          id
          name
        }
        createdAt
        id
        payoutType: type
      }
    }
  }
  ${fixtureFragments.fixtureParticipant}
  ${betFragments.BettorMainInfo}
  ${betFragments.TakerMainInfo}
`;
