import { WriteCompetitionInput } from '@cobra/common/dist/graphql/generated/graphql';
import { Col, Form, Input, Row, Select, Switch } from 'antd';
import React, { FC } from 'react';
import { CompetitionTypes } from '@cobra/common/dist/utils/constants';
import { RegionSelector } from '../../Selector/RegionSelector';
import { SportSelector } from '../../Selector/SportSelector';

const { Item } = Form;

export const CompetitionForm: FC<{
  data: WriteCompetitionInput;
  onChange: (data: Partial<WriteCompetitionInput>) => void;
}> = ({ data, onChange }) => {
  return (
    <Form layout="vertical">
      <Row gutter={16}>
        <Col span={12}>
          <Item label="Nombre">
            <Input
              placeholder="Nombre"
              value={data.name ?? ''}
              onChange={(x) => onChange({ name: x.target.value })}
            />
          </Item>
        </Col>
        <Col span={12}>
          <Item label="Logo url">
            <Input
              placeholder="https://..."
              value={data.logoUrl ?? ''}
              onChange={(x) => onChange({ logoUrl: x.target.value })}
            />
          </Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <Item label="Región">
            <RegionSelector
              region={data.region}
              onChange={(x) => onChange({ region: x })}
            />
          </Item>
        </Col>
        <Col span={6}>
          <Item label="Deporte">
            <SportSelector
              sport={data.sport}
              onChange={(x) => onChange({ sport: x })}
            />
          </Item>
        </Col>
        <Col span={6}>
          <Item label="Tipo de competencia">
            <Select
              placeholder="Seleccione el tipo de competencia"
              value={
                (data.type?.length ? data.type : undefined) as CompetitionTypes
              }
              onChange={(x: CompetitionTypes) => onChange({ type: x })}
            >
              {Object.entries(CompetitionTypes).map(([k, v]) => (
                <Select.Option key={k} value={k}>
                  {v}
                </Select.Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col span={6}>
          <Item label="Activo">
            <Switch
              checked={data.active ?? false}
              onChange={() => onChange({ active: !data.active })}
            />
          </Item>
        </Col>
      </Row>
      {/* <Row gutter={16}>
        <Col span={12}>
          <Item label="Fases">
            <Input
              type="number"
              placeholder="0"
              value={data.phases ?? ''}
              onChange={(x) => onChange({ phases: x.target.value })}
            />
          </Item>
        </Col>
        <Col span={12}>
          <Item label="Grupos">
            <Input
              type="number"
              placeholder="0"
              value={data.groups ?? ''}
              onChange={(x) => onChange({ groups: x.target.value })}
            />
          </Item>
        </Col>
      </Row> */}
    </Form>
  );
};
