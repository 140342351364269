import gql from 'graphql-tag';
import { SponsorData } from '../fragments/sponsor';

export const WRITE = gql`
  mutation writeSponsor($data: SponsorInput!) {
    writeSponsor(data: $data) {
      ...SponsorData
    }
  }
  ${SponsorData}
`;

export const REMOVE = gql`
  mutation removeSponsor($id: ID!) {
    removeSponsor(id: $id) {
      ...SponsorData
    }
  }
  ${SponsorData}
`;
