import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { css } from '@emotion/css';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import gql from 'graphql-tag';
import { MENU_KEYS } from '../../contants/menukeys';
import CreateAdmin from '../CreateAdmin/CreateAdmin';
import Dashboard from '../Dashboard/Dashboard';
import Login from '../Login/Login';
import ManageAdmins from '../ManageAdmins/ManageAdmins';
import NotFoundPage from '../NotFoundPage/NotFoundPage';
import ProtectedRoute from '../ProtectedRoute/ProtectedRoute';
import SideMenu from '../SideMenu/SideMenu';
import { Options } from '../Options';
import { SportsView } from '../Views/Sport';
import { CompetitionsView } from '../Views/Competition';
import { TeamsView } from '../Views/Team';
import { ContestView } from '../Views/Contest';
import { FixturesView } from '../Views/Fixture';
import './App.css';
import { useAppContext } from '../../context/app';
import { SponsorsView } from '../Views/Sponsor';

dayjs.locale('es');

const appContainer = css`
  min-height: 100vh;

  .main-content {
    &[data-wide='true'] {
      --drawer: 80px;
    }
    &[data-wide='false'] {
      --drawer: 300px;
    }
    &[data-logged='false'] {
      --drawer: 0px;
    }

    min-height: inherit;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition-property: margin, width;
    transition-duration: 150ms;
    padding: 1.5rem 0;
    margin: 0 1.5rem;
    margin-left: calc(1.5rem + var(--drawer));
    width: calc(100% - 3rem - var(--drawer));
  }
`;

const initialRoute = (isLoggedIn: boolean) => {
  return isLoggedIn ? <Navigate to="/dashboard" /> : <Login />;
};

const IS_LOGGED_IN = gql`
  query isLoggedIn {
    isLoggedIn @client
  }
`;

const App: React.FC = () => {
  const { data } = useQuery(IS_LOGGED_IN);
  const { menuState } = useAppContext();
  const { isLoggedIn } = data || { isLoggedIn: false };

  return (
    <div className={appContainer}>
      {isLoggedIn && <SideMenu />}
      <div
        className="main-content"
        data-logged={isLoggedIn}
        data-wide={menuState}
      >
        <Routes>
          <Route path="/" element={initialRoute(isLoggedIn)} />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute Component={Dashboard} isLoggedIn={isLoggedIn} />
            }
          />
          <Route
            path="/dashboard/admins"
            element={
              <ProtectedRoute
                Component={ManageAdmins}
                isLoggedIn={isLoggedIn}
                requiresSuperAdmin
              />
            }
          />
          <Route
            path="/dashboard/admins/create"
            element={
              <ProtectedRoute
                Component={CreateAdmin}
                isLoggedIn={isLoggedIn}
                requiresSuperAdmin
              />
            }
          />
          <Route
            path={MENU_KEYS.TEAMS}
            element={
              <ProtectedRoute Component={TeamsView} isLoggedIn={isLoggedIn} />
            }
          />
          <Route
            path={MENU_KEYS.FIXTURES}
            element={
              <ProtectedRoute
                Component={FixturesView}
                isLoggedIn={isLoggedIn}
              />
            }
          />
          <Route
            path={MENU_KEYS.COMPETITIONS}
            element={
              <ProtectedRoute
                Component={CompetitionsView}
                isLoggedIn={isLoggedIn}
              />
            }
          />
          <Route
            path={MENU_KEYS.SPONSORS}
            element={
              <ProtectedRoute
                Component={SponsorsView}
                isLoggedIn={isLoggedIn}
              />
            }
          />
          <Route
            path={MENU_KEYS.CONTESTS}
            element={
              <ProtectedRoute Component={ContestView} isLoggedIn={isLoggedIn} />
            }
          />
          <Route
            path="/dashboard/options"
            element={
              <ProtectedRoute Component={Options} isLoggedIn={isLoggedIn} />
            }
          />
          <Route
            path={MENU_KEYS.SPORTS}
            element={
              <ProtectedRoute Component={SportsView} isLoggedIn={isLoggedIn} />
            }
          />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
    </div>
  );
};

export default App;
