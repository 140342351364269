import { useLazyQuery } from '@apollo/client';
import {
  Fixture,
  FixtureParticipant,
  FixturesQuery,
  FixturesQueryVariables,
} from '@cobra/common/dist/graphql/generated/graphql';
import { FixtureQueries } from '@cobra/common/dist/graphql/queries';
import { Col, DatePicker, Row, Table } from 'antd';
import { Key } from 'antd/lib/table/interface';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { equals } from 'ramda';
import { FunctionComponent, useEffect, useState } from 'react';
import { CompetitionSelector } from '../Selector/CompetitionSelector';

dayjs.extend(utc);
const formatStart = (date: Dayjs) => date.startOf('week');
const formatEnd = (date: Dayjs) => date.add(1, 'week').endOf('week');

type FixturesTable = FunctionComponent<{
  onChange?: (p: string[]) => void;
  selectedFixtures: string[];
  dates?: Dayjs[];
  sport: string;
}>;

const { RangePicker } = DatePicker;

const FixturesTable: FixturesTable = ({
  onChange,
  selectedFixtures,
  dates,
  sport,
}) => {
  const [fixtures, setFixtures] = useState<Fixture[]>([]);
  const [selected, setSelected] = useState<string[]>([]);
  const [selectedCompetition, setSelectedCompetition] = useState<
    string | undefined
  >();
  const [date, setDate] = useState({
    start: formatStart(dayjs()),
    end: formatEnd(dayjs()),
  });

  const [fetchFixtures, { loading }] = useLazyQuery<
    FixturesQuery,
    FixturesQueryVariables
  >(FixtureQueries.FIXTURES, {
    onCompleted: ({ fixtures }) => setFixtures(fixtures as Fixture[]),
    fetchPolicy: 'no-cache',
  });

  const rowSelect = (selectedRowKeys: Key[]) => {
    setSelected(selectedRowKeys as string[]);
  };

  const setDates = (dates: Dayjs[]) => {
    if (dates) {
      const [start, end] = dates;
      setDate({ start: start.startOf('day'), end: end.endOf('day') });
    }
  };

  useEffect(() => {
    if (dates?.length) setDates(dates);
  }, [dates]);

  useEffect(() => {
    if (equals(selectedFixtures, selected)) return;
    setSelected(selectedFixtures);
  }, [selectedFixtures]);

  useEffect(() => {
    fetchFixtures({
      variables: {
        limit: 100,
        offset: 0,
        filter: { ...date, sport, competitionId: selectedCompetition },
      },
    });
  }, [date.end, date.start, selectedCompetition]);

  useEffect(() => {
    if (onChange) onChange(selected);
  }, [selected]);

  useEffect(() => {
    return () => onChange?.([]);
  }, [selected]);

  return (
    <>
      <Row gutter={16}>
        <Col span={8}>
          <CompetitionSelector
            competition={selectedCompetition}
            onChange={setSelectedCompetition}
            sport={sport}
          />
        </Col>
        <Col span={16}>
          <RangePicker
            style={{ width: '100%' }}
            value={[date.start, date.end]}
            onChange={(d) => setDates(d as Dayjs[])}
          />
        </Col>
      </Row>

      <Table
        style={{ marginBottom: '1rem', marginTop: '1rem' }}
        rowKey="id"
        loading={loading}
        rowSelection={{
          selectedRowKeys: selected,
          onChange: rowSelect,
        }}
        columns={columns}
        dataSource={fixtures}
        pagination={false}
      />
    </>
  );
};

export { FixturesTable };

const columns = [
  {
    title: 'Partido',
    key: 'id',
    render: (fixture: Fixture) => (
      <span>{buildFixtureName(fixture.participants)}</span>
    ),
  },
  {
    title: 'Fecha',
    key: 'startDate',
    render: (fixture: Fixture) => (
      <span>{dayjs(fixture.startDate).utcOffset(0).format('D/MM/YY')}</span>
    ),
  },
  {
    title: 'Competition',
    key: 'competition',
    render: (fixture: Fixture) => <span>{fixture.competition?.name}</span>,
  },
];

const buildFixtureName = (participants: FixtureParticipant[]) => {
  const separator = participants.length > 1 ? ' vs ' : ', ';
  return participants.map((p) => p.name!).join(separator);
};
