import gql from 'graphql-tag';

import * as fixtureFragments from '../fragments/fixture';

export const CREATE_FIXTURE = gql`
  mutation createFixture($data: CreateFixtureInput!) {
    createFixture(data: $data) {
      ...allInfo
    }
  }
  ${fixtureFragments.allInfo}
`;

export const UPDATE_FIXTURE = gql`
  mutation updateFixture($id: ID!, $data: FixtureInput!) {
    updateFixture(id: $id, data: $data) {
      ...allInfo
    }
  }
  ${fixtureFragments.allInfo}
`;

export const DELETE = gql`
  mutation deleteFixture($id: ID!) {
    deleteFixture(id: $id) {
      ...allInfo
    }
  }
  ${fixtureFragments.allInfo}
`;
