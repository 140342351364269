import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useQuery,
  useApolloClient,
  useMutation,
  ApolloError,
} from '@apollo/client';
import { Card, Empty, Table, Divider, Button, Popconfirm } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import * as R from 'ramda';

import { css } from '@emotion/css';
import { Helpers } from '@cobra/common';
import { Role } from '@cobra/common/dist/utils/constants';
import {
  UpdateAdminRoleMutation as updateAdminRole,
  UpdateAdminRoleMutationVariables as updateAdminRoleVariables,
  AuthenticatedUserQuery as authenticatedUser,
  AdminsQuery as admins,
  AdminsQueryVariables as adminsVariables,
} from '@cobra/common/dist/graphql/generated/graphql';
import { UPDATE_ADMIN_ROLE } from '@cobra/common/dist/graphql/mutations';

import { AUTHENTICATED_USER, ADMINS } from '@cobra/common/dist/graphql/queries';
import { leftAlignedButtonStyle } from '../../styles';
import GenericError from '../GenericError/GenericError';

const errorContainerStyle = css({ marginTop: 20 });

interface ColumnItem {
  key: string;
  username: string;
  email: string;
  role: string;
}

type ApolloOnError = (err: ApolloError) => void;

interface UpdateAdminRoleProps {
  variables: {
    id: string;
    role: string;
  };
  onError?: ApolloOnError;
  children: (props: {
    updateAdminRoleMutation: Function;
    loading: boolean;
    error?: ApolloError;
    data?: updateAdminRole;
  }) => JSX.Element;
}

const UpdateAdminRole: React.FC<UpdateAdminRoleProps> = (props) => {
  const { variables, onError, children } = props;
  const [updateAdminRoleMutation, { data, loading, error }] = useMutation<
    updateAdminRole,
    updateAdminRoleVariables
  >(UPDATE_ADMIN_ROLE, {
    variables: { id: variables.id, role: variables.role },
    onError,
  });

  return children({
    updateAdminRoleMutation,
    loading,
    error,
    data: data!,
  });
};

const RemoveAdminAccess = ({ id }: { id: string }) => (
  <UpdateAdminRole variables={{ id, role: Role.Regular }}>
    {(props) => (
      <Popconfirm
        okText="Si"
        cancelText="No"
        title="Segur@?"
        onConfirm={() => props.updateAdminRoleMutation()}
      >
        <Button>Remover acceso</Button>
      </Popconfirm>
    )}
  </UpdateAdminRole>
);

const MakeAdmin = ({ id, onError }: { id: string; onError: ApolloOnError }) => (
  <UpdateAdminRole variables={{ id, role: Role.Admin }} onError={onError}>
    {(props) => (
      <Popconfirm
        okText="Si"
        cancelText="No"
        title="Segur@?"
        onConfirm={() => props.updateAdminRoleMutation()}
      >
        <Button>Degradar a Admin</Button>
      </Popconfirm>
    )}
  </UpdateAdminRole>
);

const MakeSuperAdmin = ({ id }: { id: string }) => (
  <UpdateAdminRole variables={{ id, role: Role.SuperAdmin }}>
    {(props) => (
      <Popconfirm
        okText="Si"
        cancelText="No"
        title="Segur@?"
        onConfirm={() => props.updateAdminRoleMutation()}
      >
        <Button>Promover a Super Admin</Button>
      </Popconfirm>
    )}
  </UpdateAdminRole>
);

const renderTableItem = (item: string) => <span>{item}</span>;

const renderLoadingCard = ({ loading }: { loading: boolean }) => (
  <Card style={{ width: '100%' }} loading={loading} />
);

const renderCreateAdminButton = ({
  isSuperAdmin,
  nav,
}: {
  isSuperAdmin: boolean;
  nav: any;
}) =>
  isSuperAdmin && (
    <Button
      type="primary"
      className={leftAlignedButtonStyle}
      // eslint-disable-next-line react/destructuring-assignment
      onClick={() => nav('/dashboard/admins/create')}
    >
      Crear nuevo administrador
    </Button>
  );

const renderTable = (
  columns: ColumnProps<ColumnItem>[],
  dataSource: ColumnItem[]
) => {
  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      bordered
    />
  );
};

const ManageAdmins: React.FC = () => {
  const client = useApolloClient();
  const nav = useNavigate();
  const [dataSource, setDataSource] = useState<ColumnItem[]>([]);
  const [error, setError] = useState<ApolloError | null>(null);
  const authedUser = useRef<authenticatedUser | null>(null);
  const columns = useRef<ColumnProps<ColumnItem>[]>([
    {
      title: 'Usuario',
      dataIndex: 'username',
      render: renderTableItem,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      render: renderTableItem,
    },
    {
      title: 'Acceso',
      dataIndex: 'role',
      render: renderTableItem,
    },
  ]);
  const isSuperAdmin = useRef(false);

  useEffect(() => {
    authedUser.current = client.readQuery<authenticatedUser>({
      query: AUTHENTICATED_USER,
    });

    const renderAction = (text: null, record: ColumnItem) => (
      <div>
        {record.role === Helpers.snakeToLower(Role.Admin) && (
          <div>
            <MakeSuperAdmin id={record.key} />
            <Divider type="vertical" />
            <RemoveAdminAccess id={record.key} />
          </div>
        )}

        {record.role === Helpers.snakeToLower(Role.SuperAdmin) && (
          <div>
            <MakeAdmin id={record.key} onError={(err) => setError(err)} />
            <Divider type="vertical" />
            <RemoveAdminAccess id={record.key} />
          </div>
        )}
      </div>
    );

    isSuperAdmin.current =
      authedUser?.current?.authenticatedUser.role === Role.SuperAdmin;

    if (isSuperAdmin.current) {
      columns.current.push({
        title: 'Acción',
        dataIndex: 'action',
        render: renderAction,
      });
    }
  }, [client]);

  const {
    data: usersData,
    loading: usersLoading,
    error: usersError,
  } = useQuery<admins, adminsVariables>(ADMINS, {
    variables: { offset: 0, limit: 100 },
    fetchPolicy: 'cache-and-network',
    partialRefetch: true,
  });

  useEffect(() => {
    const source = usersData?.admins.reduce((acc: ColumnItem[], user) => {
      if (
        user.id === authedUser.current?.authenticatedUser.id ||
        user.role === Role.Regular
      ) {
        return acc;
      }

      return [
        ...acc,
        {
          key: user.id,
          username: user.username,
          email: user.email,
          role: Helpers.snakeToLower(user.role || ''),
        },
      ];
    }, []);

    if (source) setDataSource(source);
  }, [usersData]);

  if (!usersData) return <Empty />;

  if (R.isEmpty(usersData)) return <Empty />;

  if (usersError) return <Empty />;

  if (usersLoading) return renderLoadingCard({ loading: usersLoading });

  return (
    <>
      <GenericError
        error={error}
        flexLayout
        alignSelf="center"
        className={errorContainerStyle}
      />
      {renderCreateAdminButton({ isSuperAdmin: isSuperAdmin.current, nav })}
      {renderTable(columns.current, dataSource)}
    </>
  );
};

export default ManageAdmins;
