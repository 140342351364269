export type Percentages = Readonly<number[]>;

/** This percentages are so $3500 would be divided into:
 * - 1st: $2000 -> 2000 / 3500 = 0.57142857143
 * - 2nd: $1000 -> 1000 / 3500 = 0.28571428572
 * - 3rd: $500  ->  500 / 3500 = 0.14285714285
 * */

export const TOP_3_DISTRIBUTION: Percentages = [
  57.142857143, 28.571428572, 14.285714285,
];

export const TOP_5_DISTRIBUTION: Percentages = [35, 20, 15, 10, 5];

export const TOP_10_DISTRIBUTION: Percentages = [
  20, 15, 11, 9, 8, 7, 6, 4, 3, 2,
];

const SECOND_BLOCK_PERCENTAGE = 10;

const THIRD_BLOCK_PERCENTAGE = 5;

const getPrizeFromPercentage = (totalPrize: string, percentage: number) =>
  Math.floor(
    Number.isNaN(Number(totalPrize))
      ? 0
      : Number(totalPrize) * (percentage / 100)
  );

const getTopWinnerDistribution = (
  distribution: Percentages,
  prizeAmount: string
) =>
  distribution.reduce(
    (acc, percentage, index) => {
      acc[index + 1] = getPrizeFromPercentage(prizeAmount, percentage);
      return acc;
    },
    {} as { [key: string]: number }
  );

const getTopWinnerCount = (distribution: Percentages) => distribution.length;

const getBlockContestantCount = (
  minWinnerCount: number,
  topWinnerCount: number
) => Math.ceil((minWinnerCount - topWinnerCount) / 2);

const getBlockDistribution = (
  distribution: Percentages,
  prizeAmount: string,
  minWinnerCount: number
) => {
  const result = getTopWinnerDistribution(distribution, prizeAmount);
  const topWinnerCount = getTopWinnerCount(distribution);
  const blockContestantCount = getBlockContestantCount(
    minWinnerCount,
    topWinnerCount
  );
  const secondBlockEnd = topWinnerCount + blockContestantCount;

  result[`${topWinnerCount + 1}-${secondBlockEnd - 1}`] = Math.floor(
    getPrizeFromPercentage(prizeAmount, SECOND_BLOCK_PERCENTAGE) /
      blockContestantCount
  );
  result[`${secondBlockEnd}-${minWinnerCount}`] = Math.floor(
    getPrizeFromPercentage(prizeAmount, THIRD_BLOCK_PERCENTAGE) /
      blockContestantCount
  );

  return result;
};

/**
 * The following functions are used to get the distribution that is displayed
 * to users so they know what the prize structure of a contest looks like before
 * joining.
 */

/**
 * For private contests we only have top 1 and top 3.
 * @param prizeAmount the total prize amount for the contest (the entry amount
 * times the number of contestants)
 * @param minWinnerCount the minimum number of winners. Determines if it's a
 * top 1 or top 3 distribution
 */
export const getPrivateContestDistribution = (
  prizeAmount: string,
  minWinnerCount: number
) => {
  if (minWinnerCount === 1) {
    return {
      1: prizeAmount,
    };
  }

  return getTopWinnerDistribution(TOP_3_DISTRIBUTION, prizeAmount);
};

/**
 * For public contest there are more distribution rules:
 * 1. contestantCount <= 5: top 1
 * 2. contestantCount <= 15: top 3
 * 3. contestantCount <= 30: top 5 with blocks
 * 4. contestantCount > 30: top 10 with blocks
 *
 * Blocks: there are three total blocks. The first block includes the top
 * winners. Then, the last two blocks each have 50% of the number
 * of contestants remaining after subtracting the number of top winners. So,
 * both blocks add up to 100% of non-top winners.
 *
 * 2nd block: 10% of the total prize divided equally between all the contestants
 * in the block.
 * 3rd block: 5% of the total prize divided equally between all the contestants
 * of the block.
 *
 */
export const getPublicContestDistribution = (
  prizeAmount: string,
  minWinnerCount: number
) => {
  if (minWinnerCount < 3) return { 1: prizeAmount };

  if (minWinnerCount <= 15) {
    return getTopWinnerDistribution(TOP_3_DISTRIBUTION, prizeAmount);
  }

  if (minWinnerCount <= 30) {
    return getBlockDistribution(
      TOP_5_DISTRIBUTION,
      prizeAmount,
      minWinnerCount
    );
  }

  return getBlockDistribution(TOP_10_DISTRIBUTION, prizeAmount, minWinnerCount);
};
