export const MENU_KEYS = {
  DASHBOARD: '/dashboard',
  MANAGE_ADMINS: '/dashboard/admins',
  COMPETITIONS: '/dashboard/competitions',
  COMPETITION: '/dashboard/competitions/:id',
  TEAMS: '/dashboard/teams',
  FIXTURES: '/dashboard/fixtures',
  CONTESTS: '/dashboard/contests',
  BANK: '/dashboard/bank',
  DEPOSITS: '/dashboard/deposits',
  WITHDRAWS: '/dashboard/withdraws',
  OPTIONS: '/dashboard/options',
  SPONSORS: '/dashboard/sponsors',
  SPORTS: '/dashboard/sports',
  LOG_OUT: 'LOG_OUT',
};
