import { Select } from 'antd';
import { isNil } from 'ramda';

export const ActiveSelector = ({
  active,
  onChange,
}: {
  active: boolean | null | undefined;
  onChange: (active: boolean | null) => void;
}) => (
  <Select
    value={isNil(active) ? null : active}
    options={[
      { value: true, label: 'Activa' },
      { value: false, label: 'Inactiva' },
      { value: null, label: 'Cualquiera' },
    ]}
    onChange={onChange}
  />
);
