import gql from 'graphql-tag';
import {
  CompetitionAllInfo,
  CompetitionShallow,
} from '../fragments/competition';
import { TeamAllInfo } from '../fragments/team';

export const COMPETITION = gql`
  query competition($id: ID!) {
    competition(id: $id) {
      ...CompetitionAllInfo
    }
  }
  ${CompetitionAllInfo}
`;

export const COMPETITIONS = gql`
  query competitions(
    $offset: Int!
    $limit: Int!
    $active: Boolean
    $options: FilterCompetitionInput
  ) {
    competitions(
      offset: $offset
      limit: $limit
      active: $active
      options: $options
    ) {
      ...CompetitionShallow
    }
  }
  ${CompetitionShallow}
`;

export const TEAMS = gql`
  query competitionTeams($id: ID!) {
    competitionTeams(id: $id) {
      ...TeamAllInfo
    }
  }
  ${TeamAllInfo}
`;

export const FIXTURECOUNT = gql`
  query competitionFixturesCount($id: ID!) {
    competitionFixturesCount(id: $id)
  }
`;

export const COMPETITIONSCOUNT = gql`
  query competitionsCount($options: FilterCompetitionInput) {
    competitionsCount(options: $options)
  }
`;
