import React, { ReactElement } from 'react';
import { Modal, Table } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { css, cx } from '@emotion/css';
import { AnyObject } from 'antd/lib/_util/type';

type LayoutProps<T extends AnyObject> = {
  style?: string;
  table: {
    pagination?: {
      pageSize?: number;
      total?: number;
      onChange?: (page: number, pageSize?: number) => void;
      onSizeChange?: (page: number, pageSize?: number) => void;
    };
    data: T[];
    columns: (ColumnType<T> & {
      dataIndex?: keyof T;
    })[];
    loading: boolean;
    onRowClick: (
      record: T,
      index?: number,
      e?: React.MouseEvent<Element, MouseEvent>
    ) => void;
  };
  filters?: ReactElement | null;
  panel?: {
    onCancel: () => void;
    onOk: () => Promise<void>;
    state: boolean;
    title: string;
    element: ReactElement;
    disabled?: boolean;
  };
  modal?: {
    autoWidth?: boolean;
    onCancel: () => void;
    onOk: () => Promise<void>;
    state: boolean;
    title: string;
    element: ReactElement;
    disabled?: boolean;
    closeAfterOk?: boolean;
  };
};

export const Layout = <T extends AnyObject>({
  filters: Filters = null,
  panel,
  table,
  modal,
  style = '',
}: LayoutProps<T>) => {
  return (
    <>
      <div className={cx('layout', style, styles)}>
        <div className="table-wrapper">
          {Filters && <div className="controls">{Filters}</div>}
          <Table
            rowKey="id"
            dataSource={table.data}
            onRow={(x, i) => ({
              onDoubleClick: undefined,
              onContextMenu: undefined,
              onMouseEnter: undefined,
              onMouseLeave: undefined,
              onClick: (e) => {
                e.stopPropagation();
                e.preventDefault();
                if ((e.target as HTMLTableCellElement)?.tagName === 'TD') {
                  table.onRowClick(x, i, e);
                }
              },
            })}
            className="table"
            columns={table.columns}
            loading={table.loading}
            pagination={{
              showSizeChanger: true,
              onShowSizeChange: table.pagination?.onSizeChange,
              pageSize: table.pagination?.pageSize ?? 20,
              total: table.pagination?.total,
              onChange: table.pagination?.onChange,
            }}
          />
        </div>
      </div>

      {panel && (
        <Modal
          width={'100%'}
          styles={{
            body: { overflowY: 'auto', overflowX: 'hidden' },
          }}
          centered
          title={panel.title}
          open={panel.state}
          onCancel={panel.onCancel}
          onOk={panel.onOk}
          okButtonProps={{ disabled: panel.disabled }}
        >
          {panel.element}
        </Modal>
      )}
      {!!modal && (
        <Modal
          width={modal.autoWidth ? 'auto' : '100%'}
          styles={{
            body: {
              overflowY: 'auto',
              overflowX: 'hidden',
              maxHeight: 'calc(100vh - 10rem)',
            },
          }}
          centered
          title={modal.title}
          open={modal.state}
          onCancel={modal.onCancel}
          onOk={() => {
            modal.onOk();
            if (modal.closeAfterOk) modal.onCancel();
          }}
          maskClosable={false}
          okButtonProps={{ disabled: modal.disabled }}
        >
          {modal.element}
        </Modal>
      )}
    </>
  );
};

const formStyles = css`
  overflow: hidden !important;
  overflow-y: overlay !important;
  overflow-y: auto !important;
  max-height: calc(100vh - 10rem);

  &[data-open='false'] {
    flex-basis: 0%;
    max-width: 0;
  }

  img {
    // height: 100%;
    width: 100%;
    aspect-ratio: 1;
    // max-height: 200px;
    // max-width: 200px;
    min-height: 160px;
    aspect-ratio: 1;
    object-position: center center;
    object-fit: cover;
    margin: 0 auto;
    border-radius: 4px;
  }

  .row {
    display: flex;
    gap: 1rem;

    .ant-btn {
      flex: 1 1 50%;
    }
  }

  .ant-row.ant-form-item {
    margin-bottom: 0;
    width: 100%;

    .ant-form-item-children {
      width: 100%;
      display: block;

      > * {
        width: 100%;
      }
    }
  }
`;

const styles = css`
  display: flex;
  flex-flow: row nowrap;
  gap: 1em;
  max-width: 1600px;
  margin: 0 auto;
  width: 100%;
  height: 100%;

  .table-wrapper {
    flex: 9 1 600px;
    display: flex;
    flex-flow: row wrap;
    gap: 0.5rem;
    max-width: 100%;
    align-content: flex-start;
    position: relative;
    overflow: hidden !important;
    height: 100%;

    .controls {
      position: sticky;
      top: 0;
      flex: 1 0 100%;
      display: flex;
      flex-flow: row no-wrap;
      gap: 0.5em;
      top: 0;
      z-index: 1;
      background: white;

      .ant-select {
        min-width: 8rem;
      }

      .ant-input-affix-wrapper:first-child {
        flex: 1 1 300px;
      }
    }

    .table {
      flex: 1 0 0%;
      grid-area: table;
      height: 100%;
      height: calc(100% - 1.5rem);

      .ant-table {
        overflow: auto;
        overflow: overlay;
      }

      .ant-table-body {
        height: calc(100% - 60px);
        overflow-y: auto;
      }

      .ant-pagination.ant-table-pagination {
        margin: 1rem 0 0;
      }

      > div {
        height: calc(100% - 70px) !important;

        > div {
          height: 100% !important;

          &,
          > div {
            &,
            > div {
              &,
              > div {
                height: inherit;
              }
            }
          }
        }
      }
    }
  }

  .details {
    ${formStyles}
    flex-basis: 50%;
    max-width: 600px;
    min-width: 200px;
  }
`;
