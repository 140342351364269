import gql from 'graphql-tag';

import * as FixtureFragments from '../fragments/fixture';

export const FIXTURE = gql`
  query fixture($id: ID!) {
    fixture(id: $id) {
      ...allInfo
    }
  }
  ${FixtureFragments.allInfo}
`;

export const FIXTURES = gql`
  query fixtures($offset: Int!, $limit: Int!, $filter: FixtureFilter) {
    fixtures(offset: $offset, limit: $limit, filter: $filter) {
      ...allInfo
    }
  }
  ${FixtureFragments.allInfo}
`;

export const COUNT = gql`
  query countFixtures($offset: Int!, $limit: Int!, $filter: FixtureFilter) {
    countFixtures(offset: $offset, limit: $limit, filter: $filter)
  }
`;

export const LIVE_FIXTURE = gql`
  query liveFixture($id: ID!) {
    fixture(id: $id) {
      id
      type
      status
      ...FixtureParticipant
    }
  }
  ${FixtureFragments.fixtureParticipant}
`;
