import { pipe, splitEvery, map, join, reverse } from 'ramda';

/**
 * No decimal support.
 * @param value the number string to mask.
 * @param options the mask options
 */
export const maskNumber = (
  value?: string,
  {
    prefix = '$',
    thousandsChar = ',',
  }: { prefix?: string; thousandsChar?: ',' | '.' } = {
    prefix: '$',
    thousandsChar: '.',
  }
) => {
  const defaultResult = { formatted: '', numberValue: 0 };
  const prefixRegex = new RegExp(`[${prefix}/g]`, 'g');
  const thousandsCharRegex = new RegExp(`[${thousandsChar}/g]`, 'g');

  if (!value) return defaultResult;

  const stripped = value
    .replace(/\s/g, '')
    .replace(prefixRegex, '')
    .replace(thousandsCharRegex, '');

  if (!stripped) {
    return defaultResult;
  }

  // ramda types complain incorrectly here.
  const formatted = pipe(
    // @ts-ignore
    reverse,
    splitEvery(3),
    map(reverse),
    reverse,
    join(thousandsChar)
    // @ts-ignore
  )(stripped) as string;

  return {
    formatted: `${prefix}${formatted}`,
    numberValue: Number(formatted.replace(thousandsCharRegex, '')),
  };
};
