import gql from 'graphql-tag';

import * as userFragments from '../fragments/user';
import * as authedUserFragments from '../fragments/authed-user';
import * as contestFragments from '../fragments/contest';

export const FRIENDS = gql`
  query authedUserFriends($friendsOffset: Int!, $friendsLimit: Int!) {
    authenticatedUser {
      ...userFriends
      id
    }
  }
  ${userFragments.friends}
`;

export const FRIEND_REQUESTS = gql`
  query authenticatedUserFriendRequests {
    authenticatedUser {
      ...userFriendRequests
    }
  }
  ${authedUserFragments.friendRequests}
`;

export const SOCIAL = gql`
  query authenticatedUserSocial {
    authenticatedUser {
      id
      balance
      privateContestInvites {
        ...contestAll
      }
      profileImageUrl
      username
      ...socialFeed
      ...userFriendRequests
    }
  }
  ${authedUserFragments.friendRequests}
  ${authedUserFragments.socialFeed}
  ${contestFragments.all}
`;

export const BANK_INFO = gql`
  query bankInfo {
    authenticatedUser {
      ...userAllNames
      ...balanceFeed
      ...userDocumentId
      ...userPaymentMethods
      balance
      bio
      email
      id
      role
    }
  }
  ${userFragments.allNames}
  ${authedUserFragments.balanceFeed}
  ${userFragments.documentId}
  ${authedUserFragments.paymentMethods}
`;

export const PAYOUTS = gql`
  query payouts(
    $payoutsAfter: String
    $payoutsFirst: Int!
    $payoutsCreatedAt: Date
  ) {
    authenticatedUser {
      id
      ...userPayouts
    }
  }
  ${authedUserFragments.payouts}
`;
