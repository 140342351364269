import gql from 'graphql-tag';
import { TeamAllInfo } from '../fragments/team';

export const TEAM_COUNT = gql`
  query teamCount {
    teamCount
  }
`;

export const TEAM = gql`
  query team($id: ID!) {
    team(id: $id) {
      ...TeamAllInfo
      competitions {
        id
        name
        region
      }
    }
  }
  ${TeamAllInfo}
`;

export const TEAMS = gql`
  query teams($offset: Int!, $limit: Int!, $data: FilterTeamInput) {
    teams(offset: $offset, limit: $limit, data: $data) {
      ...TeamAllInfo
      competitions {
        id
      }
    }
  }
  ${TeamAllInfo}
`;
