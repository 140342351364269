import { useMemo, useState } from 'react';

export default function useArray<T, K = T extends Object ? keyof T : undefined>(
  defaultValue: T[],
  { filterAttribute: att }: { filterAttribute?: K } = {}
) {
  const [original, setOriginal] = useState(defaultValue);
  const [array, setArray] = useState(defaultValue);
  const added = useMemo(
    () =>
      array
        .map((x) => (att ? (x as any)[att] : x))
        .filter(
          (x) => !original.map((x) => (att ? (x as any)[att] : x)).includes(x)
        ),
    [array]
  );
  const removed = useMemo(
    () =>
      original
        .map((x) => (att ? (x as any)[att] : x))
        .filter(
          (x) => !array.map((x) => (att ? (x as any)[att] : x)).includes(x)
        ),
    [array]
  );

  const push = (element: T) => {
    setArray((a) => [...a, element]);
  };

  const filter = (callback: Parameters<T[]['filter']>[0]) => {
    setArray((a) => a.filter(callback));
  };

  const update = (index: number, element: T) => {
    setArray((a) => [
      ...a.slice(0, index),
      element,
      ...a.slice(index + 1, a.length),
    ]);
  };

  const remove = (index: number) => {
    setArray((a) => [...a.slice(0, index), ...a.slice(index + 1, a.length)]);
  };

  const clear = () => setArray([]);

  const reset = (value?: T[]) => {
    if (value) {
      setOriginal(value);
      setArray(value);
    } else setArray(original);
  };

  return {
    array,
    original,
    added,
    removed,
    set: setArray,
    push,
    filter,
    update,
    remove,
    clear,
    reset,
  };
}
