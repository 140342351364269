import {
  SportType,
  SportInput,
} from '@cobra/common/dist/graphql/generated/graphql';
import { Col, Input, Row, Select } from 'antd';
import React, { FC } from 'react';

export const SportForm: FC<{
  data: SportInput;
  onChange: (data: Partial<SportInput>) => void;
}> = ({ data, onChange }) => {
  return (
    <Row gutter={16}>
      <Col span={12}>
        <Input
          placeholder="name"
          value={data.name}
          onChange={(x) =>
            onChange({
              name: x.target.value,
              id: x.target.value
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .replace(/\W/g, '')
                .trim()
                .toUpperCase(),
            })
          }
        />
      </Col>
      <Col span={12}>
        <Select
          style={{ width: '100%' }}
          value={data.type}
          onChange={(x: SportType) => onChange({ type: x })}
          options={Object.entries(SportType).map(([k, v]) => ({
            value: v,
            label: k,
          }))}
        />
      </Col>
    </Row>
  );
};
