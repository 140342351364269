import { ClearOutlined } from '@ant-design/icons';
import { Row, Col, Input, Button, Space, Select, Form, Flex } from 'antd';
import useArray from '../../../../hooks/useArray';
import {
  PointsSchemaInput,
  CompetitionConfigPointsInput,
  CompetitionConfigPhaseInput,
  PointSchemaRange,
} from '@cobra/common/dist/graphql/generated/graphql';
import { useEffect } from 'react';
import { ScoreRange } from '../parts/scoreRange';

const BreakdownKeys: {
  value: keyof PointsSchemaInput;
  label: string;
  description: string;
  defaultValue: number | number[] | PointSchemaRange[];
}[] = [
  {
    value: 'inPhase',
    label: 'Participante en fase',
    description: '',
    defaultValue: [0, 0],
  },
  {
    value: 'participants',
    label: 'Participante en partido',
    description: '',
    defaultValue: [0, 0],
  },
  {
    value: 'placing',
    label: 'Participante en posición del podio',
    description: '',
    defaultValue: [0, 0],
  },
  {
    value: 'podium',
    label: 'Particiapnte en podio',
    description: '',
    defaultValue: [0, 0],
  },
  {
    value: 'positions',
    label: 'Participante en posición del partido',
    description: '',
    defaultValue: [0, 0],
  },
  {
    value: 'score',
    label: 'Puntaje del participante',
    description: '',
    defaultValue: 0,
  },
  {
    value: 'scoreRange',
    label: 'Rango de puntos',
    description: '',
    defaultValue: [],
  },
  {
    value: 'scoreOver',
    label: 'Puntaje total del partido superior a',
    description: '',
    defaultValue: 0,
  },
  {
    value: 'scoreTotal',
    label: 'Puntaje total del partido, exacto',
    description: '',
    defaultValue: 0,
  },
  {
    value: 'scoreUnder',
    label: 'Puntaje total del partido inferior a',
    description: '',
    defaultValue: 0,
  },
];
export const CompetitionConfigPoints = ({
  config,
  phases,
  onChange,
}: {
  config: CompetitionConfigPointsInput[] | null | undefined;
  phases: CompetitionConfigPhaseInput[];
  onChange: (phases: CompetitionConfigPointsInput[]) => void;
}) => {
  const points = useArray(decodePointsConfig(config ?? []));
  const showPhase = phases.length > 0;

  const updatePoints = (
    index: number,
    value: number | number[] | PointSchemaRange[]
  ) => {
    const upd = { ...points.array[index] };
    if (Array.isArray(upd.points) && Array.isArray(value)) {
      upd.points = [...value] as number[];
    } else upd.points = value;

    points.update(index, upd);
  };
  useEffect(() => {
    console.log({ points: points.array });

    onChange(encodePointsConfig(points.array));
  }, [points.array]);

  return (
    <>
      <Form layout="vertical">
        <Space direction="vertical" style={{ width: '100%' }}>
          {points.array.map((point, i) => (
            <Row key={i} gutter={16} style={{ maxWidth: '100%' }}>
              {showPhase && (
                <Col span={5}>
                  <Select
                    placeholder="Selecciones una fase"
                    showSearch
                    allowClear
                    value={point.phase}
                    optionFilterProp="label"
                    options={phases.map((p) => ({
                      value: p.id,
                      label: p.name,
                    }))}
                    onChange={(x) => points.update(i, { ...point, phase: x })}
                  />
                </Col>
              )}
              <Col span={12}>
                <Select
                  placeholder="Selecciones una tipo de puntaje"
                  value={point.breakdownType}
                  options={BreakdownKeys}
                  optionRender={
                    ({ data }) =>
                      // <Tooltip
                      //   placement="right"
                      //   showArrow
                      //   title={data.description}
                      // >
                      data.label
                    // </Tooltip>
                  }
                  onChange={(x, record) => {
                    const rec = Array.isArray(record)
                      ? record[0].defaultValue
                      : record.defaultValue;
                    points.update(i, {
                      ...point,
                      points:
                        point.breakdownType === 'scoreRange'
                          ? []
                          : Array.isArray(rec)
                            ? [...rec]
                            : (rec as any),
                      breakdownType: x,
                    });
                  }}
                />
              </Col>
              <Col span={showPhase ? 6 : 11}>
                <Flex gap={8}>
                  {point.breakdownType === 'scoreRange' ? (
                    <ScoreRange
                      range={
                        (point.points ?? []) as unknown as PointSchemaRange[]
                      }
                      onChange={(x) => updatePoints(i, x)}
                    />
                  ) : Array.isArray(point.points) ? (
                    <>
                      <Input
                        type="number"
                        value={(point.points[0] as number) ?? 0}
                        onChange={(x) => {
                          const upd = [
                            Number(x.target.value),
                            (points.array[i].points as number[])[1] ?? 0,
                          ];
                          updatePoints(i, upd);
                        }}
                      />
                      <Input
                        type="number"
                        value={(point.points[1] as number) ?? 0}
                        onChange={(x) => {
                          const upd = [
                            (points.array[i].points as number[])[0] ?? 0,
                            Number(x.target.value),
                          ];
                          updatePoints(i, upd);
                        }}
                      />
                    </>
                  ) : (
                    <Input
                      type="number"
                      value={point.points}
                      onChange={(x) => updatePoints(i, Number(x.target.value))}
                    />
                  )}
                </Flex>
              </Col>
              <Col span={1}>
                <Button
                  onClick={() => points.remove(i)}
                  icon={<ClearOutlined />}
                />
              </Col>
            </Row>
          ))}
          <Button
            style={{ width: '100%' }}
            onClick={() =>
              points.push({
                phase: undefined,
                breakdownType: 'score',
                points: 0,
              })
            }
          >
            Añadir nuevo esquema de puntos
          </Button>
        </Space>
      </Form>
    </>
  );
};

type CompetitionConfigPoints = {
  phase: string | null | undefined;
  breakdownType: keyof PointsSchemaInput;
  points: number | number[] | PointSchemaRange[];
};

const decodePointsConfig = (config: CompetitionConfigPointsInput[]) =>
  config.reduce<CompetitionConfigPoints[]>(
    (t, c) => [
      ...t,
      ...Object.entries(c.breakdown).reduce<CompetitionConfigPoints[]>(
        (y, [k, v]) => {
          if (!v) return y;
          const isNumber = typeof v === 'number' && v > 0;
          const isNumberArr =
            Array.isArray(v) && v.some((x) => typeof x === 'number' && x > 0);
          const isRangeArr =
            Array.isArray(v) &&
            v.some((x) => typeof x === 'object' && x?.points > 0);

          if (isNumber || isNumberArr || isRangeArr) {
            y.push({
              phase: c.phase,
              breakdownType: k as keyof PointsSchemaInput,
              points: v,
            });
          }
          return y;
        },
        []
      ),
    ],
    []
  );

const encodePointsConfig = (config: CompetitionConfigPoints[]) => {
  return config.reduce((t: CompetitionConfigPointsInput[], c) => {
    const upd = [...t];
    let index = upd.findIndex((x) => x.phase === c.phase);
    if (index < 0) {
      index = upd.length;
      upd.push({
        phase: c.phase,
        breakdown: {},
      });
    }
    (upd[index].breakdown[c.breakdownType] as any) = c.points;
    return upd;
  }, [] as CompetitionConfigPointsInput[]);
};
