import gql from 'graphql-tag';

export const OPTION = gql`
  query option($id: String!) {
    option(id: $id) {
      id
      value
    }
  }
`;
export const OPTIONS = gql`
  query options {
    options {
      id
      value
    }
  }
`;
