import gql from 'graphql-tag';

import * as fixtureFragments from './fixture';
import { Prediction } from './prediction';
import { SponsorData } from './sponsor';
import { Config } from './competition-config';

export const base = gql`
  fragment contestBase on Contest {
    contestantCount
    config {
      ...Config
    }
    coupons
    createdAt
    currency
    distribution
    entryAmount
    id
    info
    initialPot
    maxContestants
    maxEntriesPerContestant
    minContestants
    minWinnerCount
    name
    prizeAmount
    priority
    sport
    sponsor {
      ...SponsorData
    }
    status
    type
    winType
    featured
    fee
    creator {
      id
      username
      firstName
      lastName
      profileImageUrl
    }
    pendingContestants {
      id
      username
      firstName
      lastName
      profileImageUrl
    }
  }
  ${Config}
  ${SponsorData}
`;

export const all = gql`
  fragment contestAll on Contest {
    ...contestBase
    fixtures {
      ...allInfo
    }
  }
  ${base}
  ${fixtureFragments.allInfo}
  ${SponsorData}
`;

export const joinedPartial = gql`
  fragment joinedPartial on JoinedContest {
    id
    contest {
      ...contestAll
    }
    info {
      id
      createdAt
      predictions {
        ...Prediction
      }
      points
      prize
      rank
    }
    entries {
      id
      createdAt
      predictions {
        ...Prediction
      }
      coupon
      points
      prize
      rank
    }
  }
  ${all}
  ${Prediction}
`;
