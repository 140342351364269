import React, { useState } from 'react';
import { RouteProps } from 'react-router';
import { useMutation, ApolloError } from '@apollo/client';
import { Form, Input, Tooltip, Button, Select } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { css, cx } from '@emotion/css';
import { FormProps } from 'antd/lib/form';

import { Role } from '@cobra/common/dist/utils/constants';
import {
  CreateAdminMutation as createAdmin,
  CreateAdminMutationVariables as createAdminVariables,
} from '@cobra/common/dist/graphql/generated/graphql';
import { CREATE_ADMIN } from '@cobra/common/dist/graphql/mutations';

import { centeredColumn, createContainerStyle } from '../../styles';
import GenericError from '../GenericError/GenericError';
import { useNavigate } from 'react-router-dom';

import { MENU_KEYS } from '../../contants/menukeys';

const { Option } = Select;

// @ts-ignore
interface CreateAdminProps extends RouteProps, FormProps {}
interface CreateAdminState {
  error: null | ApolloError;
  confirmDirty: boolean;
}

const { useForm, Item } = Form;

const CreateAdminForm: React.FC<CreateAdminProps> = () => {
  const [form] = useForm();
  const navigate = useNavigate();
  const [{ error, confirmDirty }, setFormState] = useState<CreateAdminState>({
    error: null,
    confirmDirty: false,
  });

  const handleConfirmBlur = (e: React.FormEvent<EventTarget>) => {
    const value = e.target;
    setFormState({ error, confirmDirty: confirmDirty || !!value });
  };

  const handleError = (err: ApolloError) =>
    setFormState({ confirmDirty, error: err });

  const compareToFirstPassword = (_: any, value: string) =>
    value && value !== form.getFieldValue('password')
      ? Promise.reject(new Error('Las contraseñas no coinciden!'))
      : Promise.resolve();

  const { getFieldValue } = form;

  const adminData = {
    email: getFieldValue('email'),
    username: getFieldValue('username'),
    role: getFieldValue('role'),
    password: getFieldValue('password'),
  };
  const [createAdminMutation, { loading, error: mutationError }] = useMutation<
    createAdmin,
    createAdminVariables
  >(CREATE_ADMIN, {
    variables: { data: adminData },
    onError: handleError,
    onCompleted: () => navigate(MENU_KEYS.MANAGE_ADMINS),
  });

  return (
    <Form
      form={form}
      className={createContainerStyle}
      name="CreateAdmin"
      onFinish={createAdminMutation}
    >
      <Item
        label="E-mail"
        name={'email'}
        rules={[
          {
            type: 'email',
            message: 'E-mail inválido!',
          },
          {
            required: true,
            message: 'E-mail requerido!',
          },
        ]}
      >
        <Input />
      </Item>

      <Item
        label={
          <span>
            Usuario&nbsp;
            <Tooltip title="Elige un nombre unico para tu usuario">
              <QuestionCircleOutlined />
            </Tooltip>
          </span>
        }
        name={'username'}
        rules={[
          {
            required: true,
            message: 'Nombre de usuario requerido!',
            whitespace: true,
          },
        ]}
      >
        <Input />
      </Item>

      <Item
        label="Nivel de Acceso"
        name={'role'}
        rules={[{ required: true, message: 'Nivel de Acceso requerido!' }]}
      >
        <Select placeholder="Admin, Super Admin">
          <Option value={Role.Admin}>Admin</Option>
          <Option value={Role.SuperAdmin}>Super Admin</Option>
        </Select>
      </Item>

      <Item
        label="Contraseña"
        name={'password'}
        rules={[
          {
            required: true,
            message: 'Contraseña requerida!',
          },
        ]}
      >
        <Input.Password />
      </Item>

      <Item
        label="Confirmar Contraseña"
        name={'confirm'}
        rules={[
          {
            required: true,
            message: 'Contraseña requerida!',
          },
          {
            validator: compareToFirstPassword,
          },
        ]}
      >
        <Input.Password onBlur={handleConfirmBlur} />
      </Item>

      <Item style={{ alignSelf: 'flex-end' }}>
        <div className={cx(centeredColumn, css({ alignItems: 'flex-start' }))}>
          <Button loading={loading} type="primary" htmlType="submit">
            Crear
          </Button>
        </div>
      </Item>
      <GenericError error={mutationError} flexLayout alignSelf="center" />
    </Form>
  );
};

export default CreateAdminForm;
