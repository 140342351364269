import gql from 'graphql-tag';
import { ContestEntry } from '../fragments/contest-entry';

export const WRITE = gql`
  mutation writeContestEntry($id: ID!, $data: ContestEntryInput!) {
    writeContestEntry(id: $id, data: $data) {
      ...ContestEntry
    }
  }
  ${ContestEntry}
`;

export const REMOVE = gql`
  mutation removeContestEntry($id: ID!) {
    removeContestEntry(id: $id) {
      ...ContestEntry
    }
  }
  ${ContestEntry}
`;
