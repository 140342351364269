import { SponsorInput } from '@cobra/common/dist/graphql/generated/graphql';
import { Form, Input, Space, Switch } from 'antd';
import { FC } from 'react';
import { UploadImageInput } from '../../Input/UploadImage';
import { isEmpty, isNil } from 'ramda';

const { Item } = Form;

export const SponsorForm: FC<{
  data: SponsorInput;
  onChange: (data: Partial<SponsorInput>) => void;
}> = ({ data, onChange }) => {
  return (
    <Form layout="vertical">
      <Space size={16} direction="vertical">
        <Item label="Nombre">
          <Input
            placeholder="name"
            value={data?.name ?? undefined}
            onChange={(x) => onChange({ name: x.target.value })}
          />
        </Item>
        <Item label="Active">
          <Switch
            checked={data.active ?? false}
            onChange={() => onChange({ active: !data.active })}
          />
        </Item>
        <Item label="Logo">
          <UploadImageInput
            preview={data.logo}
            onChange={(x) => onChange({ logo: x })}
            avatar
            name={`sponsor/logo/${data.id}`}
          />
        </Item>
        <Item label="Show banner in home">
          <Switch
            checked={data.bannerInHome ?? false}
            onChange={(x) => onChange({ bannerInHome: x })}
          />
        </Item>
        <Item label="Banner">
          <UploadImageInput
            preview={data.banner}
            onChange={(x) => {
              if (isEmpty(x) || isNil(x)) {
                onChange({
                  banner: undefined,
                  bannerInHome: false,
                  bannerLink: null,
                });
              } else onChange({ banner: x });
            }}
            name={`sponsor/banner/${data.id}`}
          />
        </Item>
        <Item label="Banner URL">
          <Input
            placeholder="https:///..."
            value={data?.bannerLink ?? undefined}
            onChange={(x) => onChange({ bannerLink: x.target.value })}
          />
        </Item>
        <Item label="Show popup in home">
          <Switch
            checked={data.popupInHome ?? false}
            onChange={(x) => onChange({ popupInHome: x })}
          />
        </Item>
        <Item label="Popup">
          <UploadImageInput
            preview={data.popup}
            onChange={(x) => {
              if (isEmpty(x) || isNil(x)) {
                onChange({
                  popup: undefined,
                  popupInHome: false,
                  popupLink: null,
                });
              } else onChange({ popup: x });
            }}
            avatar
            name={`sponsor/popup/${data.id}`}
          />
        </Item>
        <Item label="Popup URL">
          <Input
            placeholder="https:///..."
            value={data?.popupLink ?? undefined}
            onChange={(x) => onChange({ popupLink: x.target.value })}
          />
        </Item>
      </Space>
    </Form>
  );
};
