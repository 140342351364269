import gql from 'graphql-tag';
import { allNames } from './user';
import { Prediction } from './prediction';

export const ContestEntry = gql`
  fragment ContestEntry on ContestEntry {
    balance
    confirmed
    contestId
    coupon
    createdAt
    id
    index
    predictions {
      ...Prediction
    }
    points
    prize
    rank
    user {
      id
      email
      profileImageUrl
      ...userAllNames
    }
  }

  ${allNames}
  ${Prediction}
`;
