import gql from 'graphql-tag';

export * as AuthedUserMutations from './authed-user';
export * as BetMutations from './bet';
export * as CompetitionMutations from './competition';
export * as ContestMutations from './contest';
export * as ContestEntryMutations from './contest-entry';
export * as FixtureMutations from './fixture';
export * as OptionMutations from './option';
export * as SponsorMutations from './sponsor';
export * as SportMutations from './sport';
export * as TeamMutations from './team';

export const SIGN_IN = gql`
  mutation signIn($login: String!, $password: String!, $admin: Boolean) {
    signIn(login: $login, password: $password, admin: $admin) {
      accessToken
      refreshToken
      email
      id
    }
  }
`;

export const SIGN_UP = gql`
  mutation signUp(
    $username: String!
    $email: String!
    $password: String!
    $phone: String!
    $fcmTokens: String!
    $acceptedTermsOfService: Boolean!
    $acceptedPrivacyPolicies: Boolean!
    $options: SignUpOptions
  ) {
    signUp(
      username: $username
      email: $email
      password: $password
      phone: $phone
      fcmTokens: $fcmTokens
      acceptedTermsOfService: $acceptedTermsOfService
      acceptedPrivacyPolicies: $acceptedPrivacyPolicies
      options: $options
    ) {
      accessToken
      refreshToken
      email
      id
    }
  }
`;

export const CREATE_ADMIN = gql`
  mutation createAdmin($data: CreateAdminInput!) {
    createAdmin(data: $data) {
      id
      username
      email
      role
    }
  }
`;

export const UPDATE_ADMIN_ROLE = gql`
  mutation updateAdminRole($id: ID!, $role: String!) {
    updateAdminRole(id: $id, role: $role) {
      id
      username
      email
      role
    }
  }
`;

export const REQUEST_PASSWORD_RESET = gql`
  mutation requestPasswordReset($email: String!) {
    requestPasswordReset(email: $email)
  }
`;

export const REQUEST_WITHDRAWAL = gql`
  mutation requestWithdrawal($data: requestWithdrawalInput!) {
    requestWithdrawal(data: $data)
  }
`;

export const REQUEST_DEPOSIT = gql`
  mutation requestDeposit($data: requestDepositInput!) {
    requestDeposit(data: $data)
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($id: ID!, $token: String!, $password: String!) {
    resetPassword(id: $id, token: $token, password: $password)
  }
`;

export const ADD_PAYMENT_METHOD = gql`
  mutation addPaymentMethod($data: AddPaymentMethodInput!) {
    addPaymentMethod(data: $data) {
      id
      paymentMethods {
        accountNumberLastFour
        bankCode
        isDefault
      }
    }
  }
`;

export const DELETE_PAYMENT_METHOD = gql`
  mutation deletePaymentMethod($data: UpdatePaymentMethodInput!) {
    deletePaymentMethod(data: $data) {
      id
      paymentMethods {
        accountNumberLastFour
        bankCode
      }
    }
  }
`;

export const CHOOSE_PRIMARY_PAYMENT_METHOD = gql`
  mutation choosePrimaryPaymentMethod($data: UpdatePaymentMethodInput!) {
    choosePrimaryPaymentMethod(data: $data) {
      id
      paymentMethods {
        accountNumberLastFour
        bankCode
        isDefault
      }
    }
  }
`;

export const UPDATE_USER_INFO = gql`
  mutation updateInfo($data: UpdateInfoInput!) {
    updateInfo(data: $data) {
      bio
      documentId {
        lastFour
      }
      id
      username
      firstName
      lastName
      email
      profileImageUrl
      paymentMethods {
        accountNumberLastFour
        bankCode
        isDefault
      }
    }
  }
`;

export const UPDATE_USER_FCM_TOKEN = gql`
  mutation updateUserFcmToken($data: UpdateInfoInput!) {
    updateInfo(data: $data) {
      fcmTokens
    }
  }
`;

export const SEND_FRIEND_REQUEST = gql`
  mutation sendFriendRequest($receiverId: ID!) {
    sendFriendRequest(receiverId: $receiverId) {
      id
    }
  }
`;

export const DELETE_FRIEND = gql`
  mutation deleteFriend($friendId: ID!) {
    deleteFriend(friendId: $friendId) {
      id
      friendCount
    }
  }
`;

export const UPDATE_DEPOSIT_STATUS = gql`
  mutation updateDepositStatus($id: ID!) {
    updateDepositStatus(id: $id)
  }
`;

export const UPDATE_WITHDRAW_STATUS = gql`
  mutation updateWithdrawStatus($id: ID!) {
    updateWithdrawStatus(id: $id)
  }
`;
