/* eslint-disable @typescript-eslint/restrict-template-expressions */
import {
  ascend,
  complement,
  descend,
  either,
  equals,
  identity,
  is,
  isNil,
  isEmpty,
  prop,
  sort,
  tryCatch,
} from 'ramda';
import validator from 'validator';
import { ApolloError } from '@apollo/client';

import {
  ContestStatus,
  FixtureStatus,
  FixtureTypes,
  User,
  // authenticatedUser_authenticatedUser_friendRequestsReceived,
  // authenticatedUser_authenticatedUser_friendRequestsSent,
} from '../graphql/generated/graphql';

import { Sport, Role, Language } from './constants';
import dictionaries from '../i18n';

const isEmailValidator = validator.isEmail;

export const snakeToLower = (str: string) =>
  str.split('_').join(' ').toLowerCase();

export const sortByDateAscending = <T>(dateProp: string, list: T[]) =>
  sort(
    ascend((obj: T) => prop(dateProp as keyof T, obj) as unknown as Date),
    list
  );

export const sortByDateDescending = <T>(dateProp: string, list: T[]) =>
  sort(
    descend<T>((obj: T) => prop(dateProp as keyof T, obj) as unknown as Date)
  )(list);

export const isBaseball = equals<unknown>(Sport.Baseball);

export const isNotBaseball = complement(isBaseball);

export const getFileExtension = (file: string) => file.slice(-3);

export const isJsExtension = equals('.js');

export const isTsExtension = equals('.ts');

export const isJsOrTsExtension = either(isJsExtension, isTsExtension);

export const isDevelopment = () => process.env.NODE_ENV === 'development';

export const isLocalStage = () => process.env.STAGE === 'local';

export const isTestingStage = () => process.env.STAGE === 'testing';

export const isStagingStage = () => process.env.STAGE === 'staging';

export const isProductionStage = () => process.env.STAGE === 'production';

/**
 * Determines if it's a stage accessible to users.
 */
export const isLiveStage = () => isStagingStage() || isProductionStage();

export const isString = (value: unknown): value is string => is(String, value);

export const isNumber = (value: unknown): value is number => is(Number, value);

export const isEmail = isEmailValidator;

export const isNilOrEmpty = (value: unknown): value is null | undefined =>
  isNil(value) || isEmpty(value);

export const isUserRole = (maybeUserRole: string): maybeUserRole is Role =>
  Object.values(Role).includes(maybeUserRole as Role);

export const toCents = (unitAmount: number) => unitAmount * 100;

export const safeJsonParse = tryCatch(JSON.parse, identity);

export const capitalize = (value: string) =>
  `${value.charAt(0).toUpperCase()}${value.slice(1)}`;

export const truncate = (maxLength: number, text: string) => {
  if (maxLength > text.length) return text;

  return `${text.slice(0, maxLength)}...`;
};

export const checkIfActiveFriendRequest = (
  friendRequestsReceived: User['friendRequestsReceived'] = [],
  friendRequestsSent: User['friendRequestsSent'] = [],
  senderId = ''
) => {
  const friendReqsSent = friendRequestsSent.filter(
    (friendReq) => friendReq.id === senderId
  );
  const friendReqsReceived = friendRequestsReceived.filter(
    (friendReq) => friendReq.id === senderId
  );
  return friendReqsSent.length || friendReqsReceived.length;
};

export const getFixtureStatusDisplayValue = (key: FixtureStatus) => {
  switch (key) {
    case FixtureStatus.NOT_STARTED:
      return 'Sin Empezar';
    case FixtureStatus.STARTED:
      return 'En Marcha';
    case FixtureStatus.ENDED:
      return 'Finalizado';
    case FixtureStatus.POSTPONED:
      return 'Pospuesto';
    default:
      throw new Error('invalid match status');
  }
};

export const getFixtureTypeDisplayValue = (key: FixtureTypes) => {
  switch (key) {
    case FixtureTypes.REGULAR:
      return 'Regular';
    case FixtureTypes.SOCCER_ELIMINATION:
      return 'Eliminatoria';
    default:
      throw new Error('invalid match type');
  }
};

export const getContestStatusDisplay = (key: ContestStatus) => {
  switch (key) {
    case ContestStatus.NOT_STARTED:
      return 'Sin Empezar';
    case ContestStatus.STARTED:
      return 'En Marcha';
    case ContestStatus.VOIDED:
      return 'Anulada';
    case ContestStatus.ENDED:
      return 'Finalizada';
    default:
      throw new Error('invalid contest status');
  }
};

export interface PitcherStats {
  name?: string;
  wins?: number;
  losses?: number;
}

export interface BaseballFixtureStats {
  [index: string]: PitcherStats | undefined;
  homePitcher?: PitcherStats;
  awayPitcher?: PitcherStats;
}

export const getBaseballFixtureStats = (
  stats: string
): BaseballFixtureStats => {
  try {
    return JSON.parse(stats);
  } catch (error) {
    throw Error(`Failed to get baseball fixture stats: ${error}`);
  }
};

interface GetErrorMessageFromCodeProps {
  error: ApolloError;
  language: Language;
}
export const getErrorMessageFromCode = ({
  error,
  language,
}: GetErrorMessageFromCodeProps) => {
  const getGraphQLErrors = error.graphQLErrors?.map((e) => {
    const eDefaultMessage = e.message;
    const eCode = e.extensions
      ?.code as keyof (typeof dictionaries)[typeof language];
    const message = dictionaries[language][eCode] || eDefaultMessage;

    return message;
  });

  return getGraphQLErrors;
};

export const getIpCountry = async () => {
  const response = await fetch(
    'https://api.ipgeolocation.io/ipgeo?lang=en&apiKey=c20b4fef07474ca59fa2252876c146ca'
  );
  const data = (await response.json()) as { country_name: string };

  return data.country_name as string;
};

export const isMexico = (country: string) => country === 'Mexico';

export const normalize = (string: string) =>
  string
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
