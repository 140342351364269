import { MinusCircleOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import {
  CompetitionTeamsQuery,
  CompetitionTeamsQueryVariables,
  FixtureParticipantInput,
  FixtureStatus,
} from '@cobra/common/dist/graphql/generated/graphql';
import { CompetitionQueries } from '@cobra/common/dist/graphql/queries';
import {
  Button,
  Col,
  InputNumber,
  Row,
  Select,
  Space,
  Switch,
  Typography,
} from 'antd';
import { equals, isNil } from 'ramda';
import { useEffect } from 'react';
import useArray from '../../../../hooks/useArray';
import { useToggle } from '../../../../hooks/useToggle';

const defaultFP: FixtureParticipantInput = {
  id: '',
  image: '',
  name: '',
  odds: 0,
  proposedId: undefined,
  score: 0,
  type: '',
  position: 0,
};

export const TeamSelector = ({
  participants,
  update,
  sport,
  competition,
  status,
}: {
  participants: FixtureParticipantInput[];
  update: (participants: FixtureParticipantInput[]) => void;
  sport: string;
  competition: string;
  status: FixtureStatus;
}) => {
  const teamsData = useArray(participants);
  const [showOdds, toggle] = useToggle(false);
  const notStarted = status === FixtureStatus.NOT_STARTED;

  const toggleItem = (team: (typeof competitionTeams)[0]) => {
    if (isNil(team)) return;
    const tIds = teamsData.array.map((x) => x.id);
    const index = tIds.indexOf(team.id);
    if (index < 0) {
      teamsData.push({
        ...defaultFP,
        id: team.id,
        name: team.name,
        image: team.image ?? '',
        type: team.type ?? '',
        position: teamsData.array.length,
      });
    } else {
      teamsData.remove(index);
    }
  };

  const {
    data: { competitionTeams = [] } = { competitionTeams: [] },
    loading,
    refetch,
  } = useQuery<CompetitionTeamsQuery, CompetitionTeamsQueryVariables>(
    CompetitionQueries.TEAMS,
    {
      fetchPolicy: 'no-cache',
    }
  );

  useEffect(() => {
    console.log({ competition, sport });
    refetch({ id: competition });
  }, [sport, competition]);

  useEffect(() => {
    update(teamsData.array);
  }, [JSON.stringify(teamsData.array)]);

  useEffect(() => {
    if (equals(participants, teamsData.array)) return;
    teamsData.set(participants);
  }, [participants]);

  return (
    <Space direction="vertical" size={16} style={{ width: '100%' }}>
      <Row gutter={16}>
        <Col span={9 + (showOdds ? 0 : 6) + (notStarted ? 0 : 3)}>
          <Typography.Text strong>Nombre</Typography.Text>
        </Col>
        <Col span={6}>
          <Typography.Text strong>Puntos</Typography.Text>
        </Col>
        {showOdds && (
          <Col span={6}>
            <Typography.Text strong>Odds</Typography.Text>
          </Col>
        )}
        {notStarted && <Col span={3} />}
      </Row>
      {participants.map((participant, i) => (
        <Row gutter={16} key={participant.id}>
          <Col span={9 + (showOdds ? 0 : 6) + (notStarted ? 0 : 3)}>
            <Typography.Text strong>{participant.name}</Typography.Text>
          </Col>
          <Col span={6}>
            <InputNumber
              value={participant.score}
              onChange={(x) =>
                teamsData.update(i, { ...participant, score: x ?? 0 })
              }
            />
          </Col>
          {showOdds && (
            <Col span={6}>
              <InputNumber
                suffix="%"
                value={participant.odds}
                onChange={(x) =>
                  teamsData.update(i, { ...participant, odds: x ?? 0 })
                }
              />
            </Col>
          )}
          {notStarted && (
            <Col span={3}>
              <Button
                type="link"
                icon={<MinusCircleOutlined />}
                onClick={() => teamsData.remove(i)}
              />
            </Col>
          )}
        </Row>
      ))}
      <Select
        disabled={loading}
        value={null}
        placeholder="Seleccione un equipo"
        onChange={(x, record) =>
          toggleItem(record as (typeof competitionTeams)[0])
        }
        showSearch
        allowClear
        optionFilterProp="label"
        options={competitionTeams
          .filter((team) => participants.findIndex((x) => x.id === team.id) < 0)
          .map((team) => ({
            ...team,
            value: team.id,
            label: team.name,
          }))}
      />

      <Space direction="horizontal" size={16}>
        <Typography.Text>Mostrar probabilidades</Typography.Text>
        <Switch value={showOdds} onChange={toggle} />
      </Space>
    </Space>
  );
};
