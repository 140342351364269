import React from 'react';
import { css, cx } from '@emotion/css';
import { ApolloError } from '@apollo/client';

import { errorStyle } from '../../styles';

interface GenericErrorProps {
  error?: Error | ApolloError | null;
  flexLayout?: boolean;
  alignSelf?: 'auto' | 'flex-start' | 'center' | 'flex-end';
  className?: string;
}

const GenericError: React.FC<GenericErrorProps> = ({
  error = null,
  flexLayout = false,
  alignSelf = 'auto',
  className = '',
}) => {
  if (!error) return null;

  const classNames = cx(
    errorStyle,
    {
      [`${css({ alignSelf })}`]: flexLayout,
    },
    className
  );

  return (
    <span className={classNames}>
      {error?.message
        .split('\n')
        .map((msg) => <p style={{ margin: 0 }}>{msg}</p>) ??
        'Oops! Ocurrió un error!'}
    </span>
  );
};

export default GenericError;
