export const Phases = {
  DEFAULT: 'DEFAULT',
  GROUPS: 'GROUPS',
  ROUNDOF32: 'ROUNDOF32',
  ROUNDOF16: 'ROUNDOF16',
  QUARTER: 'QUARTER',
  SEMI: 'SEMI',
  FINAL3rd: 'FINAL3rd',
  FINAL: 'FINAL',
} as const;

export type Phases = typeof Phases[keyof typeof Phases];

export const PHASES = [
  Phases.DEFAULT,
  Phases.GROUPS,
  Phases.ROUNDOF32,
  Phases.ROUNDOF16,
  Phases.QUARTER,
  Phases.SEMI,
  Phases.FINAL3rd,
  Phases.FINAL,
] as const;
