import gql from 'graphql-tag';

import * as contestFragments from '../fragments/contest';

export const CONTEST = gql`
  query contest($id: ID!) {
    contest(id: $id) {
      ...contestAll
    }
  }
  ${contestFragments.all}
`;

export const CONTESTS = gql`
  query contests($offset: Int!, $limit: Int!, $filter: ContestFilterInput) {
    contests(offset: $offset, limit: $limit, filter: $filter) {
      ...contestAll
    }
  }
  ${contestFragments.all}
`;

export const COUNT = gql`
  query contestsCount($filter: ContestFilterInput) {
    contestsCount(filter: $filter)
  }
`;

export const LIVE_CONTEST = gql`
  query liveContest($id: ID!) {
    contest(id: $id) {
      id
      status
      contestantCount
    }
  }
`;

export const ADMIN = gql`
  query adminContests(
    $offset: Int!
    $limit: Int!
    $filter: ContestFilterInput!
  ) {
    adminContests(offset: $offset, limit: $limit, filter: $filter) {
      ...contestAll
    }
  }
  ${contestFragments.all}
`;
