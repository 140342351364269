import { isEmpty } from 'ramda';
import { useSearchParams } from 'react-router-dom';

const NEW = 'new';

export const useIdParam = () => {
  const [params, setParams] = useSearchParams();
  const value = params.get('id') ?? '';
  const empty = isEmpty(value);
  const isNew = value === NEW;

  const is = (id: string) => id === value;

  const set = (id = NEW) => {
    setParams((p) => {
      p.set('id', id);
      return p;
    });
  };

  const clear = () => {
    setParams((p) => {
      p.delete('id');
      return p;
    });
  };

  return { value, set, clear, isEmpty: empty, isNew, is };
};
