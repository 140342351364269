import { useState } from 'react';

export const useToggle = (
  initial = false
): [boolean, (value?: boolean) => void] => {
  const [state, setState] = useState(initial);

  const toggle = (value?: boolean) =>
    setState((p) => {
      if (value === undefined) return !p;
      else return value;
    });

  return [state, toggle];
};
