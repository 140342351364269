import gql from 'graphql-tag';

export const ACCEPT_FRIEND_REQUEST = gql`
  mutation acceptFriendRequest($senderId: ID!) {
    acceptFriendRequest(senderId: $senderId) {
      id
      friendCount
    }
  }
`;

export const ADMIN_DEPOSIT = gql`
  mutation adminDeposit($username: String!, $amount: Int!) {
    adminDeposit(username: $username, amount: $amount) {
      id
      balance
    }
  }
`;

export const ADMIN_WITHDRAWAL = gql`
  mutation adminWithdrawal($username: String!, $amount: Int!) {
    adminWithdrawal(username: $username, amount: $amount) {
      id
      balance
    }
  }
`;
