import { useQuery } from '@apollo/client';
import {
  CompetitionTeamsQuery,
  CompetitionTeamsQueryVariables,
} from '@cobra/common/dist/graphql/generated/graphql';
import { CompetitionQueries } from '@cobra/common/dist/graphql/queries';
import { Avatar, Select, Space, Tag, Typography } from 'antd';
import useDebounce from '../../hooks/useDebounce';
import { useEffect, useRef } from 'react';

export const TeamSelector = ({
  value,
  onChange,
  extra,
}: {
  value: string[] | undefined | null;
  extra: CompetitionTeamsQueryVariables;
  onChange: (team: string[]) => void;
}) => {
  const search = useRef('');
  const debounced = useDebounce(search.current);
  const {
    data: { competitionTeams: teams } = { competitionTeams: [] },
    refetch,
  } = useQuery<CompetitionTeamsQuery, CompetitionTeamsQueryVariables>(
    CompetitionQueries.TEAMS,
    {
      fetchPolicy: 'no-cache',
      variables: extra,
    }
  );

  useEffect(() => {
    refetch();
  }, [debounced]);
  console.log({ teams, value });

  return (
    <Select<string[]>
      placeholder="Seleccione un equipo(s)"
      mode="multiple"
      showSearch
      allowClear
      value={value ?? []}
      tagRender={(x) => (
        <Tag {...x}>{teams.find((y) => y.id === x.value)?.name}</Tag>
      )}
      onChange={onChange}
      onSearch={(x) => {
        search.current = x;
      }}
      options={teams.map((x) => ({
        value: x.id,
        label: x.name,
        image: x.image,
        country: x.countryCode,
      }))}
      optionFilterProp="label"
      optionRender={(x: any) => (
        <Space size={16} direction="horizontal">
          <Avatar src={x.image} />
          <Typography.Text>{x.label}</Typography.Text>
          <Typography.Text>{x.country}</Typography.Text>
        </Space>
      )}
    ></Select>
  );
};
