import React, {
  ChangeEventHandler,
  FunctionComponent,
  KeyboardEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Input, Tag, InputRef, Row, Col, Flex } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

type CouponTags = FunctionComponent<{
  onChange: (tags: string[]) => void;
  coupons: string[];
}>;

const CouponTags: CouponTags = ({ onChange, coupons: tags = [] }) => {
  const newtagref = useRef<InputRef>(null);
  const [newtag, setNewTag] = useState('');
  const keys = ['Enter', ',', ' '];
  const length = 6;

  const submitNewTag = () => {
    const upd = [...tags];
    if (!upd.includes(newtag)) upd.push(newtag);
    onChange(upd);
    setNewTag('');
    newtagref.current?.focus();
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (
      keys.includes(e.key) &&
      (e.target as HTMLInputElement).value.length === length
    ) {
      submitNewTag();
    }
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!keys.includes(e.target.value.at(-1) ?? '')) setNewTag(e.target.value);
  };

  useEffect(() => {
    return () => onChange([]);
  }, []);

  return (
    <>
      <Row>
        <Col>
          <Flex align="center" justify="start" style={{ height: '100%' }}>
            {tags.map((t) => (
              <Tag
                key={t}
                onClick={() => {
                  const upd = [...tags].filter((x) => x !== t);
                  onChange(upd);
                }}
              >
                {t}
                <CloseCircleOutlined style={{ marginLeft: '0.25rem' }} />
              </Tag>
            ))}
          </Flex>
        </Col>
        <Col>
          <Input
            placeholder="Nombre del cupón..."
            ref={newtagref}
            style={{ width: '8rem' }}
            value={newtag}
            onInput={(e) => {
              (e.target as HTMLInputElement).value = (
                e.target as HTMLInputElement
              ).value.toUpperCase();
            }}
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            maxLength={length}
            disabled={tags.length >= 4}
            showCount
          />
        </Col>
      </Row>
      {/* <div style={{ display: 'flex', gap: '0.5rem' }}> */}
      {/* <Button
          onClick={() => {
            const upd = [...tags];
            if (!upd.includes(newtag)) upd.push(newtag);
            onChange(upd);
            setNewTag('');
            // eslint-disable-next-line no-unused-expressions
            newtagref.current?.focus();
          }}
        >
          Add coupon
        </Button> */}
      {/* </div> */}
    </>
  );
};

export { CouponTags };
