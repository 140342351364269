import { DepositMethod, WithdrawMethod } from 'typings/option';

export const DepositMethodProperties: Record<keyof DepositMethod, string> = {
  aba: 'Número ABA',
  accountName: 'Titular de la cuenta',
  accountNumber: 'Número de cuenta',
  bank: 'Entidad bancaria',
  code: 'Código del método',
  name: 'Nombre del método',
  idn: 'Documento de identidad',
  email: 'Correo',
  href: 'Link a la pasarela de pago',
  paymentConcept: 'Concepto',
  phone: 'Número de teléfono',
  reference: 'Número de refencia',
  swift: 'Número SWIFT',
  warn: 'Advetencia sobre la pasarela',
};

export const DepositMethodPropertyOrder: (keyof DepositMethod)[] = [
  'code',
  'name',
  'bank',
  'aba',
  'swift',
  'accountName',
  'accountNumber',
  'idn',
  'email',
  'phone',
  'paymentConcept',
  'href',
  'warn',
  'reference',
];

export const WithdrawMethodProperties: Record<keyof WithdrawMethod, string> = {
  aba: 'Número ABA',
  accountName: 'Titular de la cuenta',
  accountNumber: 'Número de cuenta',
  bank: 'Entidad bancaria',
  code: 'Código del método',
  name: 'Nombre del método',
  idn: 'Documento de identidad',
  personType: 'Tipo de persona',
  email: 'Correo',
  phone: 'Número de teléfono',
};

export const WithdrawMethodPropertyOrder: (keyof WithdrawMethod)[] = [
  'code',
  'name',
  'bank',
  'aba',
  'accountName',
  'accountNumber',
  'idn',
  'email',
  'phone',
  'personType',
];
