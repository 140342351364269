import gql from 'graphql-tag';
import { TeamAllInfo } from '../fragments/team';

export const CREATE = gql`
  mutation createTeam($data: CreateTeamInput!) {
    createTeam(data: $data) {
      ...TeamAllInfo
    }
  }
  ${TeamAllInfo}
`;

export const UPDATE = gql`
  mutation updateTeam($id: ID!, $data: UpdateTeamInput!) {
    updateTeam(id: $id, data: $data) {
      ...TeamAllInfo
      competitions {
        id
      }
    }
  }
  ${TeamAllInfo}
`;

export const DELETE = gql`
  mutation deleteTeam($id: ID!) {
    deleteTeam(id: $id) {
      ...TeamAllInfo
    }
  }
  ${TeamAllInfo}
`;

export const JOIN_COMPETITIONS = gql`
  mutation joinCompetitions($teamId: ID!, $competitionIds: [ID!]!) {
    joinCompetitions(teamId: $teamId, competitionIds: $competitionIds) {
      id
      competitions {
        id
        name
        region
      }
    }
  }
`;

export const LEAVE_COMPETITION = gql`
  mutation leaveCompetition($teamId: ID!, $competitionId: ID!) {
    leaveCompetition(teamId: $teamId, competitionId: $competitionId) {
      id
      competitions {
        id
        name
        region
      }
    }
  }
`;
