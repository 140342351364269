import gql from 'graphql-tag';

import * as betFragments from '../fragments/bet';

export const GET_BET = gql`
  query getBet($id: ID!) {
    getBet(id: $id) {
      ...BetMainInfo
    }
  }
  ${betFragments.BetMainInfo}
`;
