import { useState } from 'react';

export const usePagination = (
  initial: { page?: number; limit?: number } = { page: 0, limit: 20 }
) => {
  const page = initial.page ?? 0;
  const limit = initial.limit ?? 0;
  const getOffset = (page: number, limit: number) => {
    return Math.max(0, page - 1) * limit;
  };
  const getPage = (offset: number, limit: number) => {
    return Math.ceil((offset + 1) / limit);
  };

  const [pagination, setPagination] = useState({
    page,
    limit,
    offset: getOffset(page, limit),
  });

  const update = (data: Partial<typeof pagination>) => {
    setPagination((p) => ({ ...p, ...data }));
  };

  const goTo = (p: number) => {
    const target = Math.max(0, p);
    update({
      page: target,
      offset: Math.max(0, target - 1) * pagination.limit,
    });
  };
  const next = () => goTo(pagination.page + 1);
  const prev = () => goTo(pagination.page - 1);
  const changeSize = (size?: number) => {
    if (size === undefined) return;
    setPagination((p) => {
      const newPage = getPage(p.offset, size);
      return {
        limit: size,
        page: newPage,
        offset: getOffset(newPage, limit),
      };
    });
  };

  return { ...pagination, next, prev, goTo, changeSize };
};
