// @ts-ignore no types for this library
import { clabe } from 'clabe-validator';

const RfcValidator = RegExp(
  /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/
);

const CurpValidator = RegExp(
  /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/
);

export const isClabeValid = (clabeNumber: string) => {
  const validation = clabe.validate(clabeNumber);

  return validation.ok;
};

export const isRfcValid = (rfcNumber: string) => RfcValidator.test(rfcNumber);

export const isCurpValid = (curpNumber: string) =>
  CurpValidator.test(curpNumber);
