import gql from 'graphql-tag';
import {
  CompetitionAllInfo,
  CompetitionConfig,
} from '../fragments/competition';

export const UPDATE = gql`
  mutation updateCompetition($id: ID!, $data: UpdateCompetitionInput!) {
    updateCompetition(id: $id, data: $data) {
      id
      name
      sport
      region
      logoUrl
      type
      groups
      phases
      ...CompetitionConfig
    }
  }
  ${CompetitionConfig}
`;

export const CREATE = gql`
  mutation createCompetition($data: CreateCompetitionInput!) {
    createCompetition(data: $data) {
      id
      name
      sport
      region
      logoUrl
    }
  }
`;

export const REMOVE = gql`
  mutation deleteCompetition($id: ID!) {
    deleteCompetition(id: $id) {
      ...CompetitionAllInfo
    }
  }
  ${CompetitionAllInfo}
`;

export const WRITE = gql`
  mutation writeCompetition($data: WriteCompetitionInput!) {
    writeCompetition(data: $data) {
      ...CompetitionAllInfo
    }
  }
  ${CompetitionAllInfo}
`;
