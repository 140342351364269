import { max, isNil } from 'ramda';
import { WithPoints } from '../../typings';
import { ContestWinType } from '../../graphql/generated/graphql';

interface Placement<T> {
  currentPlace: number;
  currentPlacePoints: number | null;
  finalPlacement: { [index: number]: T[] };
}

const getContestantNumberThreshold = (
  prizeAmount: string,
  profitPercentage: number,
  entryAmount: number
) => {
  const entry = max(1, entryAmount);
  const profitMultiplier = 1 + profitPercentage / 100;
  const threshold =
    (Number.isNaN(Number(prizeAmount))
      ? 1
      : Number(prizeAmount) * profitMultiplier) / entry;

  return Math.ceil(threshold);
};

const getContestantsLimit = (
  min: number | undefined | null,
  max: number | undefined | null,
  prize: number,
  entry: number,
  fee: number
) => {
  /**
   * This formula is derived from the revenue formula where:
   *
   * ... profit can be expressed according to cost, and to revenue
   * Profit = ProfitPercent × Cost ÷ 100
   * Profit = Revenue - Cost
   * Revenue = Price × No.Sales
   *
   * ProfitPercent × Cost ÷ 100 = (Price × No.Sales) - Cost
   * ... where:
   *    - Price is the remaining amount taken from the entry according
   *      to our fee.
   *    - No.Sales is the amount of contestants needed to yield revenue.
   *    - Cost is our inversion (the prize money).
   *    - Profit percent is our expected min/max.
   *
   * We replace the variables with our own:
   * min × prize ÷ 100 = (entry × fee × n) - prize
   *
   * And we solve for n, which is the amount of contestants:
   * n = (((min ÷ 100) × prize) + prize) ÷ (entry × fee)
   * n = ((min ÷ 100) + 1) × prize ÷ (entry × fee)
   */
  const noPrize = prize <= 0;
  const noEntry = !entry || entry <= 0;
  const notValid = noPrize || noEntry;

  return {
    minContestants:
      !min || notValid ? 1 : ((1 + min / 100) * prize) / (entry * fee),
    maxContestants:
      !max || notValid ? 5000 : ((1 + max / 100) * prize) / (entry * fee),
  };
};

export const getContestantStats = ({
  winType,
  entry,
  prizeAmount,
  minProfit,
  maxProfit,
  winnerPercentage,
  fee,
}: {
  winType: ContestWinType;
  entry: number;
  prizeAmount: string;
  minProfit: number | undefined | null;
  maxProfit: number | undefined | null;
  winnerPercentage: number | undefined | null;
  fee: number;
}) => {
  const prize = isNaN(Number(prizeAmount)) ? 0 : Number(prizeAmount);

  let minContestants: number,
    maxContestants: number,
    minWinnerCount: number,
    minWinnerPercentage: number;

  // If contest win type is pot, manually set the values
  if (winType === ContestWinType.POT) {
    minContestants = 2;
    maxContestants = 5000;
    minWinnerCount = 1;
    minWinnerPercentage = 1;
  } else {
    const contestantsLimits = getContestantsLimit(
      minProfit,
      maxProfit,
      prize,
      entry,
      fee
    );
    minContestants = contestantsLimits.minContestants;
    maxContestants = contestantsLimits.maxContestants;

    const tier = [
      { count: 5, minWinnerCount: 1, topWinners: 1, blockWinners: 0 },
      { count: 15, minWinnerCount: 3, topWinners: 1, blockWinners: 0 },
      { count: 30, minWinnerCount: 5, topWinners: 5, blockWinners: 10 },
      { count: Infinity, minWinnerCount: 3, topWinners: 10, blockWinners: 20 },
    ].find((x) => minContestants <= x.count)!;

    minWinnerPercentage = Math.max(
      winnerPercentage ?? 0,
      (tier.topWinners + tier.blockWinners) / minContestants
    );

    minWinnerCount = Math.max(
      Math.ceil(minContestants * minWinnerPercentage),
      tier.minWinnerCount
    );

    console.log({
      maxContestants,
      minContestants,
      minWinnerPercentage,
      prize,
      prizeAmount,
      tier,
      winnerPercentage,
    });
  }

  return {
    minContestants,
    minWinnerCount,
    maxContestants,
    minWinnerPercentage: minWinnerPercentage * 100,
  };
};

export const getContestMinMaxContestants = (contest: {
  entryAmount: number;
  prizeAmount: string;
  minProfitPercentage: number;
  maxProfitPercentage: number;
}): [number, number] => {
  return [
    getContestantNumberThreshold(
      contest.prizeAmount,
      contest.minProfitPercentage,
      contest.entryAmount
    ),
    getContestantNumberThreshold(
      contest.prizeAmount,
      contest.maxProfitPercentage,
      contest.entryAmount
    ),
  ];
};

export const getMinWinnerCount = (
  contestantCount: number,
  winnerPercentage: number
) => {
  if (contestantCount <= 5) return 1; // top 1

  if (contestantCount <= 15) return 3; // top 3

  const minDistribution = contestantCount <= 30 ? 5 : 10;

  return max(
    minDistribution,
    Math.ceil(contestantCount * (winnerPercentage / 100))
  );
};

export const getMinWinnerPercentage = (contestantCount: number) => {
  if (contestantCount <= 15) return 1;

  let topWinners: number;
  let blockWinners: number;

  if (contestantCount <= 30) {
    // top 5 distribution
    topWinners = 5; // top 5
    blockWinners = 10; // 5 for 1st group, 5 for 2nd group
  } else {
    // top 10 distribution
    topWinners = 10; // top 10
    blockWinners = 20; // 10 for 1st group, 10 for 2nd group
  }

  const totalWinners = topWinners + blockWinners;

  return Math.ceil((totalWinners * 100) / contestantCount);
};

const placementReducer = <T extends WithPoints>(
  acc: Placement<T>,
  contestant: T
) => {
  const { currentPlace, currentPlacePoints } = acc;

  if (isNil(currentPlacePoints)) {
    acc.currentPlacePoints = contestant.points;
    acc.finalPlacement[currentPlace].push(contestant);

    return acc;
  }

  if (currentPlacePoints === contestant.points) {
    acc.finalPlacement[currentPlace].push(contestant);
    return acc;
  }

  // If 5 contestants placed first, then the next place would be 6th, not
  // 2nd
  const contestantsPlacedCount = acc.finalPlacement[currentPlace].length;
  const nextPlace = acc.currentPlace + contestantsPlacedCount;

  acc.currentPlace = nextPlace;
  acc.currentPlacePoints = contestant.points;
  acc.finalPlacement[acc.currentPlace] = [contestant];
  return acc;
};

export const getPlacement = <T extends WithPoints>(contestants: T[]) => {
  const initialPlacementData: Placement<T> = {
    currentPlace: 1,
    currentPlacePoints: null,
    finalPlacement: { 1: [] },
  };

  const { finalPlacement } = contestants.reduce(
    placementReducer,
    initialPlacementData
  );

  return finalPlacement;
};
