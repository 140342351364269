import gql from 'graphql-tag';

export const SportAllInfo = gql`
  fragment SportAllInfo on Sport {
    id
    name
    type
    config
  }
`;
