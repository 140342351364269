import gql from 'graphql-tag';

export default gql`
  mutation updateOption($id: String!, $value: JSON!) {
    updateOption(id: $id, value: $value) {
      id
      value
    }
  }
`;
