import spanish from './es.json';
import english from './en.json';

const es = spanish;
const en = english;

// We don't use string literal so we can build the regex easily.
// eslint-disable-next-line prefer-template
const getInterpolator = (str: string) => new RegExp('\\${' + str + '}');

const interpolate = (str: string, values: Record<string, string>) => {
  return Object.keys(values).reduce((acc, key) => {
    const regex = getInterpolator(key);

    return acc.replace(regex, values[key]);
  }, str);
};

export default {
  interpolate,
  en,
  es,
};
