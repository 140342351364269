import { useQuery } from '@apollo/client';
import {
  SportsQuery,
  SportsQueryVariables,
} from '@cobra/common/dist/graphql/generated/graphql';
import { SportQueries } from '@cobra/common/dist/graphql/queries';
import { Select } from 'antd';
import { isEmpty, isNil } from 'ramda';

export const SportSelector = ({
  sport,
  onChange,
  isFilter = false,
}: {
  sport: string | undefined | null;
  onChange: (sport: string) => void;
  isFilter?: boolean;
}) => {
  const { data: { sports } = { sports: [] } } = useQuery<
    SportsQuery,
    SportsQueryVariables
  >(SportQueries.SPORTS, { fetchPolicy: 'no-cache' });

  return (
    <Select
      value={
        isNil(sport) || isEmpty(sport)
          ? isFilter
            ? 'Todos'
            : undefined
          : sport
      }
      onChange={onChange}
      placeholder="Seleccione un deporte"
    >
      {isFilter && <Select.Option value={undefined}>Todos</Select.Option>}
      {sports.map((x) => (
        <Select.Option key={x.id} value={x.id}>
          {x?.name}
        </Select.Option>
      ))}
    </Select>
  );
};
