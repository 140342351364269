import gql from 'graphql-tag';

export const SponsorData = gql`
  fragment SponsorData on Sponsor {
    id
    name
    active
    logo
    banner
    bannerLink
    bannerInHome
    popup
    popupLink
    popupInHome
  }
`;
