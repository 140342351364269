import { useQuery } from '@apollo/client';
import {
  CompetitionsQuery,
  CompetitionsQueryVariables,
  FixtureInput,
  FixtureParticipantInput,
  FixtureStatus,
  FixtureTypes,
} from '@cobra/common/dist/graphql/generated/graphql';
import { CompetitionQueries } from '@cobra/common/dist/graphql/queries';
import { REGIONS_AND_COUNTRIES } from '@cobra/common/dist/utils/constants';
import { isNotBaseball, normalize } from '@cobra/common/dist/utils/helpers';
import {
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
} from 'antd';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import { useState } from 'react';
import GenericError from '../../../GenericError/GenericError';
import { TeamSelector } from './TeamSelector';

dayjs.extend(weekday);
dayjs.extend(localeData);

const { utc } = dayjs;

const renderLoadingCard = () => <Card style={{ width: '100%' }} loading />;

const { Item } = Form;

export const FixtureForm = ({
  data,
  status,
  onChange,
}: {
  data: FixtureInput;
  status: FixtureStatus;
  onChange: (data: Partial<FixtureInput>) => void;
}) => {
  const [currentSport, setCurrentSport] = useState('');

  const {
    data: { competitions } = { competitions: [] },
    loading: loadingCompetitions,
    error: competitionsError,
  } = useQuery<CompetitionsQuery, CompetitionsQueryVariables>(
    CompetitionQueries.COMPETITIONS,
    {
      variables: { offset: 0, limit: 100, options: { active: true } },
      fetchPolicy: 'no-cache',
    }
  );

  const renderBaseballStatsInput = () => {
    if (isNotBaseball(currentSport)) return null;

    return (
      <>
        <Row justify="space-between">
          <Col span={14}>
            <Item
              label="Pitcher Local"
              name="homePitcherName"
              rules={[
                {
                  required: true,
                  message: 'Pitcher local requerido!',
                },
              ]}
            >
              <Input />
            </Item>
          </Col>

          <Col span={3}>
            <Item
              label="G"
              name="homePitcherWins"
              rules={[
                {
                  required: true,
                  message: 'Elija un número de partidos!',
                },
              ]}
            >
              <InputNumber min={0} max={100} />
            </Item>
          </Col>

          <Col span={3}>
            <Item
              label="P"
              name="homePitcherLosses"
              rules={[
                {
                  required: true,
                  message: 'Elija un número de partidos!',
                },
              ]}
            >
              <InputNumber min={0} max={100} />
            </Item>
          </Col>
        </Row>

        <Row justify="space-between">
          <Col span={14}>
            <Item
              label="Pitcher Visitante"
              name="awayPitcherName"
              rules={[
                {
                  required: true,
                  message: 'Pitcher visitante requerido!',
                },
              ]}
            >
              <Input />
            </Item>
          </Col>

          <Col span={3}>
            <Item
              label="G"
              name="awayPitcherWins"
              rules={[
                {
                  required: true,
                  message: 'Elija un número de partidos!',
                },
              ]}
            >
              <InputNumber min={0} max={100} />
            </Item>
          </Col>

          <Col span={3}>
            <Item
              label="P"
              name="awayPitcherLosses"
              rules={[
                {
                  required: true,
                  message: 'Elija un número de partidos!',
                },
              ]}
            >
              <InputNumber min={0} max={100} />
            </Item>
          </Col>
        </Row>
      </>
    );
  };

  if (loadingCompetitions) return renderLoadingCard();

  return (
    <Form layout="vertical">
      {status !== FixtureStatus.NOT_STARTED ? (
        <Item label="Status">
          <Select
            value={data.status}
            placeholder="Seleccione un status"
            options={Object.keys(FixtureStatus).map((x) => ({
              value: x,
              label: x,
            }))}
            onChange={(x) => onChange({ status: x })}
          />
        </Item>
      ) : (
        <>
          <Item label="Competición">
            <Select
              value={data.competitionId}
              placeholder="Seleccione una competición"
              onSelect={(x, record) => {
                onChange({ competitionId: x });
                setCurrentSport(record.sport);
              }}
              showSearch
              filterOption={(input, option) =>
                normalize(option?.label + ' ' + option?.region).includes(
                  normalize(input)
                )
              }
              options={competitions.map((x) => ({
                value: x.id,
                label: x.name,
                region: x.region,
                sport: x.sport,
              }))}
              optionRender={(x: any) =>
                x.label +
                (x?.region ? ' - ' + REGIONS_AND_COUNTRIES[x.region] : '')
              }
            />
          </Item>
          <Row gutter={16}>
            <Col span={5}>
              <Item label="Status">
                <Select
                  value={data.status}
                  placeholder="Seleccione un status"
                  options={Object.keys(FixtureStatus).map((x) => ({
                    value: x,
                    label: x,
                  }))}
                  onChange={(x) => onChange({ status: x })}
                />
              </Item>
            </Col>
            <Col span={5}>
              <Item label="Tipo de partido">
                <Select
                  value={data.type}
                  placeholder="Seleccione un tipo"
                  options={Object.keys(FixtureTypes).map((x) => ({
                    value: x,
                    label: x,
                  }))}
                  onChange={(x) => onChange({ type: x })}
                />
              </Item>
            </Col>
            <Col span={5}>
              <Item label="Fase del partido">
                <Select
                  placeholder="Seleccione una fase"
                  value={data.phase?.length ? data.phase : undefined}
                  onChange={(x) => onChange({ phase: x })}
                  options={(
                    competitions.find((c) => c.id === data.competitionId)
                      ?.config?.phases ?? []
                  ).map((x) => ({
                    value: x.id,
                    label: x.name,
                  }))}
                />
              </Item>
            </Col>
            <Col span={4}>
              <Item label="Número del partido">
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder="0"
                  value={data.numberOfMatch}
                  onChange={(x) => {
                    onChange({ numberOfMatch: String(x) });
                  }}
                />
              </Item>
            </Col>
            <Col span={5}>
              <Item label="Fecha del Partido (Hora UTC)">
                <DatePicker
                  style={{ width: '100%' }}
                  value={utc(data.startDate)}
                  onChange={(x) => onChange({ startDate: x.utc().toDate() })}
                  placement="bottomRight"
                  format="DD/MM/YYYY hh:mm A"
                  disabledDate={(current) =>
                    current ? current < utc().subtract(1, 'day') : false
                  }
                  showTime={{
                    showHour: true,
                    showMinute: true,
                    use12Hours: true,
                    format: 'hh:mm A',
                  }}
                  allowClear={false}
                  showToday={false}
                  showNow={false}
                />
              </Item>
            </Col>
          </Row>
        </>
      )}

      <Item label="Participantes">
        <TeamSelector
          status={status}
          participants={(data.participants ?? []) as FixtureParticipantInput[]}
          sport={currentSport!}
          competition={data.competitionId!}
          update={(x) => onChange({ participants: x })}
        />
      </Item>

      {status === FixtureStatus.NOT_STARTED && renderBaseballStatsInput()}

      <GenericError error={competitionsError} flexLayout alignSelf="center" />
    </Form>
  );
};
