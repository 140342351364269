import React, {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';

type Context = {
  menuState: boolean;
  set: (data: ContextProps) => void;
};

type ContextProps = Omit<Partial<typeof initialContext>, 'set'>;

const initialContext: Context = {
  menuState: true,
  set: () => {},
};

const AppContext = createContext(initialContext);

export const AppContextProvider: FunctionComponent<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [context, update] = useState(initialContext);
  const set: Context['set'] = (data) => update((p) => ({ ...p, ...data }));

  return (
    <AppContext.Provider value={{ ...context, set }} children={children} />
  );
};

export const useAppContext = () => useContext(AppContext);
