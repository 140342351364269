export const Event = {
  EndBet: 'EndBet',
  EndContest: 'EndContest',
  RefundContestEntryFee: 'RefundContestEntryFee',
  SaveContestEntry: 'SaveContestEntry',
  SendBetPayout: 'SendBetPayout',
  SendContestPayout: 'SendContestPayout',
  StartContest: 'StartContest',
  VoidPendingBets: 'VoidPendingBets',
  Test: 'Test',
  UpdateContestLeaderboard: 'UpdateContestLeaderboard',
} as const;

export type Event = (typeof Event)[keyof typeof Event];
