import { PushpinFilled, PushpinOutlined } from '@ant-design/icons';

export const FeaturedSelector = ({
  featured,
  onChange,
}: {
  featured: boolean;
  onChange: (featured: boolean) => void;
}) =>
  featured ? (
    <PushpinFilled onClick={() => onChange(false)} />
  ) : (
    <PushpinOutlined onClick={() => onChange(true)} />
  );
