import gql from 'graphql-tag';
import { SponsorData } from '../fragments/sponsor';

export const SPONSOR = gql`
  query sponsor($id: ID!) {
    sponsor(id: $id) {
      ...SponsorData
    }
  }
  ${SponsorData}
`;

export const SPONSORS = gql`
  query sponsors($offset: Int!, $limit: Int!, $filters: SponsorsFilterInput) {
    sponsors(offset: $offset, limit: $limit, filters: $filters) {
      ...SponsorData
    }
  }
  ${SponsorData}
`;
