import { DeleteOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  CountFixturesQuery,
  CountFixturesQueryVariables,
  CreateFixtureInput,
  CreateFixtureMutation,
  CreateFixtureMutationVariables,
  DeleteFixtureMutation,
  DeleteFixtureMutationVariables,
  FixtureFilter,
  FixtureInput,
  FixtureQuery,
  FixtureQueryVariables,
  FixtureStatus,
  FixtureTypes,
  FixturesQuery,
  FixturesQueryVariables,
  UpdateFixtureMutation,
  UpdateFixtureMutationVariables,
} from '@cobra/common/dist/graphql/generated/graphql';
import { FixtureMutations } from '@cobra/common/dist/graphql/mutations';
import { FixtureQueries } from '@cobra/common/dist/graphql/queries';
import { Button, Popconfirm, Tag } from 'antd';
import dayjs from 'dayjs';
import { equals, isNil } from 'ramda';
import { useEffect, useState } from 'react';
import { useFormState } from '../../../hooks/useFormState';
import { useIdParam } from '../../../hooks/useIdParam';
import { usePagination } from '../../../hooks/usePagination';
import { Layout } from '../../Layout';
import { FixtureFilters } from './filters';
import { FixtureForm } from './form';

const initialFixtureData: FixtureInput = {
  competitionId: undefined,
  numberOfMatch: '',
  participants: [],
  phase: '',
  startDate: dayjs.utc().startOf('day').add(32, 'hours').toDate(),
  status: FixtureStatus.NOT_STARTED,
  tieOdds: 0,
  type: FixtureTypes.REGULAR,
};

export const FixturesView = () => {
  const id = useIdParam();
  const [fixtureData, fixtureForm] = useFormState(initialFixtureData, {
    onReset: (x) => setStatus(x?.status ?? FixtureStatus.NOT_STARTED),
  });
  const [filter, setFilterData] = useState<FixtureFilter>();
  const { limit, offset, goTo, changeSize } = usePagination();
  const [status, setStatus] = useState(FixtureStatus.NOT_STARTED);

  const {
    data: { fixtures } = { fixtures: [] },
    loading,
    refetch,
  } = useQuery<FixturesQuery, FixturesQueryVariables>(FixtureQueries.FIXTURES, {
    fetchPolicy: 'no-cache',
    variables: { limit, offset, filter },
  });
  const { data: { countFixtures } = { countFixtures: 0 }, refetch: count } =
    useQuery<CountFixturesQuery, CountFixturesQueryVariables>(
      FixtureQueries.COUNT,
      {
        fetchPolicy: 'no-cache',
        variables: { limit, offset, filter },
      }
    );

  const [fetch] = useLazyQuery<FixtureQuery, FixtureQueryVariables>(
    FixtureQueries.FIXTURE,
    {
      onCompleted: ({ fixture }) => {
        const upd = {
          ...initialFixtureData,
          competitionId: fixture.competition?.id,
        };

        for (const key in upd) {
          if (['competitionId'].includes(key)) continue;
          if (!isNil((fixture as any)[key])) {
            upd[key as keyof typeof upd] = (fixture as any)[key];
          }
        }

        fixtureForm.reset(upd);
      },
      fetchPolicy: 'no-cache',
    }
  );

  const [create] = useMutation<
    CreateFixtureMutation,
    CreateFixtureMutationVariables
  >(FixtureMutations.CREATE_FIXTURE, {
    onCompleted: ({ createFixture }) => {
      fixtureForm.reset({
        ...initialFixtureData,
        status: createFixture.status,
        phase: createFixture.phase,
        competitionId: createFixture.competition?.id,
        numberOfMatch: (Number(createFixture.numberOfMatch) + 1).toString(),
        type: createFixture.type,
        startDate: createFixture.startDate,
      });
    },
    fetchPolicy: 'no-cache',
  });

  const [update] = useMutation<
    UpdateFixtureMutation,
    UpdateFixtureMutationVariables
  >(FixtureMutations.UPDATE_FIXTURE, {
    onCompleted: () => refetch({ filter, offset, limit }),
    fetchPolicy: 'no-cache',
  });

  const [remove] = useMutation<
    DeleteFixtureMutation,
    DeleteFixtureMutationVariables
  >(FixtureMutations.DELETE, {
    onCompleted: () => {
      refetch({ filter, offset, limit });
      count({ filter });
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    refetch({ filter, offset, limit });
    count({ filter });
  }, [offset, limit, filter]);

  useEffect(() => {
    if (id.isEmpty) fixtureForm.reset(initialFixtureData);
    else fetch({ variables: { id: id.value } });
  }, [id.value]);

  return (
    <Layout<(typeof fixtures)[0]>
      table={{
        data: fixtures,
        loading,
        pagination: {
          pageSize: limit,
          total: countFixtures,
          onChange: (p) => goTo(p),
          onSizeChange: (p, s) => changeSize(s),
        },
        columns: [
          {
            title: 'Nombre',
            dataIndex: 'participants',
            render: (x, record) => {
              return record.participants.map((x) => x.name).join(' vs. ');
            },
          },
          {
            title: 'Competencia',
            dataIndex: 'competition',
            render: (x) => x.name,
          },
          {
            title: 'Nro.',
            dataIndex: 'numberOfMatch',
            render: (x) => x ?? '-',
          },
          {
            title: 'Fase',
            dataIndex: 'phase',
            render: (x, record) => {
              const phase = record.competition?.config?.phases?.find(
                (p) => p.id.toString() === x.toString()
              )?.name;

              return phase ? <Tag>{phase}</Tag> : '-';
            },
          },
          {
            title: 'Fecha',
            dataIndex: 'startDate',
            render: (x) => dayjs.utc(x).format('hh:mma. DD/MM/YYYY '),
          },
          {
            title: 'Acciones',
            render: (t, x) => (
              <Popconfirm
                title="¿Desea borrar este partido?"
                onConfirm={() => remove({ variables: { id: x.id } })}
              >
                <Button>
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            ),
          },
        ],
        onRowClick: (x) => {
          if (id.is(x.id)) id.clear();
          else id.set(x.id);
        },
      }}
      filters={
        <FixtureFilters onChange={setFilterData} openModal={() => id.set()} />
      }
      modal={{
        autoWidth: false,
        state: !id.isEmpty,
        title: id.isNew
          ? 'Nuevo partido'
          : fixtureData.participants?.map((p) => p?.name).join(' vs. ') ??
            'Editar partido',
        disabled: equals(fixtureData, fixtureForm.original),
        onCancel: id.clear,
        onOk: async () => {
          if (id.isEmpty) return;
          else if (id.isNew) {
            await create({
              variables: { data: fixtureData as CreateFixtureInput },
            });
          } else {
            await update({ variables: { id: id.value, data: fixtureData } });
          }
          await refetch({ filter, offset, limit });
        },
        element: (
          <FixtureForm
            data={fixtureData}
            status={status}
            onChange={fixtureForm.update}
          />
        ),
      }}
    />
  );
};
