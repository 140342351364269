import { ApolloProvider, ApolloClient } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import { InMemoryCache } from '@apollo/client/cache';
import { ConfigProvider } from 'antd';
import esES from 'antd/lib/locale/es_ES';
import React from 'react';
import ReactDOM from 'react-dom/client';
import Money from 'dinero.js';
import { ClientTypeDefs } from '@cobra/common';
import gql from 'graphql-tag';
import { AppContextProvider } from './context/app';

import './index.css';
import * as serviceWorker from './serviceWorker';
import App from './components/App/App';
import createApolloLink, { ErrorLink } from './apolloLink';

// Set dinero globals.
Money.globalLocale = 'en-US';
Money.defaultCurrency = 'USD';

const cache = new InMemoryCache({ addTypename: false });
const client = new ApolloClient({
  cache,
  link: createApolloLink(),
  typeDefs: ClientTypeDefs,
  resolvers: {},
});
ErrorLink.injectClient(client);

const initialData = {
  isLoggedIn: !!localStorage.getItem('accessToken'),
};

const loggedInQuery = gql`
  query IsLoggedIn {
    isLoggedIn
  }
`;

cache.writeQuery({
  query: loggedInQuery,
  data: initialData,
});

// @ts-ignore writeData does not need to return a promise
client.onResetStore(() => {
  cache.writeQuery({
    query: loggedInQuery,
    data: { ...initialData, isLoggedIn: !!localStorage.getItem('accessToken') },
  });
});

const rootElement = document.getElementById('root');
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <AppContextProvider>
      <BrowserRouter>
        <ApolloProvider client={client}>
          <ConfigProvider locale={esES}>
            <App />
          </ConfigProvider>
        </ApolloProvider>
      </BrowserRouter>
    </AppContextProvider>
  );
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
